import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const channelsUseStyles = createUseStyles(() => ({
  title: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#5D6269",
    margin: "0",
  },
  description: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
    margin: "8px 0 0 0",
  },
  channel: {
    width: "100%",
    padding: "20px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  channelName: {
    marginLeft: "4px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5D6269",
  },
  email: {
    marginLeft: "12px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "50%",
  },
  status: {
    padding: "3.5px 10px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#039855",
    background: "#F2FDF7",
    borderRadius: "8px",
  },
  message: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#858C9B",
  },
  radioButton: {
    width: "16px",
    height: "16px",
    margin: "0",
    background: "#537FC1",
  },
  messageType: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
  },
  messageTypeTitle: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#5D6269",
    margin: "0",
  },
  input: {
    marginTop: "12px",
  },
  telegramLink: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#00AFD6",
    width: "100%",

    "&>a": {
      maxWidth: "calc(100% - 28px)",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#00AFD6",
    },
  },

  messageForConect: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#858C9B",
    marginTop: "12px",
  },
  emailForMobile: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonsContainer: {},
  emailChannel: {},
  emailChannelForMobile: {
    display: "none",
  },
  wrapper: {},
  telegramConnectContainer: {
    width: "100%",
  },
  [Breakpoints.Mobile]: {
    buttonsContainer: {
      flexDirection: "column-reverse",
      "&>button": {
        width: "100%",
      },
    },
    wrapper: {
      width: "100%",
      gap: "32px",
    },
    emailChannel: {
      display: "none",
    },
    emailChannelForMobile: {
      display: "flex",
    },
  },
}));
