import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const frequencyUseStyles = createUseStyles(() => ({
  radioButton: {
    width: "16px",
    height: "16px",
    margin: "0",
    background: "#537FC1",
  },
  title: {
    margin: "0",
    fontSize: "22px",
    fontWeight: "500",
    color: "#5D6269",
  },
  timePeriod: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    whiteSpace: "nowrap",
  },
  timePeriodEvery: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    width: "148px",
  },
  menuItemWrapper: {
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E6E6EB",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#E6E6EB", // Change the color of the scrollbar track
    },
  },
  selectTimeIcon: {
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  timePeriodwrapper: {
    padding: "8px 0 24px",
    borderBottom: "1px solid #E6E6EB",
    marginBottom: "8px",
    width: "100%",
  },
  hourlySelects: {
    maxWidth: "280px",
    position: "relative",
    width: "100%",
  },
  buttonsContainer: {},
  frequencyWrapper: {},
  hourlyTimeContainer: {
    width: "100%",
  },
  everyContainer: {
    width: "calc(50% + 28px)",
  },
  dailyWrapper: {
    marginTop: "8px",
    width: "calc(50% + 28px)",
  },
  hourlyInputs: {},
  [Breakpoints.Mobile]: {
    frequencyWrapper: {
      width: "100%",
      gap: "32px",
    },
    buttonsContainer: {
      flexDirection: "column-reverse",
      "&>button": {
        width: "100%",
      },
    },
    hourlyTimeContainer: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
    hourlyInputs: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
    dailyWrapper: {
      flexDirection: "column",
      gap: "8px",
      alignItems: "flex-start",
      width: "100%",
    },
    everyContainer: {
      flexDirection: "column",
      gap: "8px",
      alignItems: "flex-start",
      width: "100%",
    },
    timePeriodEvery: {
      width: "auto",
    },
  },
}));
