import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import AuthenticationLayout from "Layouts/Authentication/AuthenticationLayout";
import { ROUTES } from "constants/routes.constants";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { Input } from "shared/reusableComponents/Input/Input";
import CustomLink from "shared/reusableComponents/CustomLink/CustomLink";
import { signInUser } from "modules/Services/auth.services";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import SuccessToaster from "shared/reusableComponents/Toster/SuccessToaster";
import { validateEmail } from "helpers/validateEmail";
import MagicLink from "modules/Autentication/MagicLink/components/MagicLink";
import { signInUseStyles } from "./SignIn.styles";

const SignIn = () => {
  const styles = signInUseStyles();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessageForToaster, setErrorMessageForToaster] =
    useState<string>("");
  const [successMessageForToaster, setSuccessMessageForToaster] =
    useState<string>("");
  const [loginCredentials, setLoginCredentials] = useState<any>({
    email: "",
    password: "",
  });
  const [isLoginManually, setIsLoginManually] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isFormValid = () => {
    return (
      loginCredentials.email.trim() !== "" &&
      loginCredentials.password.trim() !== ""
    );
  };

  const { mutate, isLoading } = useMutation(
    () => signInUser(loginCredentials),
    {
      onSuccess: (res: any) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);

        navigate(ROUTES.ON_BOARDING);
      },
      onError: () => {
        setErrorMessageForToaster(errorSuccessMessages.incorectEmailOrPass);
      },
    }
  );

  useEffect(() => {
    if (location?.state?.successMessageForToaster) {
      setSuccessMessageForToaster(location?.state?.successMessageForToaster);
    }
  }, [location?.state?.successMessageForToaster]);

  const handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValidEmail = validateEmail(loginCredentials.email);
    if (isValidEmail && !isLoading) {
      mutate();
    } else if (!isValidEmail) {
      setErrorMessage(errorSuccessMessages.incorrect);
    }
  };

  return isLoginManually ? (
    <AuthenticationLayout
      title="Welcome back"
      description="Don’t have an account?"
      linkTo={ROUTES.SIGNUP}
      linkName="Sign Up"
    >
      <form className={styles.form} onSubmit={handleSignIn}>
        <Input
          label="Email"
          defaultValue={loginCredentials.email}
          value={loginCredentials.email}
          hasError={!!errorMessage}
          errorText={errorMessage}
          handleChange={(e) => {
            setErrorMessage("");
            setErrorMessageForToaster("");
            setLoginCredentials({
              ...loginCredentials,
              email: (e.target as HTMLInputElement).value,
            });
          }}
        />
        <Flex direction="column" gap="12px" width="100%">
          <Input
            label="Password"
            inputType="password"
            defaultValue={loginCredentials.password}
            value={loginCredentials.password}
            handleChange={(e) => {
              setErrorMessageForToaster("");
              setLoginCredentials({
                ...loginCredentials,
                password: (e.target as HTMLInputElement).value,
              });
            }}
          />
          <CustomLink to={ROUTES.FORGOT_PASSWORD} style={{ fontWeight: "400" }}>
            Forgot password?
          </CustomLink>
        </Flex>
        <Button
          variant={ButtonVariants.Primary}
          type="submit"
          disabled={!isFormValid()}
          style={{ marginTop: "8px" }}
        >
          {isLoading ? <Loader /> : "Login"}
        </Button>
      </form>

      <ErrorToaster
        message={errorMessageForToaster}
        setMessage={setErrorMessageForToaster}
      />
      <SuccessToaster
        message={successMessageForToaster}
        setMessage={setSuccessMessageForToaster}
      />
    </AuthenticationLayout>
  ) : (
    <MagicLink setIsActionManually={setIsLoginManually} type="sign-in" />
  );
};
export default SignIn;
