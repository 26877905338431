import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessToaster from "shared/reusableComponents/Toster/SuccessToaster";
import { ROUTES } from "constants/routes.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import ChangePassword from "modules/Dashboard/Profile/components/ChangePassword";
import PersonalDetails from "modules/Dashboard/Profile/components/PersonalDetails";
import BillingIcon from "assets/icons/components/Billing.Icon";
import PasswordChangeIcon from "assets/icons/components/PasswordChange.Icon";
import ProfileIcon from "assets/icons/components/Profile.Icon";
import NewsPodIcon from "assets/icons/components/NewsPod.Icon";
import Billing from "modules/Dashboard/Profile/components/Billing/Billing";
import { useDashboardContext } from "context/Dashboard.context";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { profileUseStyles } from "modules/Dashboard/Profile/pages/Profile.styles";

const Profile = ({ userInfo }: { userInfo: any }) => {
  const styles = profileUseStyles();
  const { activeTab, setActiveTab } = useDashboardContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setActiveTab("Personal details")
     //eslint-disable-next-line
  },[])

  useEffect(() => {
    if (location.search.includes("succeeded")) {
      setSuccessMessage(errorSuccessMessages.paymentConfirmed);
      setActiveTab("Billing information");
      navigate(ROUTES.PROFILE);
    }
    //eslint-disable-next-line
  }, [location.search.includes("succeeded")]);

  useEffect(() => {
    if (location?.state?.activeTab) {
      setActiveTab(location?.state?.activeTab);
    }
    //eslint-disable-next-line
  }, [location?.state?.activeTab]);

  const generateContent = () => {
    switch (activeTab) {
      case "Personal details":
        return <PersonalDetails userInfo={userInfo} />;
      case "Change password":
        return <ChangePassword />;
      case "Billing information":
        return <Billing userInfo={userInfo} />;
      default:
        break;
    }
  };
  return (
    <Flex className={styles.profileWrapper} direction="column" gap="32px">
      <Flex className={styles.profileTitle} direction="column" gap="12px">
        <h4 className={styles.sectionName}>Profile</h4>
        <Flex gap="8px" alignItems="center">
          <Flex
            gap="8px"
            alignItems="center"
            className={styles.profileLocation}
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            <NewsPodIcon />
            <span>NewsPods</span>
            <span>/</span>
          </Flex>
          <Flex
            gap="8px"
            alignItems="center"
            className={styles.profileLocation}
            style={{ color: "#537FC1" }}
          >
            <ProfileIcon />
            <span>Profile</span>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.profileContainer} gap="24px" direction="column">
        <Flex  className={styles.tabContainer}>
          <Flex
            alignItems="center"
            gap="10px"
            className={`${styles.tab} ${
              activeTab === "Personal details" && styles.activeTab
            }`}
            onClick={() => setActiveTab("Personal details")}
          >
            <ProfileIcon />
            <span>Personal details</span>
          </Flex>
          {userInfo.can_change_password && (
            <Flex
              alignItems="center"
              gap="10px"
              className={`${styles.tab} ${
                activeTab === "Change password" && styles.activeTab
              }`}
              onClick={() => setActiveTab("Change password")}
            >
              <PasswordChangeIcon />
              <span>Change password</span>
            </Flex>
          )}
          <Flex
            alignItems="center"
            gap="10px"
            className={`${styles.tab} ${
              activeTab === "Billing information" && styles.activeTab
            }`}
            onClick={() => setActiveTab("Billing information")}
          >
            <BillingIcon />
            <span>Billing information</span>
          </Flex>
        </Flex>
        {generateContent()}
      </Flex>
      <SuccessToaster message={successMessage} setMessage={setSuccessMessage} />
    </Flex>
  );
};
export default Profile;
