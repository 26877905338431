import { Box, Typography } from "@mui/material";
import EditIcon from "assets/icons/components/Edit.Icon";
import ProfileIcon from "assets/icons/components/Profile.Icon";
import messageIcon from "assets/icons/messageIcon.svg";
import selectTime from "assets/icons/selecTimeIcon.svg";
import telegramIcon from "assets/icons/telegramIcon.svg";
import DeleteModalContent from "modules/Dashboard/NewsPods/components/Modals/DeleteModalContent";
import { profileSettingUseStyles } from "modules/Dashboard/Profile/components/styles";
import { deleteAccount, editUser } from "modules/Services/auth.services";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import BasicModal from "shared/reusableComponents/BasicModal/BasicModal";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Input } from "shared/reusableComponents/Input/Input";

const modalDescription =
  "We’re sorry to see you go. By deleting your account you will lose all of your data permanently. There will be now way to restore your account.";

const PersonalDetails = ({ userInfo }: { userInfo: any }) => {
  const styles = profileSettingUseStyles();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const queryClient = useQueryClient();

  const { mutate: editUserMutate, isLoading } = useMutation(
    () => editUser({ name: name, tz: userTimezone }),
    {
      onSuccess: () => {
        setIsEdit(false);
        queryClient.invalidateQueries("getUser");
      },
    }
  );

  const { mutate: deleteAccountMutate } = useMutation(() => deleteAccount(), {
    onSuccess: () => {
      setIsOpenDeleteModal(false);
      localStorage.clear();
      navigate("/");
      window.location.reload();
    },
  });

  useEffect(() => {
    userInfo.name && setName(userInfo.name);

    //eslint-disable-next-line
  }, [userInfo]);

  const handleEdit = () => {
    if (!isLoading) {
      editUserMutate();
    }
  };

  const generateNameContent = () => {
    if (isEdit) {
      return (
        <Flex
          className={styles.profileDetailsWrapper}
          direction="column"
          gap="20px"
        >
          <Flex direction="column" gap="32px" width="100%">
            <span className={styles.sectionTitle}>Personal details</span>
            <Input
              label="Name"
              defaultValue={userInfo?.name}
              handleChange={(e: any) => setName(e?.target?.value)}
            />
            <Flex
              className={styles.buttonsContainer}
              gap="16px"
              justifyContent="flex-end"
              width="100%"
            >
              <Button
                variant={ButtonVariants.Secondary}
                handleClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={name === userInfo.name || isLoading}
                handleClick={handleEdit}
              >
                {isLoading ? <Loader /> : "Save"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <Flex
          className={styles.profileDetailsWrapper}
          direction="column"
          gap="20px"
        >
          <Flex
            className={styles.profileDetailsContainer}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <span className={styles.sectionTitle}>Personal details</span>
            <Flex
              gap="8px"
              className={styles.nameEditContainer}
              onClick={() => setIsEdit(true)}
            >
              <EditIcon />
              <span>Edit</span>
            </Flex>
          </Flex>
          <Flex direction="column" gap="24px" width="100%">
            <Flex
              className={styles.nameWrapper}
              alignItems="center"
              width="100%"
              gap="100px"
            >
              <Flex
                className={styles.nameContainer}
                alignItems="center"
                gap="8px"
              >
                <ProfileIcon />
                <span>Name</span>
              </Flex>
              <span className={styles.name}>{userInfo.name}</span>
            </Flex>
            <Flex className={styles.emailContainer} width="100%" gap="100px">
              <Flex
                className={styles.nameContainer}
                alignItems="center"
                gap="8px"
              >
                <img src={messageIcon} alt="" />
                <span>Email</span>
              </Flex>
              <span className={styles.name}>{userInfo?.email}</span>
            </Flex>
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <>
      <Flex className={styles.wrapper} gap="24px" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { lg: "50%", sm: "100%" }, gap: "24px" }}
          justifyContent="space-between"
        >
          {generateNameContent()}
          <Flex
            className={styles.profileDetailsWrapper}
            direction="column"
            gap="32px"
          >
            <span className={styles.sectionTitle}>Location</span>
            <Flex
              className={styles.timeZoneContainer}
              justifyContent="space-between"
              width="100%"
            >
              <Flex gap="8px">
                <img src={selectTime} alt="" />
                <span>Timezone</span>
              </Flex>
              <span>{userInfo?.tz}</span>
            </Flex>
          </Flex>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          sx={{ width: { lg: "50%", sm: "100%" }, gap: "24px" }}
        >
          <Flex
            className={styles.connectedAccountsWrapper}
            direction="column"
            gap="32px"
          >
            <span className={styles.sectionTitle}>Connected accounts</span>
            <Flex width="100%" className={styles.channelsContainer} gap="24px">
              {userInfo.has_telegram_profile && (
                <Flex
                  className={styles.channelTelegram}
                  alignItems="center"
                  gap="8px"
                >
                  <div className={styles.iconContainer}>
                    <img src={telegramIcon} alt="" />
                  </div>
                  <span>Telegram</span>
                </Flex>
              )}
              <Flex
                alignItems="center"
                gap="8px"
                className={styles.channelEmail}
              >
                <div className={styles.iconContainer}>
                  <img src={messageIcon} alt="" />
                </div>
                <span>Mail</span>
              </Flex>
            </Flex>
          </Flex>

          <Box
            sx={{
              background: "#F6F6F6",
              padding: "20px",
              border: "1px solid #E6E6EB",
              borderRadius: "8px",
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              sx={{ color: "#5D6269", fontSize: "18px", fontWeight: 600 }}
            >
              Delete account
            </Typography>
            <Typography sx={{ color: "#858C9B", fontSize: "14px" }}>
              Your account and all associated data will be permanently deleted.
            </Typography>
            <Typography
              sx={{ color: "#D33F5E", fontWeight: 500, cursor: "pointer" }}
              onClick={() => setIsOpenDeleteModal(true)}
            >
              Delete Account
            </Typography>
          </Box>
        </Box>
      </Flex>
      <BasicModal
        open={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
      >
        <DeleteModalContent
          handleClose={() => setIsOpenDeleteModal(false)}
          handleDelete={() => deleteAccountMutate()}
          deleteUser
          description={modalDescription}
        />
      </BasicModal>
    </>
  );
};
export default PersonalDetails;
