import { createUseStyles } from "react-jss";

export const welcomCreatedUseStyles = createUseStyles(() => ({
  wrapper: {
    padding: "24px",
  },
  title: {
    fontSize: "32px",
    fontWeight: "600",
    color: "#5D6269",
    margin: "0",
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#858C9B",
    maxWidth: "480px",
    textAlign: "center",
  },
}));
