import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const customStepperUseStyles = createUseStyles((theme) => ({
  stepLabel: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.5",
    marginBottom: "8px",
  },
  stepDescription: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
  },
  customStepperWrapper: {
    background: "#F4F8FF",
    width: "38%",
    padding: "24px",
  },
  optional: {
    display: "flex",
    justifyContent: "space-between",
    "&>span": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#C5C8CC",
    },
  },
  roundProgress: {
    "&>.MuiCircularProgress-svg": {
      width: "56px",
      height: "56px",
    },
  },
  customMobileStepperWrapper: {
    background: "#F4F8FF",
    borderRadius: "8px",
    padding: "16px",
    position: "relative",
    display: "none",
  },
  count: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#5D6269",
    position: "absolute",
    top: "38px",
    left: "28px",
  },
  [Breakpoints.Mobile]: {
    stepLabel: {
      width: "100%",
    },
    customStepperWrapper: {
      display: "none",
    },
    customMobileStepperWrapper: {
      display: "flex",
    },
  },
}));
