import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes.constants";
import { Button } from "shared/reusableComponents/Button/Button";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import pageNotFound from "assets/icons/pageNotFound.svg";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap="12px"
      style={{ height: "100vh" }}
    >
      <img src={pageNotFound} alt="" />
      <span
        style={{
          fontSize: "24px",
          fontWeight: "500",
          color: "#5D6269",
          marginBottom: "8px",
        }}
      >
        Sorry,Page not found!
      </span>
      <Button handleClick={() => navigate(ROUTES.ON_BOARDING)}>
        Go to main page
      </Button>
    </Flex>
  );
};
export default PageNotFound;
