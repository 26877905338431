const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M10.9199 4.59031L2.63242 12.8778L2.26492 15.4728C2.24242 15.6303 2.37742 15.7578 2.52742 15.7353L5.12242 15.3678L13.4099 7.08031L10.9199 4.59031ZM15.6299 4.32781L13.6724 2.37031C13.5224 2.22031 13.2899 2.22031 13.1399 2.37031L11.7449 3.76531L14.2349 6.25531L15.6299 4.86031C15.7799 4.71031 15.7799 4.47781 15.6299 4.32781Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
