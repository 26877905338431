import { createUseStyles } from "react-jss";


export const errorMessageUseStyles = createUseStyles(()=>({
errorMessage :{
  display:"flex",
  alignItems:"center",
  margin:"0",
  color:"#E74768",
  fontWeight:"400",
  fontSize:"12px",
  "&>img":{
    marginRight:"6px"
  }
  
}
}))


