import { useEffect, useState } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { BreakPointSize } from "constants/styles.contants";
import untitledNewspod from "assets/icons/untitledNewspod.svg";
import erroIcon from "assets/icons/deleteModalIcon.svg";
import { newspodDetailsUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/styles";
import { useDashboardContext } from "context/Dashboard.context";

const NewsPodName = ({
  uuid,
  newspodName,
  setNewspodName,
}: {
  uuid: any;
  newspodName: string;
  setNewspodName: any;
}) => {
  const styles = newspodDetailsUseStyles();
  const [nameError, setNameError] = useState<string>("");

  const { isOpenCreateNewspodModal }: any = useDashboardContext();

  const handleNameInputBlur = () => {
    if (newspodName.length > 50) {
      setNameError("Maximum 50 characters");
    }
  };
  useEffect(() => {
    if (window.innerWidth > BreakPointSize.Mobile) {
      const input = document.getElementById("nameInput");
      (input as any).style.width = `${(input as any)?.value?.length * 8}px`;
      const updateInputWidth = () => {
        const textLength = (input as any)?.value?.length;
        (input as any).style.width = `${textLength * 8}px`;
      };

      input?.addEventListener("input", updateInputWidth);
    }
  }, []);

  return (
    <Flex className={styles.newsPodNameWrapper} direction="column" gap="18px">
      <span className={styles.title}>
        {uuid && isOpenCreateNewspodModal.type === "edit"
          ? "Edit the NewsPod "
          : "Create a new NewsPod"}
      </span>
      <Flex
        className={styles.newsPodNameContainer}
        direction="column"
        gap="4px"
      >
        <input
          id="nameInput"
          className={styles.newsPodNameInput}
          value={newspodName}
          onChange={(e: any) => setNewspodName(e.target.value)}
          onBlur={handleNameInputBlur}
          onFocus={() => setNameError("")}
          style={{
            border: nameError && "1px solid #E74768",
            paddingRight: nameError ? "32px" : "12px",
          }}
        />
        <div className={styles.newsPodNameImg}>
          <img src={untitledNewspod} alt="" />
        </div>
        {nameError && (
          <div className={styles.errorIcon}>
            <img src={erroIcon} alt="" />
          </div>
        )}
        {nameError && <span className={styles.nameError}>{nameError}</span>}
      </Flex>
    </Flex>
  );
};

export default NewsPodName;
