import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import {
  createNewsPod,
  editNewsPod,
  getNewspod,
} from "modules/Services/newsPods.services";
import {
  convertTo12HourFormat,
  convertTo24HourFormat,
  convertToHourString,
} from "helpers/convertTimes";
import Topics from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/Topics";
import Sources from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/Sources";
import Frequency from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/Frequency";
import Channels from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/Channels";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import close from "assets/icons/close.svg";
import NewsPodName from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/NewsPodName";
import { useDashboardContext } from "context/Dashboard.context";
import { newsPodCreateOrEditUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodCreateOrEdit.styles";

const NewsPodCreateOrEdit = ({
  userInfo,
  setErrorMessageForToaster,
}: {
  userInfo: any;
  setErrorMessageForToaster: (message: string) => void;
}) => {
  const [newspodName, setNewspodName] = useState<string>("Untitled NewsPod");
  const styles = newsPodCreateOrEditUseStyles();
  const { uuid } = useParams();

  const queryClient = useQueryClient();
  const { isOpenCreateNewspodModal, setIsOpenCreateNewspodModal }: any =
    useDashboardContext();

  const [frequencySettings, setFrequencySettings] = useState({
    sendStart: "",
    sendEnd: "",
    sendAt: "",
    every: Number(),
  });
  const [newsPodDetails, setNewsPodDetails] = useState<any>({
    name: "Untitled NewsPod",
    status: "Active",
    keywords: [],
    categories: [],
    sources: [],
    frequency: "",
    send_at: null,
    send_start: null,
    send_end: null,
    send_via_email: true,
    send_via_telegram: false,
  });
  const [frequencyTime, setFrequencyTime] = useState("");
  const [hourlyTime, setHourlyTime] = useState({
    start: "",
    end: "",
    every: "",
  });
  const [dailyTime, setDailyTime] = useState("");
  const [selectedTopic, setSelectedTopic] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [keywordValue, setKeywordValue] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isDisable, setIsDisable] = useState<boolean>(true);

  const [ChannelsInfo, setChannelsInfo] = useState({
    email: {
      send: true,
      connected: true,
    },
    telegram: {
      send: false,
      connected: false,
    },
  });

  useQuery(["getNewsPod", { uuid }], () => getNewspod({ uuid }), {
    onSuccess: ({ data }) => {
      setSelectedTopic(data.categories);
      setSelectedSources(data.sources);
      data.keywords && setKeywords(data.keywords);
      setChannelsInfo({
        email: {
          send: data.email.send,
          connected: data.email.connected,
        },
        telegram: {
          send: data.telegram.send,
          connected: data.telegram.connected,
        },
      });
      setNewspodName(data.name);
      if (data.frequency === 24) {
        setFrequencyTime("Daily");
        setDailyTime(convertTo12HourFormat(data.send_at));
      } else {
        setFrequencyTime("Hourly");
        setHourlyTime({
          start: convertTo12HourFormat(data.send_start),
          end: convertTo12HourFormat(data.send_end),
          every: convertToHourString(data.frequency),
        });
      }
    },

    enabled: !!uuid && isOpenCreateNewspodModal.type === "edit",
  });

  const { mutate: createNewsPodMutate, isLoading: isCreateLoading } =
    useMutation(createNewsPod, {
      onSuccess: () => {
        setIsOpenCreateNewspodModal({ open: false, type: "" });
        queryClient.invalidateQueries("getNewspods");
        queryClient.invalidateQueries("getUser");
      },
      onError: ({ response }) => {
        const key = response.data.code;
        setErrorMessageForToaster(errorSuccessMessages[key]);
      },
    });

  const { mutate: editNewsPodMutate, isLoading: isEditLoading } = useMutation(
    editNewsPod,
    {
      onSuccess: () => {
        setIsOpenCreateNewspodModal({ open: false, type: "edit" });
        queryClient.invalidateQueries("getNewspods");
        queryClient.invalidateQueries("getUser");
      },
      onError: ({ response }) => {
        const key = response.data.code;
        setErrorMessageForToaster(errorSuccessMessages[key]);
      },
    }
  );

  useEffect(() => {
    setNewsPodDetails({
      name: newspodName,
      status: newsPodDetails?.status || "Active",
      keywords: keywords,
      categories: selectedTopic,
      sources: selectedSources,
      frequency: frequencySettings.every,
      send_at: frequencySettings.sendAt || null,
      send_start: frequencySettings.sendStart || null,
      send_end: frequencySettings.sendEnd || null,
      send_via_email: ChannelsInfo.email.send,
      send_via_telegram: ChannelsInfo.telegram.send,
    });
    setIsDisable(
      !(
        newspodName &&
        newspodName.length <= 50 &&
        (selectedTopic.length || keywords.length) &&
        frequencySettings.every &&
        ((frequencySettings.sendEnd && frequencySettings.sendStart) ||
          frequencySettings.sendAt) &&
        (ChannelsInfo.email.send || ChannelsInfo.telegram.send)
      )
    );

    //eslint-disable-next-line
  }, [
    selectedTopic,
    selectedSources,
    keywords,
    ChannelsInfo,
    frequencySettings,
    newspodName,
  ]);

  useEffect(() => {
    if (frequencyTime === "Daily") {
      setFrequencySettings({
        ...frequencySettings,
        every: 24,
        sendEnd: "",
        sendStart: "",
        sendAt: dailyTime && convertTo24HourFormat(dailyTime),
      });
    } else {
      setFrequencySettings({
        sendStart: hourlyTime.start && convertTo24HourFormat(hourlyTime.start),
        sendEnd: hourlyTime.end && convertTo24HourFormat(hourlyTime.end),
        every: hourlyTime.every ? parseInt(hourlyTime.every, 10) : Number(),
        sendAt: "",
      });
    }
    //eslint-disable-next-line
  }, [hourlyTime, frequencyTime, dailyTime]);

  const handleCreate = () => {
    if (!isEditLoading && !isCreateLoading) {
      if (uuid && isOpenCreateNewspodModal.type === "edit") {
        editNewsPodMutate({ uuid, payload: newsPodDetails });
      } else {
        createNewsPodMutate(newsPodDetails);
      }
    }
  };

  const handleBlur = () => {
    if (keywordValue && !keywords.includes(keywordValue)) {
      setKeywords((prev: any) => [...prev, keywordValue]);
    }
    setKeywordValue("");
  };
  const handleEnterClick = (e: any) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };
  const handleDeleteKeyword = (keyword: string) => {
    let newArr = [...keywords];
    const indexToRemove = newArr.indexOf(keyword);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setKeywords(newArr);
  };

  const handleDeletCategorie = (topic: any) => {
    let newArr = [...selectedTopic];
    const indexToRemove = newArr.indexOf(topic);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setSelectedTopic(newArr);
  };

  const handleDeleteSource = (source: any) => {
    let newArr = [...selectedSources];
    const indexToRemove = newArr.indexOf(source);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setSelectedSources(newArr);
  };

  return (
    <Flex direction="column" gap="32px" className={styles.wrapper}>
      <Flex
        className={styles.newsPodCreationWrapper}
        direction="column"
        gap="32px"
      >
        <NewsPodName
          uuid={uuid}
          newspodName={newspodName}
          setNewspodName={setNewspodName}
        />
        <Topics
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          handleDeletCategorie={handleDeletCategorie}
          keywordValue={keywordValue}
          setKeywordValue={setKeywordValue}
          keywords={keywords}
          handleBlur={handleBlur}
          handleEnterClick={handleEnterClick}
          handleDeleteKeyword={handleDeleteKeyword}
        />
        <div className={styles.divider} />
        <Sources
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          handleDeleteSource={handleDeleteSource}
        />
        <div className={styles.divider} />
        <Frequency
          frequencyTime={frequencyTime}
          setFrequencyTime={setFrequencyTime}
          hourlyTime={hourlyTime}
          setHourlyTime={setHourlyTime}
          dailyTime={dailyTime}
          setDailyTime={setDailyTime}
        />
        <div className={styles.divider} />
        <Channels
          userInfo={userInfo}
          ChannelsInfo={ChannelsInfo}
          setChannelsInfo={setChannelsInfo}
        />
        <Flex gap="16px" width="100%" justifyContent="flex-end">
          <Button
            variant={ButtonVariants.Secondary}
            handleClick={() =>
              setIsOpenCreateNewspodModal({ open: false, type: "" })
            }
          >
            Cancel
          </Button>
          <Button handleClick={handleCreate} disabled={isDisable}>
            {uuid && isOpenCreateNewspodModal.type === "edit"
              ? "Save"
              : "Create"}
          </Button>
        </Flex>
      </Flex>
      <div
        className={styles.closeIcon}
        onClick={() => setIsOpenCreateNewspodModal(false)}
      >
        <img src={close} alt="close" />
      </div>
    </Flex>
  );
};
export default NewsPodCreateOrEdit;
