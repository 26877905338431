import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";
const backgroundImage = require("assets/icons/onBoardingBackground.png");

export const onBoardingLayoutUseStyles = createUseStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "unset",
    backgroundColor: "#0f1f37",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
  },
  container: {
    width: "40%",
    background: "#F5F5F5",
    height: "100vh",
    overflow: "hidden",
  },
  newsPodContainer: {
    background: "#FFF",
    borderRadius: "8px",
    width: "912px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "120px",
    padding: "24px",
    boxShadow: "4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
    maxHeight: "calc(100% - 100px)",
    overflow: "auto",
  },
  [Breakpoints.Mobile]: {
    newsPodContainer: {
      position: "static",
      width: "calc(100% - 80px)",
      margin: "20px auto",
      maxHeight: "unset",
      transform: "initial",
      padding: "20px",
      overflow: "unset",
    },
    container: {
      width: "100%",
      overflow: "unset",
      height: "unset",
    },
  },
}));
