import { createUseStyles } from "react-jss";

interface ISelectStylesProps {
  isOpen: boolean;
}

export const selectUseStyles = createUseStyles(() => ({
  selectWrapper: {
    position: "relative",
    width: "100%",
  },
  label: {
    position: "absolute",
    fontSize: "12px",
    fontWeight: "400",
    color: ({ isOpen }: ISelectStylesProps) => (isOpen ? "#537FC1" : "#858C9B"),
    padding: "0 4px",
    background: "#FFF",
    left: "8px",
    top: "-7px",
    borderRadius: "8px",
  },
  selectInput: {
    padding: "10px 32px 10px 12px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "600",
    width: "100%",
    "&:focus": {
      border: "1px solid #537FC1",
    },
    height: "40px",
    background: "#FFFFFF",
  },
  optionName: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269 ",
    whiteSpace: "nowrap",
  },
  placeholder: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#C2C4CB",
  },
  arrowIcon: {
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
  },
  optionsContainer: {
    position: "absolute",
    padding: "8px 0",
    background: "#FFFFFF",
    border: "1px solid #C2C4CB",
    width: "100%",
    borderRadius: "8px",
    top: "42px",
    zIndex: "999",
    maxHeight: "136px",
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ECECEC",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  option: {
    padding: "11px 12px",
    cursor: "pointer",
  },
}));
