import CrossIcon from "assets/icons/components/Cross.Icon";
import { defaultTopics } from "constants/newsPodDetails.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Input } from "shared/reusableComponents/Input/Input";
import CustomMultiSelect from "shared/reusableComponents/Select/MultiSelect";
import warningIcon from "assets/icons/warningIcon.svg";
import { newspodDetailsUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/styles";

const Topics = ({
  selectedTopic,
  setSelectedTopic,
  handleDeletCategorie,
  keywordValue,
  setKeywordValue,
  keywords,
  handleBlur,
  handleEnterClick,
  handleDeleteKeyword,
}: {
  selectedTopic: any;
  setSelectedTopic: any;
  handleDeletCategorie: any;
  keywordValue: string;
  setKeywordValue: any;
  keywords: string[];
  handleBlur: any;
  handleEnterClick: any;
  handleDeleteKeyword: any;
}) => {
  const styles = newspodDetailsUseStyles();
  return (
    <Flex width="100%" className={styles.wrapper} direction="column" gap="20px">
      <span className={styles.sectionTitle}>
        Topics<span style={{ color: "red" }}>*</span>
      </span>
      <Flex
        direction="column"
        gap="28px"
        width="100%"
        style={{ maxWidth: "800px" }}
      >
        <Flex direction="column" gap="8px" width="100%">
          <CustomMultiSelect
            label="Add topics"
            activeLabel="Topics"
            options={defaultTopics}
            style={{ width: "100%" }}
            selectedOptions={selectedTopic}
            setSelectedOptions={setSelectedTopic}
          />
          <Flex gap="8px" flexWrap="wrap" style={{ maxWidth: "800px" }}>
            {selectedTopic.map((topic: any) => (
              <Flex
                key={topic}
                gap="8px"
                alignItems="center"
                className={styles.selectedTopic}
                onClick={() => handleDeletCategorie(topic)}
              >
                <span>{topic}</span>
                <CrossIcon />
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex direction="column" gap="8px" width="100%">
          <Input
            label="Add keywords"
            value={keywordValue}
            handleChange={(e: any) =>
              e.target.value.length <= 30 && setKeywordValue(e.target.value)
            }
            handleBlur={handleBlur}
            handleKeyDown={handleEnterClick}
          />
          {!!keywords.length && (
            <Flex flexWrap="wrap" gap="8px" width="100%">
              {keywords.map((keyword: any) => (
                <Flex
                  key={keyword}
                  gap="8px"
                  alignItems="center"
                  className={styles.selectedTopic}
                  onClick={() => handleDeleteKeyword(keyword)}
                >
                  <span className={styles.keyword}>{keyword}</span>
                  <CrossIcon />
                </Flex>
              ))}
            </Flex>
          )}
          <Flex alignItems="center" gap="8px">
            <img src={warningIcon} alt="warnning" />
            <span className={styles.warnningMessage}>
              Please fill out at least one of the two fields above.
            </span>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Topics;
