import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const modalUseStyles = createUseStyles(() => ({
  description: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
    width: "calc(100% - 42px)",
  },
  userDesciption: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#5D6269",
  },

  newsPodName: {
    fontWeight: "600",
    lineHeight: "24px",
    wordWrap: "break-word",
  },
  buttonsContainer: {},
  modalButtons: {},
  container: {},
  [Breakpoints.Mobile]: {
    buttonsContainer: {
      flexDirection: "column-reverse",
    },
    modalButtons: {
      width: "100%",
    },
    container: {
      flexDirection: "column",
      alignItems: "center",
      gap: "12px",
    },
  },
}));
