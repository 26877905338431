import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { Fragment } from "react";

export const DetailsRow = ({
  title,
  chips,
  showAll,
  onShowAll,
  chipProps,
  wrapProps,
}: any) => (
  <Box
    display="flex"
    sx={{
      ...wrapProps,
      flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
    }}
    alignItems="center"
  >
    <Typography sx={{ color: "#858C9B", fontSize: "14px" }}>{title}</Typography>
    <Box display="flex" gap="12px" flexWrap="wrap">
      {chips?.map((item: any, index: number) => (
        <Chip
          key={index}
          sx={{
            ...chipProps,
            display: showAll || index < 5 ? "inline-flex" : "none",
            fontWeight: 500,
          }}
          label={item}
        />
      ))}
      {chips?.length > 5 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                color: "#5D6269",
                fontSize: "16px",
                border: "1px solid #E6E6EB",
                borderRadius: "8px",
                bgcolor: "#fff",
                "& .MuiTooltip-arrow": {
                  color: "#fff",
                },
              },
            },
          }}
          placement="top"
          title={chips?.slice(0, Infinity)?.map((item: any) => (
            <Fragment key={item}>{`${item}, `}</Fragment>
          ))}
        >
          <Chip
            onClick={onShowAll}
            sx={{
              ...chipProps,
              "&:hover": {
                cursor: "pointer",
                background: chipProps.background,
              },
            }}
            label={` ${showAll ? "-" : "+"} ${chips?.length - 5}`}
          />
        </Tooltip>
      )}
    </Box>
  </Box>
);
