import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useGoogleLogin } from "@react-oauth/google";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import CustomLink from "shared/reusableComponents/CustomLink/CustomLink";
import { signinWithGoogle } from "modules/Services/auth.services";
import { ROUTES } from "constants/routes.constants";
import googleIcon from "assets/icons/gogleIcon.svg";
import hotakeAiLogoLight from "assets/icons/hotakeAiLogoLight.svg";
import { authLayoutUseStyles } from "./styles";

const AuthenticationLayout = ({
  children,
  title,
  description,
  linkTo,
  linkName,
  uid,
}: {
  children: any;
  title?: string;
  description?: string;
  linkTo?: string;
  linkName?: string;
  uid?: string | undefined;
}) => {
  const styles = authLayoutUseStyles();
  const navigate = useNavigate();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      mutate(
        uid
          ? {
              code: tokenResponse.code,
              tz: userTimezone,
              newspod_initiation: uid,
            }
          : { code: tokenResponse.code, tz: userTimezone }
      );
    },
    flow: "auth-code",
  });
  const { mutate } = useMutation(signinWithGoogle, {
    onSuccess: (res: any) => {
      localStorage.setItem("access_token", res.data.access);
      localStorage.setItem("refresh_token", res.data.refresh);
      navigate(ROUTES.ON_BOARDING);
    },
  });
  const handleLoginWithGoogle = () => {
    loginWithGoogle();
  };

  return (
    <Flex
      className={styles.wrapper}
      justifyContent="flex-end"
      alignItems="center"
      gap="210px"
    >
      <div>
        <img src={hotakeAiLogoLight} alt="" />
      </div>
      <Flex
        className={styles.authContainer}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="24px"
          className={styles.childrenContainer}
        >
          <h2 className={styles.title}>{title}</h2>
          {children}
          <div className={styles.divider}></div>
          <Flex
            className={styles.authWidthGoogle}
            gap="12px"
            alignItems="center"
            justifyContent="center"
            onClick={() => handleLoginWithGoogle()}
          >
            <img src={googleIcon} alt="google" />{" "}
            <span>Continue with Google</span>
          </Flex>
          <Flex gap="8px">
            <span className={styles.description}>{description}</span>
            {linkTo && <CustomLink to={linkTo}>{linkName}</CustomLink>}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuthenticationLayout;
