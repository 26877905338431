import CrossIcon from "assets/icons/components/Cross.Icon";
import TickIcon from "assets/icons/components/Tick.Icon";
import { RegexConstants } from "constants/regex.constants";
import { useEffect } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { passwordRequirmentsUseStyles } from "shared/reusableComponents/PasswordRequirments/PasswordRequirments.styles";

const PassworRequirements = ({
  password,
  setRequirements,
  requirements,
}: {
  password: string;
  setRequirements: any;
  requirements: any;
}) => {
  const styles = passwordRequirmentsUseStyles();

  useEffect(() => {
    setRequirements({
      uppercaseChar: RegexConstants.uppercaseChar.test(password),
      specialChar: RegexConstants.specialChar.test(password),
      charCount: password.length >= 8,
      numberCount: RegexConstants.number.test(password),
    });

    //eslint-disable-next-line
  }, [password]);

  const generateRequirmentsIcon = (requirement: any) => {
    if (password && requirement) {
      return <TickIcon />;
    } else if (password) {
      return <CrossIcon />;
    } else {
      return null;
    }
  };

  return (
    <Flex className={styles.wrapper} justifyContent="space-between" width="100%">
      <Flex direction="column" gap="12px">
        <Flex
          className={styles.requirements}
          alignItems="center"
          gap="8px"
          style={{
            color: password && (requirements.charCount ? "#039855" : "#E74768"),
          }}
        >
          {generateRequirmentsIcon(requirements.charCount)}
          <span>8 characters minimum</span>
        </Flex>
        <Flex
          className={styles.requirements}
          alignItems="center"
          gap="8px"
          style={{
            color:
              password && (requirements.numberCount ? "#039855" : "#E74768"),
          }}
        >
          {generateRequirmentsIcon(requirements.numberCount)}
          <span>1 number</span>
        </Flex>
      </Flex>
      <Flex direction="column" gap="12px">
        <Flex
          className={styles.requirements}
          alignItems="center"
          gap="8px"
          style={{
            color:
              password && (requirements.specialChar ? "#039855" : "#E74768"),
          }}
        >
          {generateRequirmentsIcon(requirements.specialChar)}
          <span>1 special character</span>
        </Flex>
        <Flex
          className={styles.requirements}
          alignItems="center"
          gap="8px"
          style={{
            color:
              password && (requirements.uppercaseChar ? "#039855" : "#E74768"),
          }}
        >
          {generateRequirmentsIcon(requirements.uppercaseChar)}
          <span>1 upper&low character</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PassworRequirements;
