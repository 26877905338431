import React, { ReactNode, CSSProperties } from "react";

import { ButtonVariants } from "./Button.types";
import { buttonUseStyles } from "./Button.styles";

export interface ButtonProps {
  variant: ButtonVariants;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  style?: CSSProperties;
  type?: any;
  className?: any;
}

const getButtonStylesByVariant = (
  variant: ButtonVariants,
  disabled: boolean
) => {
  const buttonStyles = buttonUseStyles({ disabled });
  switch (variant) {
    case ButtonVariants.Primary:
      return buttonStyles.primaryButton;
    case ButtonVariants.Secondary:
      return buttonStyles.secondaryButton;
      case ButtonVariants.Outlined:
        return buttonStyles.outlinedButton
    default:
      return buttonStyles.primaryButton;
  }
};

export const Button: React.FC<any> = ({
  variant,
  disabled = false,
  handleClick,
  children,
  style,
  type,
  className,
}: ButtonProps) => {
  const wholeClassName = `${className} ${getButtonStylesByVariant(
    variant,
    !!disabled
  )}`;
  return (
    <button
      onClick={handleClick}
      style={style}
      className={wholeClassName}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};
