import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { MenuProps, customSelectUseStyles } from "./Select.styles";

const CustomSelect: React.FC<any> = ({
  label,
  value,
  options,
  style,
  handleChange,
  width = "174px",
  selectedValue,
}: {
  label?: string;
  value?: any;
  options?: any;
  style?: any;
  handleChange: any;
  width: string;
  selectedValue?: any;
}) => {
  const classes = customSelectUseStyles();

  return (
    <FormControl style={{ width: width }}>
      <InputLabel
        id="demo-simple-select-label"
        className={classes.label}
        style={{ top: value ? "0" : "-8px" }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={handleChange}
        style={style}
        MenuProps={MenuProps}
        className={classes.select}
        sx={{
          "&& fieldset": {
            borderRadius: "8px",
          },
          "&:hover": {
            "&& fieldset": {
              border: "1px solid #A3A9B5",
            },
          },
        }}
      >
        {options?.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            className={classes.menuItem}
            disabled={selectedValue === option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
