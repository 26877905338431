import { useNavigate } from "react-router-dom";

import { Article } from "types/newsPods.types";
import { formatDate, formatSeconds } from "helpers/convertTimes";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import Waveform from "shared/reusableComponents/WaveForm/WaveForm";

import listeningDurationIcon from "assets/icons/listeningDurationIcon.svg";
import readingDurationIcon from "assets/icons/readingDurationIcon.svg";
import articleLinkIcon from "assets/icons/articleLinkIcon.svg";

import { newsfeedItemUseStyles } from "./NewsfeedsItem.styles";

const NewsfeedsItem = ({
  article,
  newsPod = false,
}: {
  article: Article;
  newsPod?: boolean;
}) => {
  const styles = newsfeedItemUseStyles();
  const navigate = useNavigate();

  const {
    newspod_uuid,
    newspod_name,
    publication_datetime,
    source_logo,
    category,
    listening_duration,
    reading_duration,
    image_url,
    summary,
    audio_url,
    title,
    url,
  } = article;

  const navigateToNewsPod = () => {
    if (newspod_uuid) {
      navigate(`/newsPod/${newspod_uuid}`);
    }
  };

  return (
    <Flex
      direction="column"
      gap="20px"
      width="100%"
      className={styles.newsfeedWrapper}
    >
      <Flex direction="column" gap="8px" width="100%">
        {!newsPod && (
          <span onClick={navigateToNewsPod} className={styles.newspodName}>
            {newspod_name}
          </span>
        )}
        <Flex gap="8px" alignItems="center">
          <span className={styles.title}>{title}</span>
          <a href={url} target="_blank" rel="noreferrer">
            <img
              src={articleLinkIcon}
              alt="go to article"
              style={{ display: "block" }}
            />
          </a>
        </Flex>
        <span className={styles.publishedDate}>
          {formatDate(publication_datetime)}
        </span>
        <Flex
          justifyContent="space-between"
          width="100%"
          className={styles.articleInfoContainer}
        >
          <Flex
            gap="16px"
            alignItems="center"
            className={styles.sourceTopicContainer}
          >
            <Flex gap="12px" alignItems="center">
              <span className={styles.source}>From:</span>
              <img src={source_logo} alt="source_logo" height={24} />
            </Flex>
            <div className={styles.divider} />
            <Flex gap="12px" alignItems="center">
              <span className={styles.source}>Topic:</span>
              <span className={styles.topic}>{category}</span>
            </Flex>
          </Flex>
          <Flex className={styles.durationsContainer} gap="12px">
            <Flex gap="8px" alignItems="center">
              <img src={listeningDurationIcon} alt="" />
              <span className={styles.durationValue}>
                {formatSeconds(listening_duration)}
              </span>
            </Flex>
            <Flex gap="8px" alignItems="center">
              <img src={readingDurationIcon} alt="readingDurationIcon" />
              <span className={styles.durationValue}>
                {formatSeconds(reading_duration)}
              </span>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="24px"
        justifyContent="space-between"
        className={styles.articleContainer}
      >
        <div className={styles.imageContainer}>
          <img src={image_url} alt="news img" />
        </div>
        <Flex
          style={{ minHeight: "200px" }}
          justifyContent="space-between"
          direction="column"
          gap="16px"
        >
          <span className={styles.summary}>{summary}</span>
          <Waveform audio={audio_url} listeningDuration={listening_duration} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NewsfeedsItem;
