import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { subscribe } from "modules/Services/subscriptions.services";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { variables } from "variables";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import { billingUseStyles } from "modules/Dashboard/Profile/components/Billing/Billing.styles";

const { REACT_APP_CONFIRM_PAYMENT_RETURN_URL } = variables;

const CheckoutForm = ({
  setSubscriptionType,
  priceId,
}: {
  setSubscriptionType: any;
  priceId: any;
}) => {
  const styles = billingUseStyles();
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { mutate, isLoading } = useMutation(subscribe, {
    onSuccess: (res) => {
      setResponse(res.data);
    },
  });

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  useEffect(() => {
    if (response) {
      const { type, client_secret }: any = response;

      const confirmIntent =
        type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
      confirmIntent({
        elements,
        clientSecret: client_secret,
        confirmParams: {
          return_url: REACT_APP_CONFIRM_PAYMENT_RETURN_URL,
        },
      }).then(({ error }: { error: any }) => {
        if (error) {
          handleError(error);
        }
      });
    }

    //eslint-disable-next-line
  }, [response]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }
    setLoading(true);
    const { error: submitError } = await (elements as any).submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    mutate({ price_id: priceId });
  };

  return (
    <form className={styles.cardInfoForm} onSubmit={handleSubmit}>
      <PaymentElement />
      <Flex
        className={styles.buttonsContainer}
        gap="16px"
        justifyContent="flex-end"
        width="100%"
      >
        <Button
          variant={ButtonVariants.Secondary}
          handleClick={() => {
            setSubscriptionType("monthly&yearly");
          }}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={loading || isLoading}>
          {isLoading || loading ? <Loader /> : "Confirm"}
        </Button>
      </Flex>
      <ErrorToaster message={errorMessage} setMessage={setErrorMessage} />
    </form>
  );
};

export default CheckoutForm;
