import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { ROUTES } from "../../../../constants/routes.constants";
import AuthenticationLayout from "Layouts/Authentication/AuthenticationLayout";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import { Input } from "shared/reusableComponents/Input/Input";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { signUpUser } from "modules/Services/auth.services";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import PassworRequirements from "shared/reusableComponents/PasswordRequirments/PasswordRequirments";
import { validateEmail } from "helpers/validateEmail";
import MagicLink from "modules/Autentication/MagicLink/components/MagicLink";
import warningIcon from "assets/icons/warningIcon.svg";
import { signUpUseStyles } from "./SignUp.styles";

const SignUp = () => {
  const styles = signUpUseStyles();
  const location = useLocation();
  const uid = location.pathname.split("/")[2];

  const [errorMessage, setErrorMessage] = useState<any>({
    email: "",
    password: "",
  });

  const [errorMessageForToaster, setErrorMessageForToaster] =
    useState<string>("");
  const [signUpCredentials, setSignUpCredentials] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [requirements, setRequirements] = useState({
    charCount: false,
    specialChar: false,
    numberCount: false,
    uppercaseChar: false,
  });
  const [isSignUpManually, setIsSignUpManually] = useState<boolean>(false);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();

  const { mutate } = useMutation(
    () =>
      signUpUser(
        uid
          ? { ...signUpCredentials, tz: userTimezone, newspod_initiation: uid }
          : { ...signUpCredentials, tz: userTimezone }
      ),
    {
      onSuccess: (res) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        navigate(ROUTES.ON_BOARDING);
      },
      onError: ({ response }) => {
        const key = response.data.code;
        setErrorMessageForToaster(errorSuccessMessages[key]);
      },
    }
  );

  const handleSignUp = (e: any) => {
    e.preventDefault();
    const isValidEmail = validateEmail(signUpCredentials.email);
    if (!isValidEmail) {
      setErrorMessage({
        ...errorMessage,
        email: errorSuccessMessages.incorrect,
      });
    } else {
      mutate();
    }
  };
  const handleConfirmPasswordChange = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    if (signUpCredentials.password !== value) {
      setErrorMessage({
        ...errorMessage,
        password: errorSuccessMessages.noMatch,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        password: "",
      });
    }

    setSignUpCredentials({
      ...signUpCredentials,
      confirm_password: value,
    });
  };
  const handlePasswordChange = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    setSignUpCredentials({
      ...signUpCredentials,
      password: value,
    });
  };

  const generatePrivacyPolicyLinkContent = () => {
    return (
      <Flex gap="8px">
        <img src={warningIcon} alt="warningIcon" />
        <span className={styles.redirectContentContainer}>
          By creating an account, you confirm you’ve reviewed our{" "}
          <a
            href="https://hottake.ai/about-project/privacy-policy"
            className={styles.redirectContentLink}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy.
          </a>
        </span>
      </Flex>
    );
  };

  const isFormValid = () => {
    return (
      signUpCredentials.email.trim() !== "" &&
      signUpCredentials.password.trim() !== "" &&
      signUpCredentials.name.trim() !== "" &&
      signUpCredentials.confirm_password.trim() !== "" &&
      requirements.charCount &&
      requirements.numberCount &&
      requirements.specialChar &&
      requirements.uppercaseChar &&
      !errorMessage.password
    );
  };

  return isSignUpManually ? (
    <AuthenticationLayout
      title="Create your account"
      description="Already have an account?"
      linkTo={ROUTES.SIGNIN}
      linkName="Login"
      uid={uid}
    >
      <form className={styles.form} onSubmit={handleSignUp}>
        <Input
          label="Name"
          defaultValue={signUpCredentials.name}
          handleChange={(e) =>
            setSignUpCredentials({
              ...signUpCredentials,
              name: (e.target as HTMLInputElement).value,
            })
          }
        />
        <Input
          label="Email"
          inputType="email"
          defaultValue={signUpCredentials.email}
          handleChange={(e) => {
            setErrorMessage({
              ...errorMessage,
              email: "",
            });
            setSignUpCredentials({
              ...signUpCredentials,
              email: (e.target as HTMLInputElement).value,
            });
          }}
          hasError={!!errorMessage.email}
          errorText={errorMessage.email}
        />
        <Flex direction="column" gap="12px">
          <Input
            label="Password"
            inputType="password"
            defaultValue={signUpCredentials.password}
            handleChange={handlePasswordChange}
          />
          {signUpCredentials.password && (
            <PassworRequirements
              password={signUpCredentials.password}
              setRequirements={setRequirements}
              requirements={requirements}
            />
          )}
        </Flex>

        <Input
          label="Confirm Password"
          defaultValue={signUpCredentials.confirm_password}
          inputType="password"
          handleChange={handleConfirmPasswordChange}
          hasError={!!errorMessage.password}
          errorText={errorMessage.password}
        />
        {generatePrivacyPolicyLinkContent()}
        <Button
          variant={ButtonVariants.Primary}
          type="submit"
          disabled={!isFormValid()}
          style={{ marginTop: "8px" }}
        >
          Get started
        </Button>
      </form>
      <ErrorToaster
        message={errorMessageForToaster}
        setMessage={setErrorMessageForToaster}
      />
    </AuthenticationLayout>
  ) : (
    <MagicLink
      setIsActionManually={setIsSignUpManually}
      type="sign-up"
      uid={uid}
    />
  );
};
export default SignUp;
