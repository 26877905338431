import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { RegexConstants } from "constants/regex.constants";
import { passwordChange } from "modules/Services/auth.services";
import SuccessToaster from "shared/reusableComponents/Toster/SuccessToaster";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import { Input } from "shared/reusableComponents/Input/Input";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import TickIcon from "assets/icons/components/Tick.Icon";
import CrossIcon from "assets/icons/components/Cross.Icon";
import DoteIcon from "assets/icons/components/Dote.Icon";
import EditIcon from "assets/icons/components/Edit.Icon";
import { profileSettingUseStyles } from "modules/Dashboard/Profile/components/styles";

const ChangePassword = () => {
  const styles = profileSettingUseStyles();
  const [passwords, setPasswords] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const [requirements, setRequirements] = useState({
    charCount: false,
    specialChar: false,
    numberCount: false,
    uppercaseChar: false,
  });
  const [isChange, setIsChange] = useState<boolean>(false);
  const [isChangeBtnDisable, setIsChangeBtnDisable] = useState<boolean>(false);
  const [tosterMessage, setTosterMessage] = useState<string>("");
  const [errorMessageForToaster, setErrorMessageForToaster] =
    useState<string>("");

  const { mutate: passwordChangeMutate, isLoading } = useMutation(
    () => passwordChange(passwords),
    {
      onSuccess: () => {
        setIsChange(false);
        setTosterMessage(errorSuccessMessages.resetConfirmed);
        setPasswords({
          old_password: "",
          password: "",
          confirm_password: "",
        });
      },
      onError: ({ response }) => {
        const key = response.data.code;
        setErrorMessageForToaster(errorSuccessMessages[key]);
      },
    }
  );

  const validationSchema = [
    {
      validationName: "8 characters minimum",
      requirements: requirements.charCount,
    },
    {
      validationName: "1 number",
      requirements: requirements.numberCount,
    },
    {
      validationName: "1 special character",
      requirements: requirements.specialChar,
    },
    {
      validationName: "1 upper&low character",
      requirements: requirements.uppercaseChar,
    },
  ];

  const handlePasswordChange = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    setPasswords({
      ...passwords,
      password: value,
    });
    setRequirements({
      uppercaseChar: RegexConstants.uppercaseChar.test(value),
      specialChar: RegexConstants.specialChar.test(value),
      charCount: value.length >= 8,
      numberCount: RegexConstants.number.test(value),
    });
  };

  useEffect(() => {
    const isRequirmentsTrue = (value: any) => !!value;

    setIsChangeBtnDisable(
      !Object.values(requirements).every(isRequirmentsTrue) ||
        !passwords.old_password ||
        passwords.confirm_password !== passwords.password ||
        isLoading
    );

    //eslint-disable-next-line
  }, [passwords]);

  const handlePasswordChangeMutate = () => {
    if (passwords.old_password === passwords.password) {
      setErrorMessageForToaster(
        "The new password you’ve entered is the same as your old password. Please choose a different one."
      );
    } else {
      passwordChangeMutate();
    }
  };

  const generateRequirmentsIcon = (requirement: any) => {
    if (passwords.password && requirement) {
      return <TickIcon />;
    } else if (passwords.password) {
      return <CrossIcon />;
    } else {
      return <DoteIcon />;
    }
  };

  const generatePasswordRequirements = () => {
    return (
      <Flex
        className={styles.requirementsWrapper}
        direction="column"
        gap="35px"
      >
        {validationSchema?.map(({ validationName, requirements }, index) => {
          return (
            <Flex
              key={index}
              className={styles.requirements}
              alignItems="center"
              gap="15px"
              style={{
                color:
                  passwords.password && (requirements ? "#039855" : "#E74768"),
                height: "20px",
              }}
            >
              {generateRequirmentsIcon(requirements)}
              <span>{validationName}</span>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const generateContent = () => {
    if (isChange) {
      return (
        <Flex
          className={styles.changePasswordWrapper}
          direction="column"
          gap="32px"
        >
          <span className={styles.sectionTitle}>Change password</span>
          <Flex width="100%" className={styles.changePasswordInputsWrapper}>
            <Flex
              direction="column"
              gap="30px"
              className={styles.changePasswordInputs}
            >
              <Input
                label="Current password"
                inputType="password"
                defaultValue={passwords.old_password}
                handleChange={(e: any) =>
                  setPasswords({
                    ...passwords,
                    old_password: e?.target?.value,
                  })
                }
              />
              <Input
                label="New password"
                inputType="password"
                defaultValue={passwords.password}
                handleChange={handlePasswordChange}
              />
              <Input
                label="Confirm password"
                inputType="password"
                defaultValue={passwords.confirm_password}
                handleChange={(e: any) =>
                  setPasswords({
                    ...passwords,
                    confirm_password: e?.target?.value,
                  })
                }
                hasError={
                  !!passwords.confirm_password &&
                  passwords.password !== passwords.confirm_password
                }
                errorText={errorSuccessMessages.noMatch}
              />
            </Flex>
            {generatePasswordRequirements()}
          </Flex>
          <Flex
            className={styles.buttonsContainer}
            gap="16px"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant={ButtonVariants.Secondary}
              handleClick={() => setIsChange(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={isChangeBtnDisable}
              handleClick={handlePasswordChangeMutate}
            >
              {isLoading ? <Loader /> : "Change"}
            </Button>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <Flex
          className={styles.changePasswordContainer}
          justifyContent="space-between"
          width="100%"
        >
          <span className={styles.passwordSecurity}>Password</span>
          <Flex
            gap="8px"
            className={styles.nameEditContainer}
            onClick={() => setIsChange(true)}
          >
            <EditIcon />
            <span>Change</span>
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <>
      {generateContent()}
      <SuccessToaster message={tosterMessage} setMessage={setTosterMessage} />
      <ErrorToaster
        message={errorMessageForToaster}
        setMessage={setErrorMessageForToaster}
      />
    </>
  );
};
export default ChangePassword;
