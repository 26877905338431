import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Button } from "shared/reusableComponents/Button/Button";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { resetPassword } from "modules/Services/auth.services";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { Input } from "shared/reusableComponents/Input/Input";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import hotakeAiLogoLight from "assets/icons/hotakeAiLogoLight.svg";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { validateEmail } from "helpers/validateEmail";
import { forgotPasswordUseStyles } from "./ForgotPassword.styles";

const ForgotPassword = () => {
  const styles = forgotPasswordUseStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isSent, setIsSent] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  const [errorForToaster, setErrorForToaster] = useState<string>("");
  const { mutate, isLoading } = useMutation(() => resetPassword({ email }), {
    onSuccess: () => {
      setIsSent(true);
    },
    onError: ({ response }) => {
      const key = response.data.code;
      setErrorForToaster(errorSuccessMessages[key]);
    },
  });
  const handleSend = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      setErrorText(errorSuccessMessages.incorrect);
    } else {
      !isLoading && mutate();
    }
  };

  const generateContent = () => {
    if (isSent) {
      return (
        <Flex
          className={styles.forgotPassContainer}
          direction="column"
          alignItems="center"
          gap="40px"
        >
          <Flex direction="column" alignItems="center" gap="12px">
            <h2 className={styles.title}>Check your email</h2>
            <span className={styles.descripton}>
              We have sent password recovery instructions to your email.
            </span>
          </Flex>
          <span className={styles.skip} onClick={() => navigate("/")}>
            Skip, I’ll confirm later
          </span>
        </Flex>
      );
    } else {
      return (
        <Flex
          className={styles.forgotPassContainer}
          gap="40px"
          direction="column"
        >
          <Flex direction="column" alignItems="center" gap="12px">
            <h2 className={styles.title}>Reset password</h2>
            <p className={styles.descripton}>
              Enter your email and we’ll send you the link to reset your
              password.
            </p>
          </Flex>
          <Input
            label="Email"
            defaultValue={email}
            handleChange={(e: any) => {
              setErrorText("");
              setEmail(e?.target?.value);
            }}
            hasError={!!errorText}
            errorText={errorText}
          />
          <Button
            variant={ButtonVariants.Primary}
            style={{ width: "100%" }}
            handleClick={handleSend}
            disabled={!email}
          >
            {isLoading ? <Loader /> : "Send"}
          </Button>
        </Flex>
      );
    }
  };
  return (
    <Flex
      className={styles.wrapper}
      justifyContent="flex-end"
      alignItems="center"
      gap="210px"
    >
      <div>
        <img src={hotakeAiLogoLight} alt="" />
      </div>
      <Flex
        className={styles.authContainer}
        alignItems="center"
        justifyContent="center"
        width="55%"
      >
        {generateContent()}
      </Flex>
      <ErrorToaster message={errorForToaster} setMessage={setErrorForToaster} />
    </Flex>
  );
};

export default ForgotPassword;
