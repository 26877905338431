import { useNavigate } from "react-router-dom";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { ROUTES } from "constants/routes.constants";
import subscriptionCanceled from "assets/icons/subscriptionCanceled.svg";
import { modalsUseStyles } from "modals/styles";

const SubscriptionCanceled = ({
  handleCancel,
}: {
  handleCancel: () => void;
}) => {
  const styles = modalsUseStyles();
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="24px" alignItems="center">
      <div className={styles.imgContainer}>
        <img src={subscriptionCanceled} alt="" />
      </div>
      <Flex
        className={styles.container}
        direction="column"
        gap="8px"
        width="calc(100% - 40px)"
        alignItems="center"
      >
        <span className={styles.info}>You do not have an active plan.</span>
        <span className={styles.message}>
          Please add a plan to receive news.
        </span>
      </Flex>
      <Flex
        className={styles.buttonsContainer}
        width="calc(100% - 48px)"
        gap="20px"
        justifyContent="flex-end"
      >
        <Button variant={ButtonVariants.Secondary} handleClick={handleCancel}>
          Cancel
        </Button>
        <Button
          handleClick={() => {
            navigate(ROUTES.PROFILE, {
              state: {
                activeTab: "Billing information",
              },
            });
            handleCancel();
          }}
        >
          Add plan
        </Button>
      </Flex>
    </Flex>
  );
};
export default SubscriptionCanceled;
