import { createContext, useContext, useState } from "react";

interface DashboardContextProps {
  activeTab: string;
  setActiveTab: (a: string) => void;
}

const dashboard = createContext<DashboardContextProps>({
  activeTab: "Personal details",
  setActiveTab: (a: string) => {},
});

const useProvideDashboard = () => {
  const [activeTab, setActiveTab] = useState("Personal details");
  const [isOpenCreateNewspodModal, setIsOpenCreateNewspodModal] =
    useState<any>({ open: false, type: "edit" });
  return {
    activeTab,
    setActiveTab,
    isOpenCreateNewspodModal,
    setIsOpenCreateNewspodModal,
  };
};
export const DashboardContext = ({ children }: { children: any }) => {
  const dashboardData = useProvideDashboard();
  return (
    <dashboard.Provider value={dashboardData}>{children}</dashboard.Provider>
  );
};
export const useDashboardContext = () =>
  useContext<DashboardContextProps>(dashboard);
