import { useState } from "react";
import { useMutation } from "react-query";
import AuthenticationLayout from "Layouts/Authentication/AuthenticationLayout";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { ROUTES } from "constants/routes.constants";
import { validateEmail } from "helpers/validateEmail";
import { magicLink } from "modules/Services/auth.services";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Input } from "shared/reusableComponents/Input/Input";
import warningIcon from "assets/icons/warningIcon.svg";
import MagicLinkSent from "modules/Autentication/MagicLink/components/MagicLinkSent";
import { magicLinkUseStyles } from "modules/Autentication/MagicLink/components/MagicLink.styles";

const MagicLink = ({
  type,
  setIsActionManually,
  uid,
}: {
  type: string;
  setIsActionManually: (a: boolean) => void;
  uid?: string | undefined;
}) => {
  const styles = magicLinkUseStyles();
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSent, setIsSent] = useState<boolean>(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { mutate, isLoading } = useMutation(magicLink, {
    onSuccess: () => {
      setIsSent(true);
    },
  });
  const handleSend = () => {
    const isValidEmail = validateEmail(email);
    if (isValidEmail && !isLoading) {
      mutate(
        uid
          ? { email, tz: userTimezone, newspod_initiation: uid }
          : { email, tz: userTimezone }
      );
    } else if (!isValidEmail) {
      setErrorMessage(errorSuccessMessages.incorrect);
    }
  };

  const generatePrivacyPolicyLinkContent = () => {
    return (
      <Flex gap="8px">
        <img src={warningIcon} alt="" />
        <span className={styles.redirectContentContainer}>
          By creating an account, you confirm you’ve reviewed our{" "}
          <a
            href="https://hottake.ai/about-project/privacy-policy"
            className={styles.redirectContentLink}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy.
          </a>{" "}
        </span>
      </Flex>
    );
  };

  const generateRedirectManualyContent = () => {
    return (
      <Flex className={styles.redirectContentWrapper} gap="8px">
        <img src={warningIcon} alt="" />
        {type === "sign-in" ? (
          <span className={styles.redirectContentContainer}>
            A magic code for a password-free login will be sent to you via
            email. Or you can{" "}
            <span
              className={styles.redirectContentLink}
              onClick={() => setIsActionManually(true)}
            >
              {" "}
              login manually instead.{" "}
            </span>
          </span>
        ) : (
          <span className={styles.redirectContentContainer}>
            A magic code for a password-free sign up will be sent to you via
            email. Or you can{" "}
            <span
              className={styles.redirectContentLink}
              onClick={() => setIsActionManually(true)}
            >
              sign up manually instead.
            </span>
          </span>
        )}
      </Flex>
    );
  };

  const generateContent = () => {
    if (isSent) {
      return <MagicLinkSent email={email} />;
    } else {
      return (
        <AuthenticationLayout
          title={type === "sign-in" ? "Welcome back" : "Create your account"}
          description={
            type === "sign-in"
              ? "Don’t have an account?"
              : "Already have an account?"
          }
          linkTo={type === "sign-in" ? ROUTES.SIGNUP : ROUTES.SIGNIN}
          linkName={type === "sign-in" ? "Sign Up" : "Sign In"}
          uid={uid}
        >
          <Flex direction="column" gap="40px" width="100%">
            <Input
              label="Email"
              defaultValue={email}
              value={email}
              hasError={!!errorMessage}
              errorText={errorMessage}
              handleChange={(e) => {
                setErrorMessage("");
                setEmail((e.target as HTMLInputElement).value);
              }}
            />
            <Flex direction="column" gap="16px">
              {type === "sign-up" && generatePrivacyPolicyLinkContent()}
              <Button
                variant={ButtonVariants.Primary}
                disabled={!validateEmail(email)}
                style={{ width: "100%" }}
                handleClick={handleSend}
              >
                {isLoading ? <Loader /> : "Send magic link"}
              </Button>
              {generateRedirectManualyContent()}
            </Flex>
          </Flex>
        </AuthenticationLayout>
      );
    }
  };

  return generateContent();
};

export default MagicLink;
