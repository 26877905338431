import { createUseStyles } from "react-jss";
import { Breakpoints } from "constants/styles.contants";
const backgroundImage = require("assets/icons/signInBackground.png");

export const magicLinkConfirmUseStyles = createUseStyles(() => ({
  wrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
  authContainer: {
    background: "#FFF",
    borderRadius: "80px 0px 0px 80px",
    height: "100vh",
  },
  linkExpiredWrapper: {
    padding: "40px 80px",
    maxWidth: "400px",
  },
  linkExpiredMessage: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#5D6269",
  },
  linkExpiredInfo: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#A3A9B5",
    textAlign: "center",
  },
  [Breakpoints.Mobile]: {
    wrapper: {
      flexDirection: "column",
      gap: "32px",
      justifyContent: "center",
    },
    linkExpiredWrapper: {
      padding: "20px",
      width: "calc(100% - 40px)",
    },
    authContainer: {
      width: "calc(100% - 30px)",
      height: "auto",
      borderRadius: "8px",
    },
  },
}));
