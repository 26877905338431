import { CSSProperties, forwardRef, useState } from "react";
import { InputVariant } from "./Input.types";
import eyeIcon from "assets/icons/eyeIcon.svg";
import noEyeIcon from "assets/icons/noEyeIcon.svg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { CssTextField, inputUseStyles } from "./Input.styles";
import { Breakpoints } from "constants/styles.contants";

export interface InputProps {
  errorMessage?: string;
  errorStyles?: CSSProperties;
  className?: string;
  hasDelete?: boolean;
  label?: string;
  floatingLabel?: string;
  value?: string | null | number;
  placeholder?: string;
  variant?: InputVariant;
  isRequired?: boolean;
  style?: CSSProperties;
  placeHolderStyles?: CSSProperties;
  htmlForName?: string;
  inputSearchStyle?: CSSProperties;
  hasError?: boolean;
  errorText?: string;
  labelStyle?: CSSProperties;
  inputType?: string;
  hintText?: string;
  name?: string;
  isReadonly?: boolean;
  hasEnter?: boolean;
  defaultValue?: string | null | number;
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  handleDelete?: () => void;
  handleBlur?: () => void;
  handleKeyDown?: (e: any) => void;
  handleFocus?: (() => void) | undefined;
  hanleClearClick?: () => void;
  handleSearch?: () => void;
}

export const Input = forwardRef(
  (
    {
      variant = InputVariant.Outlined,
      label,
      defaultValue,
      inputType = "text",
      hasError,
      errorText = "",
      value,
      style,
      className,
      handleBlur,
      handleChange,
      handleKeyDown,
    }: InputProps,
    ref: any
  ) => {
    const styles = inputUseStyles();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isBlur, setIsBlur] = useState<boolean>(false);

    const handleInputBlur = () => {
      handleBlur && handleBlur();
      setIsBlur(true);
    };

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <CssTextField
          inputRef={ref}
          label={label}
          variant={variant}
          type={inputType === "password" && !showPassword ? "password" : "text"}
          defaultValue={defaultValue}
          value={value}
          error={hasError}
          onChange={(event: any) => handleChange && handleChange(event)}
          onBlur={handleInputBlur}
          onFocus={() => setIsBlur(false)}
          onKeyDown={handleKeyDown}
          style={style}
          className={className}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              "&>label": {
                top: defaultValue || value ? "0" : "-7px",
                color: hasError ? "#E74768" : "#C2C4CB",
                fontSize: "16px",
                fontWeight: "400",
                "&.Mui-focused": {
                  top: "0px",
                  color: "#537FC1",
                },
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                border: hasError ? "" : "1px solid #A3A9B5",
              },
            },
            [Breakpoints.Mobile]:{
              
            }
          }}
        />
        {inputType === "password" && (
          <div
            className={styles.eyeIcon}
            onClick={() => setShowPassword(!showPassword)}
          >
            <img src={showPassword ? noEyeIcon : eyeIcon} alt="eye" />
          </div>
        )}
        {hasError && isBlur && (defaultValue || value) && (
          <div style={{ marginTop: "4px", paddingLeft: "8px" }}>
            <ErrorMessage text={errorText} />
          </div>
        )}
      </div>
    );
  }
);
