import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDashboardContext } from "context/Dashboard.context";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import warningIcon from "assets/icons/warningIcon.svg";
import visa from "assets/icons/cardIcons/visa.icon.svg";
import mastercard from "assets/icons/cardIcons/mastercard.icon.svg";
import unionPay from "assets/icons/cardIcons/unionPay.icon.svg";
import cancelPlan from "assets/icons/cancelPlan.svg";
import amex from "assets/icons/cardIcons/amex.icon.svg";
import { formatDate, formatExpirationDate } from "helpers/convertTimes";
import { changePlan } from "modules/Services/subscriptions.services";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { billingUseStyles } from "modules/Dashboard/Profile/components/Billing/Billing.styles";
import { userState } from "constants/userInfo.constants";

const BillingInformation = ({
  billingInformation,
  userInfo,
}: {
  billingInformation: any;
  userInfo: any;
}) => {
  const styles = billingUseStyles();
  const { setActiveTab } = useDashboardContext();
  const [currentPlan, setCurrentPlan] = useState<string>("");
  const [price, setPrice] = useState<number>();
  const [cardType, setCardType] = useState<string>("");
  const [expireDate, setExpireDate] = useState<string>("");
  const [periodEnd, setPeriodEnd] = useState<string>("");

  const { mutate: changePlanMutate, isLoading } = useMutation(changePlan, {
    onSuccess: ({ data }) => {
      setActiveTab("Personal details");
      window.open(data.url, "_blank");
    },
  });

  const generateCardIcon = (brand: string) => {
    switch (brand) {
      case "visa":
        return <img src={visa} alt="" />;
      case "mastercard":
        return <img src={mastercard} alt="" />;
      case "unionPay":
        return <img src={unionPay} alt="" />;
      case "amex":
        return <img src={amex} alt="" />;
      default:
        break;
    }
  };

  useEffect(() => {
    setCurrentPlan(
      billingInformation?.subscriptions[0]?.subscription_plan?.frequency?.interval
        .charAt(0)
        .toUpperCase() +
        billingInformation?.subscriptions[0]?.subscription_plan?.frequency.interval.slice(
          1
        ) +
        "ly"
    );
    setPrice(billingInformation?.subscriptions[0]?.subscription_plan?.price);
    setCardType(
      billingInformation?.cards[0]?.brand?.charAt(0).toUpperCase() +
        billingInformation?.cards[0]?.brand?.slice(1)
    );
    setExpireDate(
      formatExpirationDate(
        billingInformation?.cards[0]?.exp_month,
        billingInformation?.cards[0]?.exp_year
      )
    );
    setPeriodEnd(formatDate(billingInformation?.subscriptions[0]?.period_end));
  }, [billingInformation]);

  const generateContent = () => {
    if (billingInformation?.subscriptions[0]?.cancel_at) {
      return (
        <Flex
          className={styles.canceledPlanContainer}
          gap="24px"
          width="100%"
          direction="column"
          alignItems="center"
        >
          <img src={cancelPlan} alt="" />
          <span className={styles.canceledMessage}>
            Subscription cancelled, but active until{" "}
            <span className={styles.canceledDate}>
              {formatDate(billingInformation?.subscriptions[0]?.cancel_at)}
            </span>
            .
          </span>
        </Flex>
      );
    }
    return (
      <>
        <Flex
          gap="12px"
          width="100%"
          direction="column"
          alignItems="flex-start"
        >
          <Flex width="100%" className={styles.wrapper}>
            <Flex
              className={styles.personalDetailsWrapper}
              gap="32px"
              direction="column"
              width="91%"
            >
              <span className={styles.planDetails}>Plan details</span>
              <Flex
                className={styles.detailsContainer}
                direction="column"
                gap="28px"
                width="100%"
              >
                <Flex width="100%" className={styles.subscriptionInfo}>
                  <span className={styles.detailName}>Current plan</span>
                  <span className={styles.detail}>{currentPlan}</span>
                </Flex>
                <Flex width="100%" className={styles.subscriptionInfo}>
                  <span className={styles.detailName}>Amount</span>
                  <span className={styles.detail}>${price}</span>
                </Flex>
                <Flex width="100%" className={styles.subscriptionInfo}>
                  <span className={styles.detailName}>Next billing date</span>
                  <span className={styles.detail}>{periodEnd}</span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className={styles.personalDetailsWrapper}
              direction="column"
              gap="32px"
              width="100%"
            >
              <span className={styles.planDetails}>Payment information</span>
              <Flex width="100%" gap="16px" direction="column">
                <span>{userInfo.name}</span>
                <Flex alignItems="center" gap="20px">
                  {generateCardIcon(billingInformation?.cards[0]?.brand)}
                  <Flex direction="column" gap="8px">
                    <span className={styles.cardType}>
                      {cardType} ****{billingInformation?.cards[0]?.last4}
                    </span>
                    <span className={styles.expireDate}>
                      Card expires at {expireDate}
                    </span>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {(userInfo.state === userState.trialing_not_subscribed ||
            userInfo.state === userState.trialing_subscribed) && (
            <Flex gap="8px">
              <img src={warningIcon} alt="" />
              <span className={styles.message}>
                The selected subscription plan will start at the end of the
                3-day trial.
              </span>
            </Flex>
          )}
        </Flex>
        <Button
          variant={ButtonVariants.Outlined}
          handleClick={() => changePlanMutate()}
          style={{ marginLeft: "auto" }}
        >
          {isLoading ? <Loader /> : "Manage plan "}
        </Button>
      </>
    );
  };

  return generateContent();
};

export default BillingInformation;
