import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const billingUseStyles = createUseStyles(() => ({
  monthlyWrapper: {
    padding: "40px",
    border: "1px solid #E6E6EB",
    background: "#F8FAFC",
    borderRadius: "16px",
    width: "100%",
  },
  monthlyPlan: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#5D6269",
  },
  price: {
    fontSize: "38px",
    fontWeight: "800",
    color: "#5D6269",
    fontFamily: "Avenir",
  },
  allowTo: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#A3A9B5",
  },
  feature: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
  },
  tickIcon: {
    padding: "6px",
    background: "rgba(83, 127, 193, 0.20)",
    borderRadius: "50%",
    color: "#537FC1",
  },

  yearlyWrapper: {
    padding: "40px",
    border: "1px solid #233147",
    background: "#171D29",
    borderRadius: "16px",
    width: "100%",
  },
  pageTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#5D6269",
    lineHeight: "24px",
  },
  billingWrapper: {
    padding: "24px",
    width: "100%",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  billingMethod: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#5D6269",
  },
  addSubscriptionContainer: {
    padding: "8px 14px",
    border: "1px solid #537FC1",
    borderRadius: "8px",
    color: "#537FC1",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
    "&:hover": {
      color: "#446BA6",
    },
  },
  cardInfoForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  message: {
    color: "#858C9B",
    fontSize: "16px",
    fontWeight: "400",
  },
  personalDetailsWrapper: {
    padding: "24px",
    position: "relative",
    "&:first-child": {
      "&::before": {
        content: "''",
        width: "1px",
        position: "absolute",
        top: "24px",
        height: "172px",
        background: "#E6E6EB",
        right: 0,
      },
    },
  },
  detailName: {
    color: "#858C9B",
    fontSize: "16px",
    fontWeight: "400",
    maxWidth: "242px",
    width: "100%",
  },
  detail: {
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "400",
  },
  planDetails: {
    color: "#5D6269",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    marginBottom: "4px",
  },
  changePlanBtn: {
    padding: "8px 14px",
    border: "1px solid #537FC1",
    borderRadius: "8px",
    color: "#537FC1",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
    "&:hover": {
      color: "#446BA6",
    },
  },
  cancelBtn: {
    padding: "8px 14px",
    borderRadius: "8px",
    color: "#A3A9B5",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
    "&:hover": {
      color: "#858C9B",
      background: "#F9FAFB",
    },
  },
  cardType: {
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  expireDate: {
    color: "#858C9B",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  modalMessage: {
    color: "#5D6269",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
  },
  errorMessage: {
    color: "#df1b41",
    fontSize: "16px",
    fontWeight: "400",
  },
  canceledPlanContainer: {},
  canceledMessage: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#5D6269",
    textAlign: "center",
  },
  canceledDate: {
    fontWeight: "600",
  },
  billingMethodContainer: {},
  subscriptionsContainer: {
    display: "felx",
  },
  wrapper: {
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
    height: "220px",
  },
  billingContainer: {},
  buttonsContainer: {},
  subscriptionInfo: {},

  [Breakpoints.LargeScreen]: {
    subscriptionsContainer: {
      flexWrap: "wrap",
    },
  },

  [Breakpoints.Mobile]: {
    billingMethodContainer: {
      flexDirection: "column",
      gap: "12px",
    },
    addSubscriptionContainer: {
      width: "calc(100% - 28px)",
      justifyContent: "center",
    },
    personalDetailsWrapper: {
      width: "100%",
      border: "none",
      padding: "0 0 24px",
      borderBottom: "1px solid #E6E6EB",
      height: "auto",
      "&:first-child": {
        "&::before": {
          display: "none",
        },
      },
    },
    subscriptionInfo: {
      flexDirection: "column",
      gap: "12px",
    },
    canceledPlanContainer: {
      "&>img": {
        width: "305px",
      },
    },
    wrapper: {
      flexDirection: "column",
      height: "auto",
      border: "none",
      gap: "32px",
    },
    subscriptionsContainer: {
      flexDirection: "column",
    },
    yearlyWrapper: {
      padding: "20px",
      width: "100%",
    },
    monthlyWrapper: {
      padding: "20px",
      width: "100%",
    },
    billingContainer: {
      flexDirection: "column",
    },
    cardInfoForm: {
      gap: "32px",
    },
    buttonsContainer: {
      flexDirection: "column-reverse",
      "&>button": {
        width: "100%",
      },
    },
  },
}));
