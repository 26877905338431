import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, ListItemIcon } from "@mui/material";
import { customSelectUseStyles } from "shared/reusableComponents/Select/Select.styles";

const CustomMultiSelect = ({
  options,
  label,
  activeLabel,
  style,
  selectedOptions,
  setSelectedOptions,
}: {
  options: any;
  label: string;
  activeLabel: string;
  style?: any;
  selectedOptions: any;
  setSelectedOptions: (a: any) => void;
}) => {
  const [isInputActiv, setIsInputActive] = useState<boolean>(false);
  const isAllSelected =
    options?.length > 0 && selectedOptions?.length === options?.length;
  const handleSelectChange = (e: any) => {
    const value = e.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedOptions(
        selectedOptions.length === options.length ? [] : options
      );
      return;
    }
    setSelectedOptions(value);
  };

  const classes = customSelectUseStyles();

  return (
    <FormControl style={style}>
      <InputLabel
        className={classes.label}
        style={{
          top: selectedOptions.length ? "0" : "-8px",
          fontSize: "16px",
          fontWeight: "400",
          color: isInputActiv ? "#537FC1" : "#C2C4CB",
          fontFamily: "Montserrat",
        }}
      >
        {selectedOptions.length || isInputActiv ? activeLabel : label}
      </InputLabel>
      <Select
        label={selectedOptions.length || isInputActiv ? activeLabel : label}
        multiple
        value={selectedOptions}
        onChange={handleSelectChange}
        sx={{
          height: "40px",
          "&.MuiInputBase-root": {
            borderRadius: "8px",
            "&.Mui-focused": {
              "&>fieldset": {
                borderColor: "#537FC1",
                borderWidth: "1px",
              },
            },
          },
          "& legend>span": {
            padding: "0 4px",
          },
        }}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selected.map(() => null)}
          </div>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 184,
              width: 250,
              border: "1px solid #C2C4CB",
              borderRadius: "8px",
            },
          },
        }}
        onFocus={() => setIsInputActive(true)}
        onBlur={() => setIsInputActive(false)}
      >
        {options?.length && (
          <MenuItem
            value="all"
            onKeyDown={(e) => e.stopPropagation()}
            style={{ padding: "0 12px", height: "40px", background: "#FDFDFD" }}
          >
            <ListItemIcon>
              <Checkbox
                sx={{
                  "&.MuiCheckbox-root": {
                    color: "#E4E6EF",
                    padding: "0 12px 0 0",
                    "&.Mui-checked": {
                      color: "#537FC1",
                    },
                  },
                }}
                checked={isAllSelected}
              />
            </ListItemIcon>
            <ListItemText primary="All" style={{ color: "#5D6269" }} />
          </MenuItem>
        )}
        {options?.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            style={{ padding: "0 12px", height: "40px", background: "#FDFDFD" }}
          >
            <Checkbox
              checked={selectedOptions.includes(option)}
              sx={{
                "&.MuiCheckbox-root": {
                  color: "#E4E6EF",
                  padding: "0 12px 0 0",
                  "&.Mui-checked": {
                    color: "#537FC1",
                  },
                },
              }}
            />
            <ListItemText primary={option} style={{ color: "#5D6269" }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelect;
