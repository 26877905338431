import { NewsPodsResult } from "types/newsPods.types";
import { Status } from "types/newsPodsStatus.enum";

interface CategorizeResultData {
  activeData: NewsPodsResult[];
  inactiveData: NewsPodsResult[];
  totalArticlesCount: number;
}

export const useCategorizeResults = (
  results: NewsPodsResult[]
): CategorizeResultData => {
  const categorizedResult = results?.reduce(
    (categorizedResult: CategorizeResultData, item: NewsPodsResult) => {
      if (item.status === Status.ACTIVE) {
        categorizedResult.activeData.push(item);
      } else {
        categorizedResult.inactiveData.push(item);
      }
      categorizedResult.totalArticlesCount += item.unread_articles_count ?? 0;
      return categorizedResult;
    },
    { activeData: [], inactiveData: [], totalArticlesCount: 0 }
  ) ?? { activeData: [], inactiveData: [], totalArticlesCount: 0 };

  return categorizedResult;
};

export default useCategorizeResults;
