const PasswordChangeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6666 9.99935C16.6666 9.08018 15.9191 8.33268 14.9999 8.33268H14.1666V5.83268C14.1666 3.53518 12.2974 1.66602 9.99992 1.66602C7.70242 1.66602 5.83325 3.53518 5.83325 5.83268V8.33268H4.99992C4.08075 8.33268 3.33325 9.08018 3.33325 9.99935V16.666C3.33325 17.5852 4.08075 18.3327 4.99992 18.3327H14.9999C15.9191 18.3327 16.6666 17.5852 16.6666 16.666V9.99935ZM7.49992 5.83268C7.49992 4.45435 8.62158 3.33268 9.99992 3.33268C11.3783 3.33268 12.4999 4.45435 12.4999 5.83268V8.33268H7.49992V5.83268Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PasswordChangeIcon;
