import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { QueryProvider } from "context/reactQuery.context";
import { BrowserRouter as Router } from "react-router-dom"; // Assuming you're using React Router
import { OnBoardingContext } from "context/onBoarding.context";
import { ThemeProvider } from "react-jss";
import { AppThemeConstants } from "constants/styles.contants";
import { DashboardContext } from "context/Dashboard.context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={AppThemeConstants}>
    <QueryProvider>
      <Router>
        <DashboardContext>
          <OnBoardingContext>
            <App />
          </OnBoardingContext>
        </DashboardContext>
      </Router>
    </QueryProvider>
  </ThemeProvider>
);
