import { useRoutes } from "react-router-dom";
import useRoutesData from "hooks/useRoutesData";
import "App.css";

function App() {
  const routesData = useRoutesData();
  return <div className="App">{useRoutes(routesData)}</div>;
}

export default App;
