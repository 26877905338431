const SuccessTosterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3ZM17.04 9.98L11.59 15.43C11.4 15.62 11.15 15.72 10.88 15.72C10.61 15.72 10.36 15.61 10.17 15.43L6.96 12.22C6.57 11.83 6.57 11.2 6.96 10.81C7.35 10.42 7.98 10.42 8.37 10.81L10.87 13.31L15.62 8.56C16.01 8.17 16.64 8.17 17.03 8.56C17.42 8.95 17.42 9.58 17.03 9.97L17.04 9.98Z"
        fill="#039855"
      />
    </svg>
  );
};

export default SuccessTosterIcon;
