import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { createUseStyles } from "react-jss";

export const inputUseStyles = createUseStyles(() => ({
  eyeIcon: {
    position: "absolute",
    right: "12px",
    top: "10px",
    cursor: "pointer",
  },
}));

export const CssTextField = withStyles({
  root: {
    "&.MuiFormControl-root": {
      width: "100%",
      "&>label": {
        fontFamily: "Montserrat",
      },
    },
    "& .MuiInputBase-root": {
      background: "#fff",
      borderRadius: "8px",
      "& legend>span": {
        padding: "0 4px",
      },
    },
    "& .MuiInputBase-input": {
      fontWeight: "400",
      fontSize: "16px",
      padding: "10px 8px 10px 12px",
      height: "auto",
      lineHeight: "1.25",
      fontFamily: "Montserrat",
      "&::placeholder": {
        color: "#CCCED6",
        fontSize: "16px",
        fontWeight: "400",
      },
      "&:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
      },
    },

    "& label.Mui-focused": {
      color: "#858C9B",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#0F314D",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DBDBDB",
        borderWidth: "1px",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid #537FC1",
      },
    },
  },
})(TextField);
