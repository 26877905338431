import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "assets/icons/closeIcon.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Status } from "types/newsPodsStatus.enum";
import { NewsPodsResult } from "types/newsPods.types";
import { getNewsPods } from "modules/Services/newsPods.services";
import { logoutUser } from "modules/Services/auth.services";
import { ROUTES } from "constants/routes.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import getFirstLetters from "constants/getFirstLetters.constants";
import useCategorizeResults from "constants/categorizeResults";

import { ReactComponent as HacktechSvg } from "assets/icons/sideBarHacktech.svg";
import { ReactComponent as HottakeAiClose } from "assets/icons/hottakeAiClose.svg";
import { ReactComponent as AddNewsPodIcon } from "assets/icons/addNewsPodIcon.svg";
import { ReactComponent as ActivateTooltipIcon } from "assets/icons/activateTooltipIcon.svg";
import { ReactComponent as InboxIcon } from "assets/icons/topicsSvg.svg";
import { ReactComponent as NewsFeed } from "assets/icons/newsFeed.svg";
import LogoutIcon from "assets/icons/components/Logout.Icon";
import profileIcon from "assets/icons/profileIcon.svg";

import { Drawer, DrawerHeader } from "./styles";
import { useDashboardContext } from "context/Dashboard.context";

export default function SideBar({ userName }: { userName: string }) {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { setIsOpenCreateNewspodModal }: any = useDashboardContext();

  const refreshToken = localStorage.getItem("refresh_token");

  const { data: { data = {} } = {} } = useQuery("getNewspods", () =>
    getNewsPods({})
  );

  const { mutate } = useMutation(() => logoutUser({ refresh: refreshToken }), {
    onSuccess: () => {
      localStorage.removeItem("lastTrialOverModalShown");
      localStorage.removeItem("lastCanceledModalShown");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      navigate(ROUTES.SIGNIN);
    },
  });

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleLogout = () => {
    mutate();
  };

  const { results } = data;

  const { firstLetterName, firstLetterLastName } = getFirstLetters(userName);
  const {
    activeData = [],
    inactiveData = [],
    totalArticlesCount = 0,
  } = useCategorizeResults(results);

  const pathname = location?.pathname.split("/").join("");

  const showTotalArticelCount = open && totalArticlesCount > 0;
  return (
    <Box sx={{ background: "#fff" }}>
      <Drawer
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            height: "100%",
            overflow: "hidden",
            background: "#fff",
          },
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader
          style={{
            display: "flex",
            position: "relative",
            padding: "20px 18px",
            justifyContent: open ? "flex-start" : "center",
            borderBottom: "1px solid rgba(253, 253, 253, 0.10)",
            transition: "justify-content 0.3s",
          }}
        >
          {open ? <HacktechSvg /> : <HottakeAiClose />}
          <IconButton
            style={{
              display: "flex",
              padding: 0,
            }}
            onClick={handleDrawer}
          >
            <img
              style={{
                padding: 0,
                transform: !open ? "rotate(180deg)" : "none",
                position: "fixed",
                left: open ? "226px" : "105px",
                transition: "transform 0.3s, left 0.3s",
              }}
              src={ChevronRightIcon}
              alt="ChevronRightIcon"
            />
          </IconButton>
        </DrawerHeader>

        <List
          disablePadding
          sx={{
            display: "flex",
            gap: "10px",
            color: "#858C9B",
            borderBottom: "1px solid rgba(253, 253, 253, 0.10)",
          }}
        >
          <ListItemButton
            onClick={() => navigate(ROUTES.PROFILE)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              padding: "0 16px",
              gap: "10px",
              marginBottom: "8px",
              marginTop: "8px",

              border: "1px solid rgba(230, 230, 235, 0.00)",
              background:
                pathname === "profile"
                  ? "linear-gradient(  to right, rgba(230, 230, 235, 0), rgba(181, 181, 185, 0.2),rgba(130, 130, 133, 0) )"
                  : "none",

              boxShadow: "0px 64px 64px -32px rgba(41, 15, 0, 0.20)",
              color: "#fff",
              fontWeight: 500,
            }}
          >
            {userName ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "32px",
                  height: "32px",
                  background: "#F2F6FD",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#A3A9B5",
                  borderRadius: "50px",
                  padding: "10px",
                }}
              >
                {firstLetterName}
                {firstLetterLastName}
              </Box>
            ) : (
              <img src={profileIcon} alt="profileIcon" />
            )}
            <Flex
              gap="5px"
              alignItems="center"
              style={{ position: "relative", fontSize: "14px" }}
            >
              {open && <span>{userName}</span>}
            </Flex>
          </ListItemButton>
        </List>

        <List
          disablePadding
          sx={{
            display: "flex",
            gap: "10px",
            color: pathname === "newsfeed" ? "#fff" : "#858C9B",
          }}
        >
          <ListItemButton
            onClick={() => navigate(ROUTES.DASHBOARD)}
            sx={{
              maxHeight: "44px",
              justifyContent: open ? "initial" : "center",
              marginTop: "12px",
              borderRadius: "12px",
              padding: "12px 16px",
              gap: "4px",
              px: open ? "12px 16px" : 3.5,
              border:
                pathname === "newsfeed"
                  ? "1px solid rgba(230, 230, 235, 0.10)"
                  : "",
              background:
                pathname === "newsfeed"
                  ? "linear-gradient(  to right, rgba(230, 230, 235, 0), rgba(181, 181, 185, 0.2),rgba(130, 130, 133, 0) )"
                  : "none",

              boxShadow: "0px 64px 64px -32px rgba(41, 15, 0, 0.20)",
              fontSize: "14px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? "8px" : "auto",
                justifyContent: "center",
                svg: {
                  path: {
                    fill: pathname === "newsfeed" ? "#fff" : "#858C9B",
                  },
                },
              }}
            >
              <NewsFeed />
            </ListItemIcon>
            <ListItemText
              primary="Newsfeed"
              primaryTypographyProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              sx={{ display: open ? "block" : "none" }}
            />
            {showTotalArticelCount && (
              <Box
                sx={{
                  display: "flex",
                  width: "28px",
                  height: "20px",
                  padding: " 0px 12px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#537FC1",
                  borderRadius: "50px",
                  fontSize: "10px",
                  color: "#fff",
                }}
              >
                {totalArticlesCount}
              </Box>
            )}
          </ListItemButton>
        </List>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {!!activeData?.length && (
              <List
                sx={{
                  padding: "32px 0 0 ",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: open ? "start" : "center",
                }}
              >
                <Typography
                  sx={{
                    padding: !open ? "0" : "0 0 0  16px",
                    fontSize: "12px",
                    color: "#A3A9B5",
                    fontWeight: 500,
                    marginBottom: "10px",
                  }}
                >
                  ACTIVE
                </Typography>
                {activeData?.map(
                  (
                    { name, unread_articles_count, uuid }: NewsPodsResult,
                    index: number
                  ) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "flex",
                        color: params.uuid === uuid ? "#fff" : "#858C9B",
                        fontSize: "14px",
                      }}
                      onClick={() => navigate(`/newspod/${uuid}`)}
                    >
                      <ListItemButton
                        sx={{
                          maxHeight: "44px",
                          justifyContent: open ? "initial" : "center",
                          padding: "12px 16px",
                          gap: "8px",
                          width: "max-content",
                          borderRadius: "12px",
                          border:
                            params.uuid === uuid
                              ? "1px solid rgba(230, 230, 235, 0.10)"
                              : "",
                          background:
                            params.uuid === uuid
                              ? "linear-gradient(  to right, rgba(230, 230, 235, 0), rgba(181, 181, 185, 0.2),rgba(130, 130, 133, 0) )"
                              : "none",
                          svg: {
                            path: {
                              fill: params.uuid === uuid ? "#fff" : "#858C9B",
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                          }}
                        >
                          {<InboxIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                              fontSize: "14px",
                            },
                          }}
                          primary={name}
                          sx={{
                            display: open ? "flex" : "none",
                            maxWidth: "100px",
                          }}
                        />
                        {unread_articles_count > 0 && open && (
                          <Box
                            sx={{
                              display: "flex",
                              width: "28px",
                              height: "20px",
                              padding: " 0px 12px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#537FC1",
                              borderRadius: "50px",
                              fontSize: "10px",
                              color: "#fff",
                            }}
                          >
                            {unread_articles_count}
                          </Box>
                        )}
                      </ListItemButton>
                    </ListItem>
                  )
                )}
              </List>
            )}

            {!!inactiveData?.length && (
              <List
                sx={{
                  padding: "32px 0 0 ",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: open ? "start" : "center",
                }}
              >
                <Typography
                  sx={{
                    padding: !open ? "0" : "0 0 0  16px",
                    fontSize: "12px",
                    color: "#A3A9B5",
                    fontWeight: 500,
                    marginBottom: "10px",
                  }}
                >
                  INACTIVE
                </Typography>
                {inactiveData?.map(
                  ({ name, status, uuid }: NewsPodsResult, index: number) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "flex",
                        fontSize: "14px",
                        color: params.uuid === uuid ? "#fff" : "#858C9B",
                      }}
                      onClick={() => navigate(`/newspod/${uuid}`)}
                    >
                      <ListItemButton
                        sx={{
                          maxHeight: "44px",
                          justifyContent: open ? "initial" : "center",
                          padding: "12px 16px",
                          gap: "8px",
                          width: "max-content",
                          borderRadius: "12px",
                          border:
                            params.uuid === uuid
                              ? "1px solid rgba(230, 230, 235, 0.10)"
                              : "",
                          background:
                            params.uuid === uuid
                              ? "linear-gradient(  to right, rgba(230, 230, 235, 0), rgba(181, 181, 185, 0.2),rgba(130, 130, 133, 0) )"
                              : "none",
                          svg: {
                            path: {
                              fill: params.uuid === uuid ? "#fff" : "#858C9B",
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                          }}
                        >
                          {<InboxIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                              textAlign: "center",
                              fontSize: "14px",
                            },
                          }}
                          primary={name}
                          sx={{
                            display: open ? "flex" : "none",
                            maxWidth: "100px",
                          }}
                        />
                        {open && status === Status.INACTIVE && (
                          <Tooltip
                            placement="top"
                            title="No active subscription"
                          >
                            <ActivateTooltipIcon />
                          </Tooltip>
                        )}
                      </ListItemButton>
                    </ListItem>
                  )
                )}
              </List>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "40px",
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                padding: open ? "20px 56px" : 0,
                border: open ? "1px solid rgba(253, 253, 253, 0.10)" : "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {open && (
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  Quick creation
                </Typography>
              )}
              <Flex
                alignItems="center"
                justifyContent="center"
                style={{
                  width: "40px",
                  height: "40px",
                  background: "#537FC1",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsOpenCreateNewspodModal({ open: true, type: "" })
                }
              >
                <AddNewsPodIcon />
              </Flex>
            </Box>

            <Box
              onClick={handleLogout}
              sx={{
                width: "100%",
                padding: open ? "12px 16px" : 0,
                color: "#fff",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <LogoutIcon />
              Log out
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
