import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP,
      boxShadow: "4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
      marginTop: "0px",
      marginLeft: 0,
      borderRadius: "8px",
      padding: "8px 0px",
      border: `1px solid #E6E6EB`,
    },
  },
};

export const customSelectUseStyles = makeStyles(() => ({
  select: {
    fontFamily: "Montserrat",
    //width: "174px",
    zIndex: "999",
    "& .MuiSelect-select": {
      padding: "8px 16px !important",
      borderRadius: "8px",
      color: "#5D6269",
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#537FC1",
      borderWidth: "1px",
    },
  },

  label: {
    color: "#C2C4CB",
    "&.Mui-focused": {
      top: "0 !important",
    },
  },

  menuItem: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
  },
}));
