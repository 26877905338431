import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const onBoardingUseStyles = createUseStyles((theme) => ({
  contentWrapper: {},
  onBoardingContainer: {
    textAlign: "start",
    marginBottom: "24px",
  },
  title: {
    fontSize: "32px",
    fontWeight: "500",
    color: "#5D6269",
    margin: "0",
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#858C9B",
  },
  newsPodNameContainer: {
    position: "relative",
  },
  newsPodNameInput: {
    width: "240px",
    border: "none",
    borderRadius: "8px",
    "&>.MuiInputBase-root": {
      "&>input": {
        padding: "10px 12px 10px 48px",
        fontSize: "20px",
        fontWeight: "500",
        color: "#858C9B",
      },
      "&>fieldset": {
        border: "none",
        "&>legend": {
          display: "none",
        },
      },
    },
  },
  newsPodNameImg: {
    position: "absolute",
    top: "8px",
    left: "12px",
    "&>img": {
      display: "block",
    },
  },
  errorMessage: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#E74768",
    padding: "0 0 0 12px",
  },
  [Breakpoints.Mobile]: {
    newsPodNameInput: {
      width: "100%",
      "&>.MuiInputBase-root": {
        "&>fieldset": {
          border: "1px solid #C2C4CB",
        },
      },
    },
    contentWrapper: {
      flexDirection: "column",
      gap: "24px",
    },
  },
}));
