import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Chip, Pagination, Typography } from "@mui/material";
import {
  articlesCountUpdate,
  deleteNewspod,
  getNewsPods,
  getNewsfeeds,
} from "modules/Services/newsPods.services";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import LoadingState from "shared/reusableComponents/LoadingState/LoadingState";
import { newsfeedsUseStyles } from "modules/Dashboard/Newsfeeds/pages/Newsfeeds.styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewsfeedsItem from "modules/Dashboard/reusableComponents/NewsfeedsItem/NewsFeedsItem";
import { Article } from "types/newsPods.types";
import useDebounce from "hooks/useDebounce";
import Filters from "modules/Dashboard/reusableComponents/Filters/Filters";
import NewsPodAcardion from "../components/NewsPodAcardion/NewsPodAcardion";
import BasicModal from "shared/reusableComponents/BasicModal/BasicModal";
import DeleteModalContent from "../components/Modals/DeleteModalContent";
import { ROUTES } from "constants/routes.constants";
import { Status } from "types/newsPodsStatus.enum";
import EmptyState from "modules/Dashboard/reusableComponents/EmptyState/EmptyState";

const NewsPods = () => {
  const styles = newsfeedsUseStyles();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { uuid } = useParams();
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const [searchValue, setSearchValue] = useState<string>("");
  const debauncedSearchValue = useDebounce(searchValue, 1000);

  const queryClient = useQueryClient();

  const {
    data: newsfeedsResponse,
    isFetching,
    refetch,
  } = useQuery(
    ["getNewsfeeds", page, uuid, debauncedSearchValue],
    () =>
      getNewsfeeds(
        page,
        selectedTopic,
        selectedSource,
        dateRange,
        debauncedSearchValue,
        uuid
      ),
    {
      keepPreviousData: true,
    }
  );
  const { data: { data: newsPodData = {} } = {} } = useQuery(
    ["getNewspods", uuid],
    () => getNewsPods({ uuid })
  );

  const { mutate: deleteNewsPodMutate } = useMutation(
    () => deleteNewspod({ uuid }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewspods");
        navigate(ROUTES.DASHBOARD);
      },
    }
  );

  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const { articles = [], count = 0 } = newsfeedsResponse?.data ?? {};

  const { mutate: updateArticlesCount, isLoading: isAticlesLoading } =
    useMutation(articlesCountUpdate, {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewspods");
      },
    });

  useEffect(() => {
    if (!isFetching && !isAticlesLoading) updateArticlesCount({ uuid });
    // eslint-disable-next-line
  }, [isFetching, uuid]);

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (isFetching) {
    return <LoadingState />;
  }

  return (
    <>
      <Flex direction="column" gap="32px">
        <Box display="flex" alignItems="center" gap="12px">
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, color: "#5D6269" }}
          >
            {newsPodData?.name}
          </Typography>

          <Chip
            sx={{
              background:
                Status.ACTIVE === newsPodData?.status ? "#DDF4E7" : "#F8E1E8",
              fontWeight: 500,
              color:
                Status.ACTIVE === newsPodData?.status ? "#039855" : "#FA4B5E",
              fontSize: "14px",
            }}
            label={newsPodData?.status}
            color="primary"
          />
        </Box>

        <Filters
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isFetching}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          refetch={refetch}
          setPage={setPage}
          page={page}
        />
        <Flex
          className={styles.wrapper}
          direction="column"
          gap="20px"
          width="100%"
        >
          <Flex
            className={styles.newsfeedsContainer}
            width="100%"
            direction="column"
            gap="32px"
          >
            <NewsPodAcardion
              data={newsPodData}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />

            {!!articles?.length ? (
              <>
                {<h2 className={styles.title}>Latest news</h2>}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "& > div:last-child": {
                      border: "none",
                    },
                  }}
                  gap="20px"
                  width="100%"
                >
                  {articles?.map((item: Article) => (
                    <>
                      <NewsfeedsItem article={item} key={item.id} newsPod />
                    </>
                  ))}
                </Box>
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                  className={styles.pagination}
                >
                  {!isFetching && (
                    <Pagination
                      count={Math.ceil(count / 10)}
                      page={page}
                      onChange={handleChange}
                    />
                  )}
                </Flex>
              </>
            ) : (
              <EmptyState
                title="Latest news"
                message="No news generated for this hour"
                variant
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <BasicModal
        open={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
      >
        <DeleteModalContent
          handleClose={() => setIsOpenDeleteModal(false)}
          handleDelete={() => deleteNewsPodMutate()}
          deleteDataName={newsPodData?.name}
        />
      </BasicModal>
    </>
  );
};

export default NewsPods;
