import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const profileUseStyles = createUseStyles(() => ({
  profileWrapper: {
    margin: "0 auto",
  },
  profileContainer: {
    width: "100%",
    background: "#FFF",
    border: "1px solid #E6E6EB",
    boxShadow: "4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
    borderRadius: "8px",
    padding: "24px",
  },
  tabContainer: {
    width: "100%",
    borderBottom: "1px solid #E6E6EB",
    '&::-webkit-scrollbar': {
      background:"transparent"
    }
  },

  tab: {
    padding: "10px 16px",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#858C9B",
    cursor: "pointer",
    whiteSpace: "nowrap",
    borderRadius: "8px 8px 0 0",
    "&:hover": {
      background: "#F2F6FD",
    },
  },
  activeTab: {
    color: "#537FC1",
    fontWeight: "500",
    borderBottom: "1px solid #537FC1",
  },
  profileLocation: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
    cursor: "pointer",
  },
  sectionName: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#5D6269",
    margin: "0",
  },
  profileTitle: {},

  [Breakpoints.LargeScreen]: {
    tabContainer: {
      overflow: "auto",
    },
  },
  [Breakpoints.Mobile]: {
    profileContainer: {
      width: "100%",
      margin: "0 auto",
      padding: "20px",
    },
    tabContainer: {
      overflowX: "scroll",
    },
    profileTitle: {
      width: "calc(100% - 30px)",
      margin: "auto",
    },
  },
}));
