import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const tosterUseStyles = createUseStyles(() => ({
  successTosterWrapper: {
    maxWidth: "400px",
    width: "100%",
    padding: "16px",
    border: "1px solid #5DC194",
    borderRadius: "8px",
    background: "#F2FDF7",
    position: "fixed",
    top: "20px",
    right: "20px",
  },
  errorTosterWrapper: {
    maxWidth: "402px",
    width: "100%",
    padding: "16px",
    border: "1px solid #F48196",
    borderRadius: "8px",
    background: "#FEF3F2",
    position: "fixed",
    top: "20px",
    right: "20px",
    zIndex:"999"
  },
  tosterType: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
  },
  message: {
    paddingLeft: "36px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#5D6269",
  },
  [Breakpoints.Mobile]: {
    successTosterWrapper: {
      width: "calc(100% - 62px)",
      top: "15px",
      right: "50%",
      transform: "translateX(50%)",
    },
    errorTosterWrapper: {
      width: "calc(100% - 62px)",
      top: "15px",
      right: "50%",
      transform: "translateX(50%)",
    },
  },
}));
