export const ArrowUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_6399_20086)">
      <path d="M17 14L12 9L7 14L17 14Z" fill="#858C9B" />
    </g>
    <defs>
      <clipPath id="clip0_6399_20086">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(-1 0 0 -1 24 24)"
        />
      </clipPath>
    </defs>
  </svg>
);
