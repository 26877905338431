import { createUseStyles } from "react-jss";
const backgroundImage = require("assets/icons/signInBackground.png");

export const signInUseStyles = createUseStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    display: "flex",
    justifyContent: "flex-end",
  },
  signInContainer: {
    width: "55%",
    background: "#FFF",
    borderRadius: "80px 0px 0px 80px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#5D6269",
    margin: "0 0 16px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "100%",
  },
  divider: {
    height: "1px",
    background: "#CCCED6",
    width: "400px",
    marginTop: "4px",
  },
  signInGoogle: {
    padding: "10px 16px",
    border: "1px solid #CCCED6",
    borderRadius: "8px",
    width: "calc(100% - 34px)",
    margin: "4px 0",
    "&>span": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#5D6269",
    },
  },
  eyeIcon: {
    position: "absolute",
    right: "12px",
    top: "10px",
    cursor: "pointer",
  },
  submitBtn: {
    width: "400px",
    background: "#537FC1",
    borderRadius: "8px",
    marginTop: "40px",
    boxShadow: "none",
    padding: "10px 16px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#FFF",
    cursor: "pointer",
    border: "none",
    fontStyle: "normal",
    "&:hover": {
      background: "#446BA6",
    },
    "&:disabled": {
      background: "#EAECF0",
      color: "#D0D5DD",
    },
  },
}));
