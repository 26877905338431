import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const passwordRequirmentsUseStyles = createUseStyles(() => ({
  requirements: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#A3A9B5",
  },
  wrapper: {},
  [Breakpoints.Mobile]: {
    wrapper: {
      flexDirection: "column",
      gap: "12px",
    },
  },
}));
