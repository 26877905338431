import { createUseStyles } from "react-jss";

export const newsfeedsUseStyles = createUseStyles(() => ({
  wrapper: {},
  sectionName: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#5D6269",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#5D6269",
  },
  newsfeedsContainer: {
    padding: "24px",
    background: "#FFF",
    borderRadius: "8px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "#537FC1",
      color: "#FFFFFF",
    },
    "& .MuiPaginationItem-page": {
      "&:hover": {
        backgroundColor: "#446BA6",
        color: "#FFFFFF",
      },
    },
    "& .MuiPaginationItem-previousNext": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #E6E6EB",
      "&:hover": {
        backgroundColor: "#F8F8F8",
        border: "1px solid #A3A9B5",
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EAECF0",
    },
  },
}));
