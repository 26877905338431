import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";

import {
  activateNewspod,
  pauseNewspod,
} from "modules/Services/newsPods.services";
import { useDashboardContext } from "context/Dashboard.context";
import { Status } from "types/newsPodsStatus.enum";
import CustomizedSwitch from "shared/reusableComponents/Switch/Switch";
import { DetailsRow } from "./DetalisRow/DetalisRow";

import { ReactComponent as ActivateTooltipIcon } from "assets/icons/activateTooltipIcon.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as BasketIcon } from "assets/icons/basket.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as InboxIcon } from "assets/icons/topicsSvg.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegramIcon.svg";
import { ReactComponent as MessageIcon } from "assets/icons/messageIcon.svg";

const NewsPodAcardion = ({
  handleOpenDeleteModal,
  data,
}: {
  handleOpenDeleteModal: () => void;
  data: any;
}) => {
  const { uuid } = useParams();
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [showAll, setShowAll] = useState({
    categories: false,
    keywords: false,
    sources: false,
  });
  const queryClient = useQueryClient();

  const {
    categories,
    keywords,
    sources,
    name,
    telegram,
    email,
    can_be_activated,
  } = data;

  const { mutate: activateMutate, isLoading: isActivateLoading } = useMutation(
    activateNewspod,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewspods");
      },
    }
  );

  const { mutate: pauseMutate, isLoading: isPauseLoading } = useMutation(
    pauseNewspod,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewspods");
      },
    }
  );

  const disabledSwich = data?.status === Status.PAUSED && !can_be_activated;

  const handleActivate = () => {
    if (!isActivateLoading && !isPauseLoading) {
      if (data.status === Status.ACTIVE) {
        pauseMutate({ uuid });
      } else if (
        data.status === Status.PAUSED ||
        data.status === Status.INACTIVE
      ) {
        activateMutate({ uuid });
      }
    }
  };

  const { setIsOpenCreateNewspodModal }: any = useDashboardContext();

  const handleOpenSummary = () => {
    setOpenSummary((prev) => !prev);
  };

  const handleShowAll = (key: string) => {
    setShowAll((prev: any) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <Box width="100%">
      <Accordion
        sx={{
          background: "#F8FAFB",
          boxShadow: "none",
          borderRadius: "8px !important",
          padding: {
            md: "20px 16px 20px 20px",
            sm: "20px 16px 20px 20px",
            xs: "10px",
          },
        }}
      >
        <AccordionSummary
          onClick={handleOpenSummary}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: 0,
            margin: 0,
            "&>div": {
              margin: 0,
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ gap: { md: "11px", sm: "11px", xs: "5px" } }}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(74, 182, 195, 1)",
                  },
                  minWidth: "auto",
                  background: "rgba(74, 182, 195, 1)",
                  borderRadius: "8px",
                  width: { md: "48px", sm: "48px", xs: "32px" },
                  height: { md: "48px", sm: "48px", xs: "32px" },
                  svg: {
                    path: {
                      fill: "#fff",
                    },
                  },
                }}
              >
                <InboxIcon />
              </IconButton>
              <Typography
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  color: "#5D6269",
                  fontSize: { md: "18px", sm: "18px", xs: "14px" },
                  fontWeight: 500,
                }}
              >
                {name}
              </Typography>
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  transform: openSummary ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <ArrowIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  borderRight: "1px solid #E6E6EB",
                  borderRadius: "0",
                  padding: "0 8px 0 0",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenCreateNewspodModal({ open: true, type: "edit" });
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDeleteModal();
                }}
              >
                <BasketIcon />
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box display="flex" flexDirection="column" gap="10px">
            <CardContent sx={{ padding: 0, flexWrap: "wrap" }}>
              <Box display="flex" gap="16px" flexDirection="column">
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    gap: { md: "46px", sm: "46px", xs: "10px" },
                    flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "#858C9B" }}>
                    Channels:
                  </Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    sx={{
                      flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
                    }}
                  >
                    {!!email?.send && (
                      <Box
                        color="#5D6269"
                        display="flex"
                        alignItems="center"
                        gap="5px"
                        borderRight={
                          !telegram?.send ? "none" : "1px solid #E6E6EB"
                        }
                        paddingRight="12px"
                      >
                        <MessageIcon />
                        Email
                      </Box>
                    )}
                    {!!telegram?.send && (
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="5px"
                        color="#5D6269"
                      >
                        <TelegramIcon />
                        Telegram
                        {!telegram?.connected && (
                          <Box
                            sx={{
                              padding: "5px 10px",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#FA4B5E",
                              border: "1px solid #E74768",
                              background: "#FFEFF4",
                              borderRadius: "8px",
                            }}
                          >
                            Not connected
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>

                {!!categories?.length && (
                  <DetailsRow
                    title="Topics:"
                    chips={categories}
                    chipProps={{ background: "#EDEBFF", color: "#7566D1" }}
                    showAll={showAll.categories}
                    onShowAll={() => handleShowAll("categories")}
                    wrapProps={{
                      gap: { md: "58px", sm: "58px", xs: "10px" },
                    }}
                  />
                )}
                {!!keywords?.length && (
                  <DetailsRow
                    title="Keywords:"
                    chips={keywords}
                    chipProps={{ background: "#DDF4E7", color: "#039855" }}
                    showAll={showAll.keywords}
                    onShowAll={() => handleShowAll("keywords")}
                    wrapProps={{
                      gap: { md: "36px", sm: "36px", xs: "10px" },
                    }}
                  />
                )}
                {!!sources?.length && (
                  <DetailsRow
                    title="Sources:"
                    chips={sources}
                    chipProps={{ background: "#FFE8F9", color: "#CF159B" }}
                    showAll={showAll.sources}
                    onShowAll={() => handleShowAll("sources")}
                    wrapProps={{
                      gap: { md: "46px", sm: "46px", xs: "10px" },
                    }}
                  />
                )}
              </Box>

              <Box
                display="flex"
                alignItems="center"
                sx={{
                  mt: 2.5,
                  borderTop: "1px solid #E6E6EB",
                  paddingTop: "20px",
                }}
              >
                <CustomizedSwitch
                  checked={data.status === Status.ACTIVE}
                  onChange={handleActivate}
                  disabled={
                    isActivateLoading || isPauseLoading || disabledSwich
                  }
                />
                <Typography sx={{ color: "#5D6269" }}>
                  Enable newspod
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="8px">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={20}
                >
                  <ActivateTooltipIcon />
                </Box>
                <Typography sx={{ color: "#A3A9B5", fontSize: "14px" }}>
                  Newspod has to be active to find and summarize articles based
                  on the settings.
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default NewsPodAcardion;
