import { createUseStyles } from "react-jss";

export const customLinkUseStyles = createUseStyles(() => ({
  link: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#537FC1",
    textDecoration:"none"
  },
}));
