import { Flex } from "shared/reusableComponents/Flex/Flex";
import deleteModalIcon from "assets/icons/deleteModalIcon.svg";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { modalUseStyles } from "modules/Dashboard/NewsPods/components/Modals/Modal.styles";
import { Typography } from "@mui/material";

const DeleteModalContent = ({
  handleClose,
  handleDelete,
  deleteDataName,
  deleteUser = false,
  description,
}: {
  handleClose: any;
  handleDelete: any;
  deleteDataName?: string;
  deleteUser?: boolean;
  description?: string;
}) => {
  const styles = modalUseStyles();

  return (
    <Flex direction="column" gap="32px">
      <Flex gap="20px" className={styles.container} width="100%">
        <img src={deleteModalIcon} alt="" />
        <span className={deleteUser ?  styles.userDesciption : styles.description}>
          Are you sure you want to delete{" "}
          <span className={styles.newsPodName}>
            {deleteDataName && `“${deleteDataName}”`}
          </span>{" "}
          {deleteUser ? "your account?" : "NewsPod"}
        </span>
      </Flex>
      {description && (
        <Typography sx={{ color: "#858C9B", fontSize: "16px", pl: "42px" }}>
          {description}
        </Typography>
      )}
      <Flex
        className={styles.buttonsContainer}
        gap="20px"
        justifyContent="flex-end"
        width="100%"
      >
        <Button
          className={styles.modalButtons}
          variant={ButtonVariants.Secondary}
          handleClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          className={styles.modalButtons}
          style={{ background: "#E74768" }}
          handleClick={handleDelete}
        >
          Yes, Delete
        </Button>
      </Flex>
    </Flex>
  );
};

export default DeleteModalContent;
