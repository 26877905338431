import messageIcon from "assets/icons/messageIcon.svg";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import TelegramLinkIcon from "assets/icons/components/TelegramLink.Icon";
import telegramIcon from "assets/icons/telegramIcon.svg";
import { newspodDetailsUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/styles";

const Channels = ({
  userInfo,
  ChannelsInfo,
  setChannelsInfo,
}: {
  userInfo: any;
  ChannelsInfo: any;
  setChannelsInfo: any;
}) => {
  const styles = newspodDetailsUseStyles();

  const generateContentForMobile = () => {
    return (
      <Flex
        direction="column"
        gap="8px"
        width="100%"
        justifyContent="space-between"
        className={`${styles.channel} ${styles.emailChannelForMobile}`}
        style={{ borderColor: ChannelsInfo.email.send ? "#537FC1" : "" }}
      >
        <Flex justifyContent="space-between" width="100%">
          <Flex gap="8px">
            <img src={messageIcon} alt="" />
            <span className={styles.channelName}>Email</span>
          </Flex>
          <input
            type="checkbox"
            checked={ChannelsInfo.email.send}
            onChange={() =>
              setChannelsInfo((prev: any) => ({
                ...ChannelsInfo,
                email: {
                  ...ChannelsInfo.email,
                  send: !prev.email.send,
                },
              }))
            }
            style={{ width: "16px", height: "16px" }}
          />
        </Flex>
        <Flex width="100%" justifyContent="space-between">
          <span className={styles.emailForMobile}>{userInfo.email}</span>
          <div className={styles.status}>Connected</div>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex className={styles.wrapper} direction="column" gap="20px" width="100%">
      <span className={styles.sectionTitle}>
        Channels<span style={{ color: "red" }}>*</span>
      </span>
      <Flex
        direction="column"
        gap="16px"
        width="100%"
        style={{ maxWidth: "800px" }}
      >
        {generateContentForMobile()}
        <Flex
          direction="column"
          gap="8px"
          justifyContent="space-between"
          className={`${styles.channel} ${styles.emailChannel}`}
          style={{ borderColor: ChannelsInfo.email.send ? "#537FC1" : "" }}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex gap="8px" alignItems="center" width="calc(100% - 36px)">
              <img src={messageIcon} alt="" />
              <span className={styles.channelName}>Email</span>
              <span className={styles.email}>{userInfo.email}</span>
              {ChannelsInfo.email.connected && (
                <div className={styles.status}>Connected</div>
              )}
            </Flex>
            <input
              type="checkbox"
              checked={ChannelsInfo.email.send}
              onChange={() => {
                setChannelsInfo((prev: any) => ({
                  ...ChannelsInfo,
                  email: {
                    ...ChannelsInfo.email,
                    send: !prev.email.send,
                  },
                }));
              }}
              style={{ width: "16px", height: "16px" }}
            />
          </Flex>

          <span className={styles.message}>
            Get your newsletters on your email.
          </span>
        </Flex>
        <Flex
          direction="column"
          gap="8px"
          className={styles.channel}
          style={{ borderColor: ChannelsInfo.telegram.send ? "#537FC1" : "" }}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex gap="8px" alignItems="center">
              <img src={telegramIcon} alt="" />
              <span className={styles.channelName}>Telegram</span>
              {ChannelsInfo.telegram.connected && (
                <div className={styles.status}>Connected</div>
              )}
            </Flex>
            <input
              type="checkbox"
              onChange={() =>
                setChannelsInfo((prev: any) => ({
                  ...ChannelsInfo,
                  telegram: {
                    ...ChannelsInfo.telegram,
                    send: !prev.telegram.send,
                  },
                }))
              }
              checked={ChannelsInfo.telegram.send}
              style={{ width: "16px", height: "16px" }}
            />
          </Flex>
          {ChannelsInfo.telegram.send ? (
            <Flex gap="8px" direction="column">
              {!ChannelsInfo.telegram.connected && (
                <span className={styles.messageForConect}>
                  Click the link below to connect your Telegram account.
                </span>
              )}
              <Flex
                gap="8px"
                alignItems="center"
                className={styles.telegramLink}
              >
                <TelegramLinkIcon />
                <a
                  href={userInfo.telegram_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {userInfo.telegram_link}
                </a>
              </Flex>
            </Flex>
          ) : (
            <span className={styles.message}>
              Receive your daily audio newsletter via Telegram.
            </span>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Channels;
