import { useEffect, useRef, useState } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import filterIcon from "assets/icons/filterIcon.svg";
import { filtersUseStyles } from "modules/Dashboard/reusableComponents/Filters/Filters.styles";
import {
  defaultSources,
  defaultTopics,
} from "constants/newsPodDetails.constants";
import SelectWithChangedLabel from "shared/reusableComponents/Select/SelectWithChangedLabel/SelectWithChangedLabel";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import DateRangeSelect from "shared/reusableComponents/DateSelect/DateRangeSelect";

const Filters = ({
  searchValue,
  setSearchValue,
  isLoading,
  dateRange,
  setDateRange,
  selectedTopic,
  setSelectedTopic,
  selectedSource,
  setSelectedSource,
  refetch,
  setPage,
  page,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  isLoading: boolean;
  dateRange: any;
  setDateRange: any;
  selectedTopic: string;
  setSelectedTopic: (topic: string) => void;
  selectedSource: string;
  setSelectedSource: (source: string) => void;
  refetch: () => void;
  setPage: (page: number) => void;
  page: number;
}) => {
  const styles = filtersUseStyles();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const filtersRef = useRef<HTMLDivElement>(null);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filtersRef.current &&
        !filtersRef.current.contains(event.target as Node)
      ) {
        setIsFiltersOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filtersRef]);

  const handleApplyClick = () => {
    setIsFiltersOpen(false);

    if (page !== 1) {
      setPage(1);
    } else {
      refetch();
    }
  };

  const generateFiltersModal = () => {
    return (
      <Flex
        gap="32px"
        direction="column"
        className={styles.filtersContainer}
        refValue={filtersRef}
      >
        <span className={styles.filtersTitle}>Filter options</span>
        <Flex
          direction="column"
          gap="24px"
          width="100%"
          className={styles.filters}
        >
          <DateRangeSelect dateRange={dateRange} setDateRange={setDateRange} />
          <SelectWithChangedLabel
            placeholder="Select topic"
            label="Topic"
            options={defaultTopics}
            selectedOption={selectedTopic}
            setSelectedOption={setSelectedTopic}
          />
          <SelectWithChangedLabel
            placeholder="Select source"
            label="Source"
            options={defaultSources}
            selectedOption={selectedSource}
            setSelectedOption={setSelectedSource}
          />
        </Flex>
        <Flex width="100%" justifyContent="flex-end" gap="16px">
          <Button
            variant={ButtonVariants.Secondary}
            handleClick={() => setIsFiltersOpen(false)}
          >
            Cancel
          </Button>
          <Button
            handleClick={handleApplyClick}
            disabled={
              !selectedTopic && !selectedSource && (!startDate || !endDate)
            }
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    );
  };
  return (
    <Flex gap="20px" className={styles.wrapper}>
      <div className={styles.searchInputWrapper}>
        <input
          className={styles.searchInput}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          disabled={isLoading}
        />
      </div>
      <Flex
        className={styles.filtersWrapper}
        gap="8px"
        onClick={() => !isLoading && setIsFiltersOpen(!isFiltersOpen)}
      >
        <img src={filterIcon} alt="filters" />
        <span className={styles.filter}>Filter</span>
      </Flex>
      {isFiltersOpen && generateFiltersModal()}
    </Flex>
  );
};

export default Filters;
