import { Button } from "shared/reusableComponents/Button/Button";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import finish from "assets/icons/finish.svg";
import { welcomCreatedUseStyles } from "modules/OnBoarding/components/WelcomeAndCreatedStates/WelcomeCreated.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes.constants";
import { useQuery } from "react-query";
import { getUser } from "modules/Services/auth.services";
import { useEffect } from "react"

const NewsPodCreated = () => {
  const styles = welcomCreatedUseStyles();
  const navigate = useNavigate();
  const { refetch } = useQuery("getUser", () => getUser(), {
    enabled: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Flex
      direction="column"
      gap="24px"
      className={styles.wrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Flex direction="column" gap="16px" alignItems="center">
        <h2 className={styles.title}>You did it!</h2>
        <span className={styles.description}>
          Congratulations on completing the onboarding process and creating your
          first NewsPod with HottakeAi!
        </span>
      </Flex>
      <img src={finish} alt="" />
      <Button
        handleClick={() => {
          refetch();
          navigate(ROUTES.DASHBOARD);
        }}
      >
        Go to dashboard
      </Button>
    </Flex>
  );
};

export default NewsPodCreated;
