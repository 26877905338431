import {
  defaultEveryTimes,
  defaultTimes,
} from "constants/newsPodDetails.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import CustomSelect from "shared/reusableComponents/Select/Select";
import selectTime from "assets/icons/selecTimeIcon.svg";
import { newspodDetailsUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/styles";

const Frequency = ({
  frequencyTime,
  setFrequencyTime,
  hourlyTime,
  setHourlyTime,
  dailyTime,
  setDailyTime,
}: {
  frequencyTime: string;
  setFrequencyTime: any;
  hourlyTime: any;
  setHourlyTime: any;
  dailyTime: any;
  setDailyTime: any;
}) => {
  const styles = newspodDetailsUseStyles();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).id;
    setFrequencyTime(value);
  };

  const generateContentHourly = () => {
    return (
      <Flex direction="column" gap="32px" className={styles.timePeriodWrapper}>
        <Flex
          direction="column"
          gap="12px"
          width="100%"
          className={styles.hourlyPeriodContainer}
        >
          <span className={styles.timePeriod}>
            Starting from<span style={{ color: "red" }}>*</span>
          </span>
          <Flex
            justifyContent="flex-end"
            gap="20px"
            alignItems="center"
            width="100%"
          >
            <div className={styles.hourlySelects}>
              <CustomSelect
                label="Time"
                value={hourlyTime.start}
                selectedValue={hourlyTime.end}
                handleChange={(e: any) =>
                  setHourlyTime({ ...hourlyTime, start: e.target.value })
                }
                options={defaultTimes}
                width="100%"
              />
              <div className={styles.selectTimeIcon}>
                <img src={selectTime} alt="" />
              </div>
            </div>

            <span className={styles.timePeriod} style={{ color: "#858C9B" }}>
              To<span style={{ color: "red" }}>*</span>
            </span>
            <div className={styles.hourlySelects}>
              <CustomSelect
                label="Time"
                value={hourlyTime.end}
                selectedValue={hourlyTime.start}
                handleChange={(e: any) =>
                  setHourlyTime({ ...hourlyTime, end: e.target.value })
                }
                options={defaultTimes}
                width="100%"
              />
              <div className={styles.selectTimeIcon}>
                <img src={selectTime} alt="" />
              </div>
            </div>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          direction="column"
          gap="12px"
          className={styles.hourlyPeriodContainer}
        >
          <span className={styles.timePeriod}>
            Every<span style={{ color: "red" }}>*</span>
          </span>
          <div className={styles.everySelect}>
            <CustomSelect
              label="Hour(s)"
              value={hourlyTime.every}
              handleChange={(e: any) =>
                setHourlyTime({ ...hourlyTime, every: e.target.value })
              }
              options={defaultEveryTimes}
              width="calc(50% - 30px)"
            />
          </div>
        </Flex>
      </Flex>
    );
  };
  const generateContentDaily = () => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={styles.dailyPeriodWrapper}
      >
        <span className={styles.timePeriod}>
          Daily at<span style={{ color: "red" }}>*</span>
        </span>
        <div className={styles.dailySelect}>
          <CustomSelect
            label="Time"
            value={dailyTime}
            handleChange={(e: any) => setDailyTime(e.target.value)}
            options={defaultTimes}
            width="100%"
          />
          <div className={styles.selectTimeIcon}>
            <img src={selectTime} alt="" />
          </div>
        </div>
      </Flex>
    );
  };

  return (
    <Flex className={styles.wrapper} direction="column" gap="20px" width="100%">
      <span className={styles.sectionTitle}>
        Frequency<span style={{ color: "red" }}>*</span>
      </span>
      <Flex
        direction="column"
        gap="24px"
        width="100%"
        style={{ maxWidth: "800px" }}
      >
        <Flex gap="16px" className={styles.periodContainer}>
          <Flex gap="8px">
            <input
              type="radio"
              id="Hourly"
              checked={frequencyTime === "Hourly"}
              value={frequencyTime}
              name="time"
              className={styles.radioButton}
              onChange={(e: any) => handleRadioChange(e)}
            />
            <label htmlFor="Hourly" className={styles.timePeriod}>
              Hourly
            </label>
          </Flex>

          {frequencyTime === "Hourly" && (
            <div className={styles.hourlyContentForMobile}>
              {generateContentHourly()}
            </div>
          )}

          <Flex gap="8px">
            <input
              type="radio"
              id="Daily"
              checked={frequencyTime === "Daily"}
              value={frequencyTime}
              name="time"
              className={styles.radioButton}
              onChange={handleRadioChange}
            />
            <label htmlFor="Daily" className={styles.timePeriod}>
              Daily
            </label>
          </Flex>
          {frequencyTime === "Daily" && (
            <div className={styles.dailyContentForMobile}>
              {generateContentDaily()}
            </div>
          )}
        </Flex>

        {frequencyTime === "Hourly" && (
          <div className={styles.hourlyContent}>{generateContentHourly()} </div>
        )}

        {frequencyTime === "Daily" && (
          <div className={styles.dailyContent}>{generateContentDaily()} </div>
        )}
      </Flex>
    </Flex>
  );
};
export default Frequency;
