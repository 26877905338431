import axios from "axios";
import { variables } from "variables";
const { REACT_APP_BACKEND_API_URL_ACCOUNTS } = variables;

export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          if (localStorage.getItem("refresh_token")) {
            const response = await axios.post(
              `${REACT_APP_BACKEND_API_URL_ACCOUNTS}token/refresh/`,
              {
                refresh: localStorage.getItem("refresh_token"),
              }
            );
            localStorage.setItem("access_token", response.data.access);
            return instance(originalConfig);
          }
        } catch (_error) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          return Promise.reject("");
        }
      }
    }
    return Promise.reject(err);
  }
);
