import CrossIcon from "assets/icons/components/Cross.Icon";
import { defaultSources } from "constants/newsPodDetails.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import CustomMultiSelect from "shared/reusableComponents/Select/MultiSelect";
import { newspodDetailsUseStyles } from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodDetails/styles";

const Sources = ({
  selectedSources,
  setSelectedSources,
  handleDeleteSource,
}: {
  selectedSources: string[];
  setSelectedSources: any;
  handleDeleteSource: any;
}) => {
  const styles = newspodDetailsUseStyles();

  return (
    <Flex className={styles.wrapper} width="100%" direction="column" gap="20px">
      <span className={styles.sectionTitle}>Sources</span>
      <Flex
        direction="column"
        gap="8px"
        width="100%"
        style={{ maxWidth: "800px" }}
      >
        <CustomMultiSelect
          label="Add sources"
          activeLabel="Sources"
          options={defaultSources}
          style={{ width: "100%" }}
          selectedOptions={selectedSources}
          setSelectedOptions={setSelectedSources}
        />
        <Flex gap="8px" flexWrap="wrap" style={{ maxWidth: "800px" }}>
          {selectedSources.map((source: any) => (
            <Flex
              key={source}
              gap="8px"
              alignItems="center"
              className={styles.selectedTopic}
              onClick={() => handleDeleteSource(source)}
            >
              <span>{source}</span>
              <CrossIcon />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Sources;
