import { Button } from "shared/reusableComponents/Button/Button";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import warningIcon from "assets/icons/warningIcon.svg";
import TickIcon from "assets/icons/components/Tick.Icon";
import { userState } from "constants/userInfo.constants";
import { UserInfo } from "types/userInfo.types";
import LoadingState from "shared/reusableComponents/LoadingState/LoadingState";
import { billingUseStyles } from "modules/Dashboard/Profile/components/Billing/Billing.styles";

const Subscriptions = ({
  subscriptionType,
  setSubscriptionType,
  data,
  isFetching,
  setPriceId,
  setPrice,
  userInfo,
}: {
  subscriptionType: string;
  setSubscriptionType: any;
  data: any;
  isFetching: boolean;
  setPriceId: any;
  setPrice: any;
  userInfo: UserInfo;
}) => {
  const styles = billingUseStyles();
  const handleSubscribe = (id: any, planType: string, price: number) => {
    setSubscriptionType(`${planType}ly`);
    setPriceId(id);
    setPrice(price * 100);
  };

  const generateMonthlyPlan = (plan: any) => {
    return (
      <Flex className={styles.monthlyWrapper} direction="column" gap="20px">
        <span className={styles.monthlyPlan}>Monthly Plan</span>
        <span className={styles.price}>${plan?.price}</span>
        <Flex direction="column" gap="8px">
          <span className={styles.allowTo}>HottakeAi allows you to:</span>
          <Flex direction="column" gap="12px">
            {plan?.features?.map((feature: string) => (
              <Flex gap="10px">
                <div className={styles.tickIcon}>
                  <TickIcon
                    style={{ display: "block", height: "8px", width: "8px" }}
                  />
                </div>
                <span className={styles.feature}>{feature}</span>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {subscriptionType !== "monthly" && (
          <Button
            style={{ width: "100%", padding: "8px 14px" }}
            handleClick={() =>
              handleSubscribe(plan?.id, plan.frequency.interval, plan.price)
            }
          >
            Subscribe
          </Button>
        )}
      </Flex>
    );
  };

  const generateYearlyPlan = (plan: any) => {
    return (
      <Flex className={styles.yearlyWrapper} direction="column" gap="20px">
        <span className={styles.monthlyPlan}>Yearly Plan</span>
        <span className={styles.price} style={{ color: "#FFFFFF" }}>
          ${plan?.price}
        </span>
        <Flex direction="column" gap="8px">
          <span className={styles.allowTo}>HottakeAi allows you to:</span>
          <Flex direction="column" gap="12px">
            {plan?.features?.map((feature: string) => (
              <Flex gap="10px">
                <div className={styles.tickIcon}>
                  <TickIcon
                    style={{ display: "block", height: "8px", width: "8px" }}
                  />
                </div>
                <span className={styles.feature} style={{ color: "#FFFFFF" }}>
                  {feature}
                </span>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {subscriptionType !== "yearly" && (
          <Button
            style={{ width: "100%", padding: "8px 14px" }}
            handleClick={() =>
              handleSubscribe(plan?.id, plan.frequency.interval, plan.price)
            }
          >
            Subscribe
          </Button>
        )}
      </Flex>
    );
  };

  const generateContent = () => {
    const monthlyPlan = data.find(
      (item: any) => item?.frequency?.interval === "month"
    );
    const yearlyPlan = data.find(
      (item: any) => item?.frequency?.interval === "year"
    );
    return (
      <Flex className={styles.subscriptionsContainer} gap="24px" width="100%">
        {subscriptionType.includes("monthly") &&
          generateMonthlyPlan(monthlyPlan)}
        {subscriptionType.includes("yearly") && generateYearlyPlan(yearlyPlan)}
      </Flex>
    );

    //   if (
    //     subscriptionType.includes("monthly") &&
    //     plan.frequency.interval === "month"
    //   ) {
    //     return generateMonthlyPlan(plan);
    //   }
    //   if (
    //     subscriptionType.includes("yearly") &&
    //     plan.frequency.interval === "year"
    //   ) {
    //     return generateYearlyPlan(plan);
    //   }
    //   return null;
    // });
  };

  return isFetching ? (
    <LoadingState />
  ) : (
    <Flex direction="column" gap="12px" width="100%">
      <Flex className={styles.subscriptionsContainer} gap="24px" width="100%">
        {generateContent()}
      </Flex>
      {subscriptionType !== "monthly&yearly" &&
        (userInfo.state === userState.trialing_not_subscribed ||
          userInfo.state === userState.trialing_subscribed) && (
          <Flex gap="8px">
            <img src={warningIcon} alt="" />
            <span className={styles.message}>
              The selected subscription plan will start at the end of the 3-day
              trial.
            </span>
          </Flex>
        )}
    </Flex>
  );
};

export default Subscriptions;
