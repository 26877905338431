const BillingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.37508 4.16602C3.65679 4.16602 2.96791 4.45136 2.46 4.95927C1.95209 5.46718 1.66675 6.15605 1.66675 6.87435V7.91602H18.3334V6.87435C18.3334 6.51869 18.2634 6.1665 18.1273 5.83791C17.9911 5.50932 17.7917 5.21076 17.5402 4.95927C17.2887 4.70778 16.9901 4.50828 16.6615 4.37218C16.3329 4.23607 15.9807 4.16602 15.6251 4.16602H4.37508ZM18.3334 9.16602H1.66675V13.1243C1.66675 13.8426 1.95209 14.5315 2.46 15.0394C2.96791 15.5473 3.65679 15.8327 4.37508 15.8327H15.6251C15.9807 15.8327 16.3329 15.7626 16.6615 15.6265C16.9901 15.4904 17.2887 15.2909 17.5402 15.0394C17.7917 14.7879 17.9911 14.4894 18.1273 14.1608C18.2634 13.8322 18.3334 13.48 18.3334 13.1243V9.16602ZM13.1251 12.0827H15.2084C15.3742 12.0827 15.5331 12.1485 15.6504 12.2657C15.7676 12.383 15.8334 12.5419 15.8334 12.7077C15.8334 12.8734 15.7676 13.0324 15.6504 13.1496C15.5331 13.2668 15.3742 13.3327 15.2084 13.3327H13.1251C12.9593 13.3327 12.8004 13.2668 12.6831 13.1496C12.5659 13.0324 12.5001 12.8734 12.5001 12.7077C12.5001 12.5419 12.5659 12.383 12.6831 12.2657C12.8004 12.1485 12.9593 12.0827 13.1251 12.0827Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BillingIcon;
