import { Link } from "react-router-dom";
import { customLinkUseStyles } from "./CustomLink.styles";
const CustomLink = ({
  to,
  style,
  children,
}: {
  to: string;
  style?: any;
  children: any;
}) => {
  const styles = customLinkUseStyles();
  return (
    <Link to={to} className={styles.link} style={style}>
      {children}
    </Link>
  );
};

export default CustomLink;
