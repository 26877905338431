type ErrorSuccessMessages = {
  [key: string]: string;
};

export const errorSuccessMessages: ErrorSuccessMessages = {
  incorrect: "Email format is incorrect",
  user_not_exist: "There is no registered user with this email.",
  noMatch: "Passwords don’t match.",
  token_not_valid:
    "Your password reset URL has expired. Please try to reset your password again.",
  user_exists:
    "A user with this email address already exists. Please sign in or use another email address.",
  invalid_old_password: "The old password you’ve entered is invalid.",
  incorectEmailOrPass: "Incorrect email or password.",
  invalid_name:
    "You have another NewsPod with the exact same name. Please change the name to save.",
  resetConfirmed: "Your password has been reset!",
  paymentConfirmed: "You have been successfully subscribed.",
};
