import { createUseStyles } from "react-jss";
import { Breakpoints } from "constants/styles.contants";
import { AppTheme } from "shared/types/styles.types";
const backgroundImage = require("assets/icons/signInBackground.png");
const backgroundImageForMobile = require("assets/icons/backgroundImageForMobile.png");

export const authLayoutUseStyles = createUseStyles((theme: AppTheme) => ({
  wrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },

  authContainer: {
    width: "55%",
    background: "#FFF",
    borderRadius: "80px 0px 0px 80px",
    height: "100vh",
    overflow: "auto",
  },
  childrenContainer: {
    maxWidth: "400px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#5D6269",
    margin: "0 0 16px",
  },

  divider: {
    height: "1px",
    background: "#CCCED6",
    width: "400px",
  },
  authWidthGoogle: {
    padding: "10px 16px",
    border: "1px solid #CCCED6",
    borderRadius: "8px",
    width: "calc(100% - 34px)",
    margin: "0",
    cursor: "pointer",

    "&>span": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#5D6269",
    },
    "&:hover": {
      background: "#F8F8F8",
    },
  },
  description: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
  },
  [Breakpoints.Mobile]: {
    wrapper: {
      backgroundImage: `url(${backgroundImageForMobile})`,
      flexDirection: "column",
      gap: "32px",
      justifyContent: "center",
    },
    childrenContainer: {
      padding: "20px",
      width: "calc(100% - 40px)",
    },
    authContainer: {
      width: "calc(100% - 30px)",
      height: "auto",
      borderRadius: "8px",
    },
    divider: {
      width: "305px",
    },
  },
}));
