import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { variables } from "variables";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "react-query";
import {
  getBillingInformation,
  getSubscriptions,
} from "modules/Services/subscriptions.services";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import Subscriptions from "modules/Dashboard/Profile/components/Billing/Subscriptions";
import BillingInformation from "modules/Dashboard/Profile/components/Billing/BillingInformation";
import CheckoutForm from "modules/Dashboard/Profile/components/Billing/CheckoutForm";
import { billingUseStyles } from "modules/Dashboard/Profile/components/Billing/Billing.styles";
import LoadingState from "shared/reusableComponents/LoadingState/LoadingState";

const { REACT_APP_STRIPE_PUBLIC_KEY } = variables;
const stripePromise: any = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const Billing = ({ userInfo }: { userInfo: any }) => {
  const styles = billingUseStyles();

  const [subscriptionType, setSubscriptionType] =
    useState<string>("monthly&yearly");
  const [priceId, setPriceId] = useState("");
  const [price, setPrice] = useState(900);

  const {
    data: { data: plansData = {} } = {},
    isFetching: isGetPlansFetching,
  } = useQuery("getSubscriptions", () => getSubscriptions());

  const { data: { data = {} } = {}, isFetching } = useQuery(
    "getbillingInformation",
    () => getBillingInformation()
  );

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    mode: "subscription",
    amount: price,
    currency: "usd",
    appearance,
    style: {
      base: {
        width: "50%",
        height: "100%",
      },
    },
  };

  const generateContent = () => {
    if (isFetching) {
      return (
      <LoadingState/>
      );
    }
    if (data?.cards?.length) {
      return (
        <BillingInformation billingInformation={data} userInfo={userInfo} />
      );
    }

    return (
      <Flex className={styles.billingWrapper} direction="column" gap="32px">
        <span className={styles.pageTitle}>
          {subscriptionType !== "monthly&yearly"
            ? "Add payment method"
            : "Choose your plan"}
        </span>
        <Flex className={styles.billingContainer} gap="24px" width="100%">
          <Subscriptions
            subscriptionType={subscriptionType}
            setSubscriptionType={setSubscriptionType}
            data={plansData}
            isFetching={isGetPlansFetching}
            setPriceId={setPriceId}
            setPrice={setPrice}
            userInfo={userInfo}
          />

          {subscriptionType !== "monthly&yearly" && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                setSubscriptionType={setSubscriptionType}
                priceId={priceId}
              />
            </Elements>
          )}
        </Flex>
      </Flex>
    );
  };
  return generateContent();
};

export default Billing;
