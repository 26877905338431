import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const newsfeedItemUseStyles = createUseStyles(() => ({
  newsfeedWrapper: {
    paddingBottom: "24px",
    borderBottom: "1px solid #E6E6EB",
  },
  newspodName: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#858C9B",
    cursor: "pointer",
  },
  title: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#5D6269",
  },
  publishedDate: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#A3A9B5",
  },
  source: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
  },
  divider: {
    height: "30px",
    width: "1px",
    background: "#D8D8D8",
  },
  durationsContainer: {
    padding: "6px 16px",
    background: "#F2F3F4",
    borderRadius: "40px",
    "&>:first-child": {
      paddingRight: "12px",
      borderRight: "1px solid #E6E6EB",
    },
  },
  durationValue: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#697182",
  },
  imageContainer: {
    width: "320px",
    "&>img": {
      display: "block",
      width: "320px",
      objectFit: "cover",
      borderRadius: "12px",
      minHeight: "200px",
      maxHeight: "200px",
    },
  },
  summary: {
    fontSize: " 16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#3E3232BF",
  },

  topic: {
    padding: "8px 16px",
    background: "rgba(117, 102, 209, 0.10)",
    borderRadius: "16px",
    color: "#7566D1",
    fontSize: "14px",
    fontWeight: "500",
  },
  articleContainer: {
    width: "100%",
  },
  articleInfoContainer: {
    marginTop: "8px",
  },
  sourceTopicContainer: {},

  [Breakpoints.Tablet]: {
    articleContainer: {
      flexDirection: "column",
    },
    articleInfoContainer: {
      flexDirection: "column",
      gap: "16px",
    },
    imageContainer: {
      width: "100%",
      borderRadius: "12px",
      "&>img": {
        width: "100%",
      },
    },
  },
  [Breakpoints.Mobile]: {
    sourceTopicContainer: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    divider: {
      display: "none",
    },
  },
}));
