import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const newsPodCreateOrEditUseStyles = createUseStyles(() => ({
  sectionName: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#5D6269",
    margin: "0",
  },
  newsPodCreationWrapper: {
    width: "100%",
  },

  newsPodCreationLocation: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
    cursor: "pointer",
  },
  wrapper: {
    position: "relative",
  },
  newsPodCreationLocationWrapper: {},
  closeIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    cursor:"pointer"
  },

  divider: { display: "none" },
  [Breakpoints.Mobile]: {
    newsPodCreationLocationWrapper: {
      padding: "0 15px",
    },
    newsPodCreationWrapper: {
      width: "100%",
      margin: "0 auto",
      gap: "24px",
    },

    wrapper: {
      width: "100%",
    },
    divider: {
      height: "1px",
      width: "100%",
      background: "#E6E6EB",
    },
  },
}));
