import { useEffect, useRef, useState } from "react";
import { ArrowDownIcon } from "assets/icons/components/ArrowDown.icon";
import { ArrowUpIcon } from "assets/icons/components/ArrowUp.icon";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { selectUseStyles } from "shared/reusableComponents/Select/SelectWithChangedLabel/Select.styles";

interface ISelectProps {
  label: string;
  options: string[];
  selectedOption: string;
  selectedOptionSrc?: string;
  setSelectedOption: (option: string) => void;
  placeholder: string;
  disabled?: boolean;
}
const SelectWithChangedLabel = ({
  label,
  options,
  selectedOption,
  selectedOptionSrc,
  setSelectedOption,
  placeholder,
  disabled,
}: ISelectProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const styles = selectUseStyles({ isOpen });

  const handleSelectChange = (option: string) => {
    setIsOpen(false);
    setSelectedOption(option);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div className={styles.selectWrapper} ref={selectRef}>
      {(isOpen || selectedOption) && (
        <span className={styles.label}>{label}</span>
      )}
      <div
        className={styles.selectInput}
        onClick={() => !disabled && setIsOpen(true)}
        style={{ border: isOpen ? "1px solid #537FC1" : "1px solid #C2C4CB" }}
      >
        {!isOpen && !selectedOption && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        {selectedOption && (
          <Flex gap="12px">
            {selectedOptionSrc && (
              <img src={selectedOptionSrc} alt="selectedOptionSrc" />
            )}
            <span className={styles.optionName}>{selectedOption}</span>
          </Flex>
        )}
        <div
          onClick={(event) => {
            event.stopPropagation();
            !disabled && setIsOpen((prev) => !prev);
          }}
          className={styles.arrowIcon}
        >
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </div>

      {isOpen && (
        <div className={styles.optionsContainer}>
          {options.map((option) => (
            <Flex
              key={option}
              gap="12px"
              className={styles.option}
              onClick={() => handleSelectChange(option)}
            >
              {/* <img src={option.imgSrc} alt="" /> */}
              <span className={styles.optionName}>{option}</span>
            </Flex>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectWithChangedLabel;
