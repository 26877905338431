const NewsPodIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26832 22C5.77943 22 5.36076 21.7822 5.01232 21.3467C4.66387 20.9111 4.48995 20.3881 4.49054 19.7778V4.22222C4.49054 3.61111 4.66476 3.08778 5.01321 2.65222C5.36165 2.21667 5.78002 1.99926 6.26832 2H15.5655C15.8693 2 16.1566 2.13809 16.3463 2.37531L20.2714 7.28164C20.4133 7.45896 20.4905 7.67927 20.4905 7.90634V19.7778C20.4905 20.3889 20.3163 20.9122 19.9679 21.3478C19.6194 21.7833 19.2011 22.0007 18.7128 22H6.26832ZM8.0461 16.5556C8.0461 17.1078 8.49381 17.5556 9.0461 17.5556H15.935C16.4873 17.5556 16.935 17.1078 16.935 16.5556V16.3333C16.935 15.781 16.4873 15.3333 15.935 15.3333H9.0461C8.49381 15.3333 8.0461 15.781 8.0461 16.3333V16.5556ZM8.0461 12.1111C8.0461 12.6634 8.49381 13.1111 9.0461 13.1111H15.935C16.4873 13.1111 16.935 12.6634 16.935 12.1111V11.8889C16.935 11.3366 16.4873 10.8889 15.935 10.8889H9.0461C8.49381 10.8889 8.0461 11.3366 8.0461 11.8889V12.1111ZM8.0461 7.66667C8.0461 8.21895 8.49381 8.66667 9.0461 8.66667H11.4905C12.0428 8.66667 12.4905 8.21895 12.4905 7.66667V7.44445C12.4905 6.89216 12.0428 6.44445 11.4905 6.44445H9.0461C8.49381 6.44445 8.0461 6.89216 8.0461 7.44445V7.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NewsPodIcon;
