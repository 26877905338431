import { Box, Button, Typography } from "@mui/material";

import { useDashboardContext } from "context/Dashboard.context";

import { ReactComponent as EmpatySvg } from "assets/icons/empaty.svg";
import { ReactComponent as AddNewsPodIcon } from "assets/icons/addNewsPodIcon.svg";

import { FC } from "react";

interface Props {
  title: string;
  message: string;
  variant?: boolean;
}

const EmptyState: FC<Props> = ({ title, message, variant = false }) => {
  const { setIsOpenCreateNewspodModal }: any = useDashboardContext();

  return (
    <Box width="100%">
      <Typography sx={{ fontSize: "18px", fontWeight: 600, color: "#5D6269" }}>
        {title}
      </Typography>
      <Box
        gap="24px"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmpatySvg />
        <Typography
          sx={{ fontSize: "16px", fontWeight: 400, color: "#5D6269" }}
        >
          {message}
        </Typography>

        {!variant && (
          <Button
            sx={{
              background: "#537FC1",
              color: "#FFF",
              borderRadius: "8px",
              padding: "10px 16px",
              fontSize: "14px",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#537FC1",
                boxShadow: "none",
              },
            }}
            onClick={() =>
              setIsOpenCreateNewspodModal({ open: true, type: "" })
            }
            startIcon={<AddNewsPodIcon />}
          >
            Add NewsPod
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EmptyState;
