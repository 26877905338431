import { useEffect, useRef, useState } from "react";
import play from "assets/icons/play.svg";
import pause from "assets/icons/pause.svg";
import volumeOn from "assets/icons/mute.svg";
import volumeOff from "assets/icons/unmute.svg";
import Skeleton from "react-loading-skeleton";
import { formatListeningDuration } from "helpers/convertTimes";
import { waveFormUseStyles } from "shared/reusableComponents/WaveForm/WaveForm.styles";
import { useWavesurfer } from "@wavesurfer/react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { playAudio, stopAudio } from "shared/reusableComponents/WaveForm/helperFunctions/playPause";



const Waveform = ({
  audio,
  listeningDuration,
}: {
  audio: any;
  listeningDuration: number;
}) => {
  const styles = waveFormUseStyles();
  const containerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);

  const { isReady, wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    waveColor: "rgba(83, 127, 193, 0.20)",
    progressColor: "#537FC1",
    barWidth: 4,
    height: 32,
    barRadius: 28,
    cursorColor: "#537FC1",
    url: audio,
  });

  const onPlayPause = () => {
    if (wavesurfer) {
      if (!isPlaying) {
        playAudio(wavesurfer);
      }
      wavesurfer.playPause();
    }
  };

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('play', () => playAudio(wavesurfer));
      wavesurfer.on('pause', () => stopAudio(wavesurfer));
      wavesurfer.on('finish', () => stopAudio(wavesurfer));
    }
  }, [wavesurfer]);


  const toggleMute = () => {
    if (wavesurfer) {
      wavesurfer.setVolume(isMuted ? 1 : 0);
      setIsMuted(!isMuted);
    }
  };

  return (
    <Flex
      alignItems="center"
      gap="10px"
      width="100%"
      className={styles.audioContainer}
      style={{
        border: isPlaying ? "1.5px solid #537FC1" : "none",
        boxShadow: isPlaying
          ? "0px 0px 10px 0px rgba(83, 127, 193, 0.25)"
          : "unset",
      }}
    >
      {!!isReady ? (
        <div className={styles.playIcon} onClick={onPlayPause}>
          <img src={isPlaying ? pause : play} alt="pause" />
        </div>
      ) : (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Skeleton height={32} width={32} circle />
          <Skeleton className={styles.skeleton} height={30} />
          <Skeleton height={30} width={60} />
        </div>
      )}
      <div ref={containerRef} style={{ width: "100%" }} />
      {!!isReady && (
        <>
          <div className={styles.listeningDuration}>
            {formatListeningDuration(listeningDuration, currentTime)}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={toggleMute}
          >
            <img src={isMuted ? volumeOff : volumeOn} alt="mute" />
          </div>
        </>
      )}
    </Flex>
  );
};

export default Waveform;
