const ErrorTosterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3ZM15.71 14.35C16.1 14.74 16.1 15.37 15.71 15.76C15.51 15.96 15.26 16.05 15 16.05C14.74 16.05 14.49 15.95 14.29 15.76L12 13.47L9.71 15.76C9.51 15.96 9.26 16.05 9 16.05C8.74 16.05 8.49 15.95 8.29 15.76C7.9 15.37 7.9 14.74 8.29 14.35L10.58 12.06L8.29 9.77C7.9 9.38 7.9 8.75 8.29 8.36C8.68 7.97 9.31 7.97 9.7 8.36L11.99 10.65L14.28 8.36C14.67 7.97 15.3 7.97 15.69 8.36C16.08 8.75 16.08 9.38 15.69 9.77L13.4 12.06L15.69 14.35H15.71Z"
        fill="#E74768"
      />
    </svg>
  );
};

export default ErrorTosterIcon;
