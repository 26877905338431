import { CircularProgress, Step, StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { customStepperUseStyles } from "./CustomStepper.styles";
import "./styles.css";

const steps = [
  {
    label: "Topics",
    description: `Explore and choose the topics that best represent your personal interests and preferences.`,
  },
  {
    label: "Sources",
    description:
      "Select the sources you trust the most for receiving your news.",
  },
  {
    label: "Frequency",
    description: `Choose how often you would like to receive news updates during the day.`,
  },
  {
    label: "Channels",
    description: `Select the channels you wish to use to receive your news updates during the day.`,
  },
];

const CustomStepper = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (a: number) => void;
}) => {
  const styles = customStepperUseStyles();

  return (
    <>
      <div className={styles.customStepperWrapper}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step
              key={step.label}
              onClick={() => activeStep > index && setActiveStep(index)}
              style={{ cursor: "pointer" }}
            >
              <StepLabel>
                <div>
                  <div
                    className={styles.stepLabel}
                    style={{
                      color: activeStep >= index ? "#5D6269" : "#C5C8CC",
                    }}
                  >
                    {step.label === "Sources" ? (
                      <div className={styles.optional}>
                        {step.label}
                        <span>Optional</span>
                      </div>
                    ) : (
                      step.label
                    )}
                  </div>
                  <div
                    className={styles.stepDescription}
                    style={{
                      color: activeStep >= index ? "#858C9B" : "#C5C8CC",
                    }}
                  >
                    {step.description}
                  </div>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <Flex className={styles.customMobileStepperWrapper} gap="16px">
        <CircularProgress
          variant="determinate"
          value={(activeStep + 1) * 25}
          style={{ width: "56px", height: "56px" }}
          className={styles.roundProgress}
        />
        <Flex direction="column">
          <div className={styles.stepLabel}>
            {steps[activeStep].label === "Sources" ? (
              <div className={styles.optional}>
                {steps[activeStep].label}
                <span>Optional</span>
              </div>
            ) : (
              steps[activeStep].label
            )}
          </div>
          <span className={styles.stepDescription}>
            {steps[activeStep].description}
          </span>
        </Flex>
        <span className={styles.count}>{activeStep + 1} of 4</span>
      </Flex>
    </>
  );
};

export default CustomStepper;
