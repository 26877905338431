import { instance } from "api/ApiRequest";
import axios from "axios";
import { variables } from "variables";
const { REACT_APP_BACKEND_API_URL_ACCOUNTS } = variables;

export const signUpUser = async (payload: any) => {
  const response = await axios.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}registration/`,
    payload
  );
  return response;
};

export const signInUser = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}login/`,
    payload
  );
  return response;
};

export const logoutUser = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}logout/`,
    payload
  );
  return response;
};

export const signinWithGoogle = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}google-login/`,
    payload
  );
  return response;
};

export const getUser = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}user/`
  );
  return response;
};

export const resetPassword = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}password/reset/`,
    payload
  );
  return response;
};

export const resetPasswordConfirm = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}password/reset/confirm/`,
    payload
  );
  return response;
};

export const editUser = async (payload: any) => {
  const response = await instance.patch(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}user/`,
    payload
  );
  return response;
};
export const passwordChange = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}password/change/`,
    payload
  );
  return response;
};
export const magicLink = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}magic-link/`,
    payload
  );
  return response;
};

export const magicLinkConfirm = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}magic-link/confirm/`,
    payload
  );
  return response;
};

export const deleteAccount = async () => {
  const response = await instance.delete(
    `${REACT_APP_BACKEND_API_URL_ACCOUNTS}user/`
  );
  return response;
};
