import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const profileSettingUseStyles = createUseStyles(() => ({
  profileDetailsWrapper: {
    padding: "24px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
    width: "100%",
  },
  connectedAccountsWrapper: {
    padding: "24px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
    width: "100%",
  },
  nameContainer: {
    color: "#858C9B",
    fontSize: "16px",
    fontWeight: "400",
  },
  channelTelegram: {
    paddingRight: "24px",
    borderRight: "1px solid #E6E6EB",
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
  },
  channelEmail: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#5D6269",
  },

  name: {
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "400",
  },
  nameEditContainer: {
    padding: "8px 14px",
    border: "1px solid #537FC1",
    borderRadius: "8px",
    color: "#537FC1",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
    "&:hover": {
      color: "#446BA6",
    },
  },
  sectionTitle: {
    color: "#5D6269",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
  },

  changePasswordWrapper: {
    padding: "24px",
    width: "100%",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  requirements: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    maxWidth: "400px",
  },
  passwordSecurity: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#5D6269",
  },
  cardElement: {
    height: "100px",
    width: "100%",
  },
  wrapper: {},
  profileDetailsContainer: {
    flexDirection: "row",
  },
  nameWrapper: {},
  buttonsContainer: {},
  timeZoneContainer: {},
  channelsContainer: {},
  changePasswordContainer: {},
  changePasswordInputsWrapper: {
    gap: "24px",
  },
  changePasswordInputs: {
    maxWidth: "636px",
    width: "100%",
  },
  iconContainer: {
    padding: "10px",
    background: "#F5F5F5",
    borderRadius: "8px",
    "&>img": {
      display: "block",
    },
  },
  requirementsWrapper: {
    minWidth: "200px",
  },
  emailContainer: {},

  [Breakpoints.LargeScreen]: {
    wrapper: {
      flexDirection: "column",
    },
  },

  [Breakpoints.Tablet]: {
    wrapper: {
      flexDirection: "column",
    },

    name: {
      fontSize: "14px",
    },
    profileDetailsWrapper: {
      border: "none",
      borderBottom: "1px solid #E6E6EB",
      padding: "0 0 24px",
      gap: "32px",
      width: "100%",
      borderRadius: "0",
    },
    connectedAccountsWrapper: {
      border: "none",
      padding: "0 0 24px",
      gap: "32px",
      width: "100%",
      borderRadius: "0",
    },
    profileDetailsContainer: {
      flexDirection: "column",
      gap: "12px",
      alignItems: "flex-start",
    },
    nameEditContainer: {
      width: "100%",
      justifyContent: "center",
    },
    nameWrapper: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
    },
    buttonsContainer: {
      flexDirection: "column-reverse",
      "&>button": {
        width: "100%",
      },
    },
    channelTelegram: {
      border: "none",
    },
    timeZoneContainer: {
      flexDirection: "column",
      gap: "12px",
    },
    channelsContainer: {
      flexDirection: "column",
    },
    changePasswordContainer: {
      flexDirection: "column",
      gap: "12px",
    },
    changePasswordInputsWrapper: {
      flexDirection: "column",
      gap: "32px",
    },
    requirementsWrapper: {
      gap: "8px",
    },
    emailContainer: {
      flexDirection: "column",
      gap: "12px",
    },
  },
}));
