import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const modalsUseStyles = createUseStyles(() => ({
  imgContainer: {
    padding: "0 120px",
    background: "#404F5A",
    borderRadius: "8px 8px 0 0",
  },
  info: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#5D6269",
  },
  message: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
  },
  buttonsContainer: {
    padding: " 8px 24px 24px",
  },
  container: {},
  [Breakpoints.Mobile]: {
    imgContainer: {
      padding: "0 20px",
    },
    buttonsContainer: {
      width: "calc(100% - 40px)",
      padding: " 8px 20px 24px",
      flexDirection: "column-reverse",
      alignItems: "center",
      gap: "16px",
      "&>button": {
        width: "100%",
      },
    },
    container: {
      alignItems: "flex-start",
      padding: "0 20px",
    },
  },
}));
