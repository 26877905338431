import { useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Input } from "shared/reusableComponents/Input/Input";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { resetPasswordConfirm } from "modules/Services/auth.services";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import { ROUTES } from "constants/routes.constants";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import PassworRequirements from "shared/reusableComponents/PasswordRequirments/PasswordRequirments";
import hotakeAiLogoLight from "assets/icons/hotakeAiLogoLight.svg";
import { forgotPasswordUseStyles } from "modules/Autentication/ForgotPassword/pages/ForgotPassword.styles";

const ForgotPasswordConfirm = () => {
  const styles = forgotPasswordUseStyles();
  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMessageForToaster, setErrorMessageForToaster] =
    useState<string>("");
  const [requirements, setRequirements] = useState({
    charCount: false,
    specialChar: false,
    numberCount: false,
    uppercaseChar: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const uid = location.pathname.split("/")[4];
  const token = location.pathname.split("/")[5];

  const { mutate } = useMutation(
    () =>
      resetPasswordConfirm({
        password: resetPasswordDetails.password,
        confirm_password: resetPasswordDetails.confirmPassword,
        uid,
        token,
      }),
    {
      onSuccess: () => {
        navigate(ROUTES.SIGNIN, {
          state: {
            successMessageForToaster: errorSuccessMessages.resetConfirmed,
          },
        });
      },

      onError: ({ response }) => {
        const key = response.data.code;
        setErrorMessageForToaster(errorSuccessMessages[key]);
      },
    }
  );
  const isFormValid = () => {
    return (
      requirements.charCount &&
      requirements.numberCount &&
      requirements.specialChar &&
      requirements.uppercaseChar &&
      resetPasswordDetails.password === resetPasswordDetails.confirmPassword
    );
  };

  const handleConfirm = () => {
    mutate();
  };

  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    setResetPasswordDetails({
      ...resetPasswordDetails,
      password: value,
    });
  };

  return (
    <Flex
      className={styles.wrapper}
      justifyContent="flex-end"
      alignItems="center"
      gap="210px"
    >
      <div>
        <img src={hotakeAiLogoLight} alt="" />
      </div>
      <Flex
        className={styles.authContainer}
        alignItems="center"
        justifyContent="center"
        width="55%"
      >
        <Flex
          className={styles.forgotPassContainer}
          direction="column"
          justifyContent="center"
          gap="40px"
          alignItems="center"
          width="100%"
          style={{ maxWidth: "560px" }}
        >
          <span className={styles.title}>Create new password</span>
          <Flex direction="column" gap="32px" width="100%">
            <Flex direction="column" gap="12px" width="100%">
              <Input
                label="Password"
                inputType="password"
                defaultValue={resetPasswordDetails.password}
                handleChange={handlePasswordChange}
              />
              {resetPasswordDetails.password && (
                <PassworRequirements
                  password={resetPasswordDetails.password}
                  setRequirements={setRequirements}
                  requirements={requirements}
                />
              )}
            </Flex>
            <Input
              label="Confirm Password"
              defaultValue={resetPasswordDetails.confirmPassword}
              inputType="password"
              handleChange={(e) =>
                setResetPasswordDetails({
                  ...resetPasswordDetails,
                  confirmPassword: (e.target as HTMLInputElement).value,
                })
              }
              hasError={
                !!resetPasswordDetails.password &&
                !!resetPasswordDetails.confirmPassword &&
                resetPasswordDetails.password !==
                  resetPasswordDetails.confirmPassword
              }
              errorText={errorSuccessMessages.noMatch}
            />
          </Flex>
          <Button
            variant={ButtonVariants.Primary}
            style={{ width: "100%" }}
            disabled={!isFormValid()}
            handleClick={handleConfirm}
          >
            Submit
          </Button>
        </Flex>
      </Flex>
      <ErrorToaster
        message={errorMessageForToaster}
        setMessage={setErrorMessageForToaster}
      />
    </Flex>
  );
};

export default ForgotPasswordConfirm;
