import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";
import searchIcon from "assets/icons/searchIcon.svg";

export const filtersUseStyles = createUseStyles(() => ({
  wrapper: {
    position: "relative",
  },
  searchInputWrapper: {
    position: "relative",
    width: "100%",
  },
  searchInput: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid #C2C4CB",
    width: "304px",
    backgroundRepeat: "no-repeat",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#444444",
    display: "block",
    backgroundPosition: "94% center",
    backgroundSize: "20px 20px",
    boxSizing: "border-box",
    backgroundImage: `url(${searchIcon})`,
    padding: "8px 50px 8px 16px",
    "&:focus": {
      border: "1px solid #D9D9D9",
    },
    "&:hover ": {
      border: "1px solid #A3A9B5",
      boxShadow: "0px 0px 4px 0px #E8E8E8",
      backgroundColor: "#F8F8F8",
    },

    "&:disabled": {
      border: "1px solid transparent",
      backgroundColor: "rgba(15, 49, 77, 0.04)",
      boxShadow: "none",
    },

    "&:focus-visible": {
      outline: "none",
    },

    "&::placeholder": {
      fontWeight: "400",
      fontSize: "16px",
      color: "#C2C4CB",
    },

    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  filtersWrapper: {
    padding: "10px 16px",
    border: "1px solid #3963A2",
    borderRadius: "8px",
    position: "relative",
    cursor: "pointer",
  },
  filtersContainer: {
    padding: "20px",
    position: "absolute",
    right: "0",
    top: "44px",
    border: "1px solid #E1E3EA",
    borderRadius: "8px",
    background: "#FDFDFD",
    boxShadow: "4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
    maxWidth: "300px",
    width: "100%",
    zIndex: 1,
  },
  filter: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#3963A2",
  },
  filtersTitle: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#5D6269",
  },
  filters: {
    "&>.MuiStack-root": {
      width: "100%",
    },
    "& input": {
      padding: "10px 12px",
      fontSize: "16px",
      fontWeight: "400",
      color: "#5D6269",
    },
  },

  [Breakpoints.Mobile]: {
    wrapper: {
      flexDirection: "column",
      width: "100%",
    },
    filtersWrapper: {
      width: "100%",
      justifyContent: "center",
    },
    searchInput: {
      width: "100%",
    },
    filtersContainer: {
      maxWidth: "unset",
      top: "106px",
    },
  },
}));
