import { useEffect, useRef } from "react";
import { Button } from "shared/reusableComponents/Button/Button";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import addCategories from "assets/icons/addCategories.svg";
import cross from "assets/icons/cross.svg";
import { useOnBoardingContext } from "context/onBoarding.context";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { sourcesUseStyles } from "./Sources.styles";

const Sources = ({
  handleNextStep,
  setNewsPodSources,
  handlePrevStep,
}: {
  handleNextStep: any;
  setNewsPodSources: any;
  handlePrevStep: any;
}) => {
  const styles = sourcesUseStyles();
  const wrapperRef = useRef(null);
  const { sources, setSources, selectedSources, setSelectedSources } =
    useOnBoardingContext();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (selectedSources.length && wrapperRef?.current) {
      (wrapperRef?.current as any).scrollTop = (
        wrapperRef?.current as any
      ).scrollHeight;
    }
  }, [selectedSources.length]);

  const handleAddSource = (source: any) => {
    setSelectedSources([...selectedSources, source]);
    const indexToRemove = sources.indexOf(source);
    const newArr = [...sources];

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setSources(newArr);
  };

  const handleDeletSource = (source: any) => {
    let newArr = [...selectedSources];
    const indexToRemove = newArr.indexOf(source);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setSelectedSources(newArr);
    setSources([...sources, source]);
  };

  return (
    <Flex
      className={styles.sourcesWrapper}
      direction="column"
      justifyContent="space-between"
      alignItems="inherit"
      width="64%"
    >
      <div className={styles.wrapper} ref={wrapperRef}>
        <h2 className={styles.title}>
          Choose the sources that will bring the highest value to you.
        </h2>
        <Flex className={styles.sourcesContainer} direction="column" gap="12px">
          <Flex direction="column" gap="12px">
            <p className={styles.sectionTitle}>All sources</p>
            <Flex flexWrap="wrap" gap="12px">
              {sources.map((source: any) => (
                <Flex
                  key={source}
                  gap="8px"
                  alignItems="center"
                  className={styles.source}
                  onClick={() => handleAddSource(source)}
                >
                  <span>{source}</span>
                  <img src={addCategories} alt="" />
                </Flex>
              ))}
            </Flex>
          </Flex>
          {!!selectedSources.length && (
            <Flex
              className={styles.selectedSourcesContainer}
              direction="column"
              gap="12px"
            >
              <p className={styles.sectionTitle}>Selected sources</p>
              <Flex flexWrap="wrap" gap="12px">
                {selectedSources.map((source: any) => (
                  <Flex
                    key={source}
                    gap="8px"
                    alignItems="center"
                    className={styles.selectedSource}
                    onClick={() => handleDeletSource(source)}
                  >
                    <span>{source}</span>
                    <img src={cross} alt="" />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </div>
      <Flex
        className={styles.buttonsContainer}
        justifyContent="flex-end"
        gap="16px"
      >
        <Button
          variant={ButtonVariants.Outlined}
          handleClick={() => handlePrevStep()}
        >
          Back
        </Button>
        <Button
          handleClick={() => {
            handleNextStep();
            setNewsPodSources(selectedSources);
          }}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
export default Sources;
