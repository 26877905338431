import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import getFirstLetters from "constants/getFirstLetters.constants";
import { ROUTES } from "constants/routes.constants";
import useCategorizeResults from "constants/categorizeResults";
import { getNewsPods } from "modules/Services/newsPods.services";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { logoutUser } from "modules/Services/auth.services";

import LogoutIcon from "assets/icons/components/Logout.Icon";
import menuSvg from "assets/icons/menu.svg";
import profileIcon from "assets/icons/profileIcon.svg";
import InfoIConSvg from "assets/icons/activateTooltipIcon.svg";
import { ReactComponent as InboxIcon } from "assets/icons/topicsSvg.svg";
import { ReactComponent as AddNewsPodIcon } from "assets/icons/addNewsPodIcon.svg";
import { ReactComponent as HottakeAILogo } from "assets/icons/hottakeAILogo.svg";
import { ReactComponent as NewsFeed } from "assets/icons/newsFeed.svg";
import { ReactComponent as NewsFeedBlue } from "assets/icons/newsFeedBlue.svg";
import { ReactComponent as CloseSvg } from "assets/icons/close.svg";
import { NewsPodsResult } from "types/newsPods.types";
import { useDashboardContext } from "context/Dashboard.context";

export default function Header({ userName }: { userName: string }) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { setIsOpenCreateNewspodModal }: any = useDashboardContext();

  const { data: { data = {} } = {} } = useQuery("getNewspods", () =>
    getNewsPods({})
  );

  const { results } = data;

  const {
    activeData = [],
    inactiveData = [],
    totalArticlesCount,
  } = useCategorizeResults(results);

  useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line
  }, [location?.pathname]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const refreshToken = localStorage.getItem("refresh_token");

  const { mutate } = useMutation(() => logoutUser({ refresh: refreshToken }), {
    onSuccess: () => {
      localStorage.removeItem("lastTrialOverModalShown");
      localStorage.removeItem("lastCanceledModalShown");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      navigate(ROUTES.SIGNIN);
    },
  });

  const handleLogout = () => {
    mutate();
  };
  const pathname = location?.pathname.split("/").join("");

  const { firstLetterName, firstLetterLastName } = getFirstLetters(userName);

  const DrawerList = (
    <Drawer
      sx={{
        display: { sm: "block", md: "none" },
        "& .MuiPaper-root": {
          width: { sm: "440px", xs: "100%" },
          borderRadius: { sm: "0 20px 20px 0 ", xs: 0 },
          height: "100%",
          justifyContent: "space-between",
          padding: "20px",
        },
      }}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 0 20px 0",
            alignItems: "center",
          }}
        >
          <HottakeAILogo />
          <ListItemIcon
            sx={{ padding: 0, minWidth: "max-content" }}
            onClick={() => setOpen(false)}
          >
            <CloseSvg />
          </ListItemIcon>
        </Box>
        <Divider sx={{ color: "#E6E6EB" }} />

        <List
          disablePadding
          sx={{
            display: "flex",
            gap: "10px",
            color: "#858C9B",
            borderBottom: "1px solid #E6E6EB",
          }}
        >
          <ListItemButton
            onClick={() => navigate(ROUTES.PROFILE)}
            sx={{
              minHeight: 48,
              justifyContent: "initial",
              gap: "10px",
              padding: "20px 0",
              color: location.pathname === "profile" ? "red" : "none",
            }}
          >
            {userName ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "32px",
                  height: "32px",
                  background: "#F2F6FD",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#A3A9B5",
                  borderRadius: "50px",
                  padding: "10px",
                }}
              >
                {firstLetterName}
                {firstLetterLastName}
              </Box>
            ) : (
              <img src={profileIcon} alt="profileIcon" />
            )}
            <Flex
              gap="5px"
              alignItems="center"
              style={{
                position: "relative",
                fontSize: "14px",
              }}
            >
              <Typography
                sx={{ color: "#5D6269", fontSize: "16px", fontWeight: 500 }}
              >
                {userName}
              </Typography>
            </Flex>
          </ListItemButton>
        </List>

        <ListItemButton
          onClick={() => navigate(ROUTES.DASHBOARD)}
          sx={{
            minHeight: 48,
            justifyContent: "space-between",
            marginTop: "8px",
            borderRadius: "12px",
            px: 3,
            padding: "12px 16px",
          }}
        >
          <Box display="flex" gap="8px">
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
              }}
            >
              {pathname === "newsfeed" ? <NewsFeedBlue /> : <NewsFeed />}
            </ListItemIcon>
            <Typography
              sx={{
                fontSize: "14px",
                color: pathname === "newsfeed" ? "#537FC1" : "#5D6269",
                fontWeight: pathname === "newsfeed" ? 600 : 400,
              }}
            >
              News Feed
            </Typography>
          </Box>
          {totalArticlesCount > 0 && (
            <Box
              sx={{
                display: "flex",
                width: "28px",
                height: "20px",
                padding: " 0px 12px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#537FC1",
                borderRadius: "50px",
                fontSize: "10px",
                color: "#fff",
              }}
            >
              {totalArticlesCount}
            </Box>
          )}
        </ListItemButton>

        {!!activeData?.length && (
          <Box sx={{ marginTop: "12px" }}>
            <Typography
              sx={{
                padding: "0 16px",
                fontSize: "12px",
                color: "#A3A9B5",
                fontWeight: 500,
              }}
            >
              ACTIVE
            </Typography>
            <List sx={{ padding: 0 }}>
              {activeData?.map(
                (
                  { name, unread_articles_count, uuid }: NewsPodsResult,
                  index: number
                ) => (
                  <ListItem
                    sx={{
                      padding: "12px 16px",
                      borderBottom: "1px solid #E6E6EB",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                    onClick={() => navigate(`/newspod/${uuid}`)}
                    key={index}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        padding: 0,
                        display: "flex",
                        gap: "8px",
                        color: params.uuid === uuid ? "#537FC1" : "#5D6269",
                        fontSize: "14px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          svg: {
                            path: {
                              fill: params.uuid === uuid ? "#537FC1" : "",
                            },
                          },
                        }}
                      >
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                            fontWeight: params.uuid === uuid ? 600 : 400,
                          },
                        }}
                        primary={name}
                      />
                      {unread_articles_count > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            width: "28px",
                            height: "20px",
                            padding: " 0px 12px",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#537FC1",
                            borderRadius: "50px",
                            fontSize: "10px",
                            color: "#fff",
                          }}
                        >
                          {unread_articles_count}
                        </Box>
                      )}
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Box>
        )}

        {!!inactiveData?.length && (
          <Box sx={{ marginTop: "12px" }}>
            <Typography
              sx={{
                padding: "0px 16px",
                fontSize: "12px",
                color: "#A3A9B5",
                fontWeight: 500,
              }}
            >
              INACTIVE
            </Typography>
            <List sx={{ padding: 0 }}>
              {inactiveData?.map(
                ({ name, uuid }: NewsPodsResult, index: number) => (
                  <ListItem
                    sx={{
                      padding: "12px 16px",
                      borderBottom: "1px solid #E6E6EB",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                    onClick={() => navigate(`/newspod/${uuid}`)}
                    key={index}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        padding: 0,
                        display: "flex",
                        gap: "8px",
                        color: params.uuid === uuid ? "#537FC1" : "#5D6269",
                        fontSize: "14px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          svg: {
                            path: {
                              fill: params.uuid === uuid ? "#537FC1" : "",
                            },
                          },
                        }}
                      >
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                            fontWeight: params.uuid === uuid ? 600 : 400,
                          },
                        }}
                        primary={name}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
              }}
            >
              <img src={InfoIConSvg} alt="InfoIConSvg" />
              <Typography sx={{ color: "#A3A9B5", fontSize: "13px" }}>
                Please make sure that Telegram is connected successfully.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          paddingTop: "40px",
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            padding: "20px 56px",
            border: "1px solid #E6E6EB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Typography sx={{ color: "#858C9B", fontSize: "14px" }}>
            Quick creation
          </Typography>
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{
              width: "40px",
              height: "40px",
              background: "#537FC1",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpenCreateNewspodModal({ open: true, type: "" });
              setOpen(false);
            }}
          >
            <AddNewsPodIcon />
          </Flex>
        </Box>

        <Box
          onClick={handleLogout}
          sx={{
            width: "100%",
            padding: "12px 16px",
            color: "#858C9B",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
        >
          <LogoutIcon />
          Log out
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <Box
      sx={{
        height: "60px",
        borderBottom: "1px solid #E6E6EB",
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          display: { md: "none", xs: "flex" },
          width: "100%",
        }}
      >
        <HottakeAILogo />
        <img
          loading="lazy"
          onClick={toggleDrawer(true)}
          src={menuSvg}
          alt="menuSvg"
        />
        {DrawerList}
      </Box>
    </Box>
  );
}
