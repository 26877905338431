import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CircularProgress } from "@mui/material";
import DashboardLayout from "Layouts/Dashboard/DashboardLayout";
import { ROUTES } from "constants/routes.constants";
import ForgotPassword from "modules/Autentication/ForgotPassword/pages/ForgotPassword";
import ForgotPasswordConfirm from "modules/Autentication/ForgotPassword/pages/ForgotPasswordConfirm";
import SignIn from "modules/Autentication/SignIn/pages/SignIn";
import SignUp from "modules/Autentication/SignUp/pages/SignUp";
import NewsPods from "modules/Dashboard/NewsPods/pages/NewsPods";
import Profile from "modules/Dashboard/Profile/pages/Profile";
import OnBoarding from "modules/OnBoarding/pages/OnBoarding";
import PageNotFound from "modules/PageNotFound/PageNotFound";
import { getUser } from "modules/Services/auth.services";
import { variables } from "variables";
import MagicLinkConfirm from "modules/Autentication/MagicLink/pages/MagicLinkConfirm";
import Newsfeeds from "modules/Dashboard/Newsfeeds/pages/Newsfeeds";

const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_OAUTH_REDIRECT_URI } = variables;

const useRoutesData = () => {
  const { data: { data = {} } = {}, isFetching } = useQuery(
    "getUser",
    () => getUser(),
    {
      enabled: !!localStorage.getItem("access_token"),
    }
  );

  const routesData = [
    
    {
      path: ROUTES.SIGNIN,
      element: localStorage.getItem("access_token") ? (
        <Navigate to={ROUTES.ON_BOARDING} />
      ) : (
        <GoogleOAuthProvider
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          redirectUri={REACT_APP_OAUTH_REDIRECT_URI}
        >
          <SignIn />
        </GoogleOAuthProvider>
      ),
    },
    {
      path: ROUTES.SIGNUP,
      element: localStorage.getItem("access_token") ? (
        <Navigate to={ROUTES.ON_BOARDING} />
      ) : (
        <GoogleOAuthProvider
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          redirectUri={REACT_APP_OAUTH_REDIRECT_URI}
        >
          <SignUp />
        </GoogleOAuthProvider>
      ),
    },
    {
      path: ROUTES.SIGNUP_FROM_LANDING,
      element: localStorage.getItem("access_token") ? (
        <Navigate to={ROUTES.ON_BOARDING} />
      ) : (
        <GoogleOAuthProvider
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          redirectUri={REACT_APP_OAUTH_REDIRECT_URI}
        >
          <SignUp />
        </GoogleOAuthProvider>
      ),
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: ROUTES.FORGOT_PASSWORD_CONFIRM,
      element: <ForgotPasswordConfirm />,
    },
    {
      path: ROUTES.MAGIC_LINK_CONFIRM,
      element: <MagicLinkConfirm />,
    },
    {
      path: ROUTES.ON_BOARDING,
      element: isFetching ? (
        <CircularProgress style={{ margin: "150px auto", display: "block" }} />
      ) : data?.has_completed_onboarding ? (
        <Navigate to={ROUTES.DASHBOARD} />
      ) : (
        <OnBoarding userInfo={data} />
      ),
    },
    {
      path: ROUTES.DASHBOARD,
      element: (
        <DashboardLayout userInfo={data} isFetching={isFetching}>
          <Newsfeeds />
        </DashboardLayout>
      ),
    },
    {
      path: ROUTES.NEWSPOD,
      element: (
        <DashboardLayout userInfo={data} isFetching={isFetching}>
          <NewsPods />
        </DashboardLayout>
      ),
    },
    {
      path: ROUTES.PROFILE,
      element: (
        <DashboardLayout userInfo={data} isFetching={isFetching}>
          <Profile userInfo={data} />
        </DashboardLayout>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  return routesData;
};

export default useRoutesData;
