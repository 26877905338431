import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";
const backgroundImage = require("assets/icons/signInBackground.png");

export const forgotPasswordUseStyles = createUseStyles(() => ({
  wrapper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
  forgotPassContainer:{
    padding:"40px 80px"
  },
  authContainer: {
    background: "#FFF",
    borderRadius: "80px 0px 0px 80px",
    height: "100vh",
  },
  title: {
    margin: "0",
    color: "#5D6269",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "normal",
  },
  descripton: {
    maxWidth: "400px",
    margin: "0",
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "center",
  },
  requirements: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#A3A9B5",
  },
  skip: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#A3A9B5",
    cursor: "pointer",
  },
  [Breakpoints.Mobile]: {
    wrapper: {
      flexDirection: "column",
      gap: "32px",
      justifyContent: "center",
    },
    forgotPassContainer: {
      padding: "20px",
      width: "calc(100% - 40px)",
    },
    authContainer: {
      width: "calc(100% - 30px)",
      height: "auto",
      borderRadius: "8px",
    },
  },
}));
