import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const categoriesUsestyles = createUseStyles((theme) => ({
  wrapper: {
    maxHeight: "396px",
    overflow: "scroll",
  },
  topicsWrapper: {},
  title: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#5D6269",
    margin: "0 0 8px ",
  },
  categoriesContainer: {
    marginTop: "24px",
    border: "1px solid #E6E6EB",
    padding: "16px 20px",
    borderRadius: "8px",
  },
  categorie: {
    padding: "6px 10px 6px 14px",
    background: "#F2F6FD",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "400",
  },
  selectedCategoriesContainer: {
    width: "100%",
    borderTop: "1px solid #E6E6EB",
    paddingTop: "12px",
  },
  selectedCategorie: {
    padding: "5px 10px 5px 14px",
    background: "#F2F6FD",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#2D5999",
    border: "1px solid #537FC1",
    fontSize: "16px",
    fontWeight: "400",
    maxWidth: "100%",
  },
  keywordsContainer: {
    marginTop: "8px",
    padding: "20px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  sectionTitle: {
    margin: "0",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#5D6269",
  },
  keyword: {
    maxWidth: "calc(100% - 28px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  buttonsContainer: {},
  [Breakpoints.Mobile]: {
    topicsWrapper: {
      width: "100%",
      gap: "32px",
    },
    wrapper: {
      maxHeight: "unset",
    },
    buttonsContainer: {
      flexDirection: "column-reverse",
      "&>button": {
        width: "100%",
      },
    },
  },
}));
