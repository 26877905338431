import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const newspodDetailsUseStyles = createUseStyles(() => ({
  selectedTopic: {
    padding: "10px 10px 10px 14px",
    background: "#F4F8FF",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#5D6269",
    fontSize: "16px",
    fontWeight: "400",
    maxWidth: "100%",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#858C9B",
  },
  radioButton: {
    width: "16px",
    height: "16px",
    margin: "0",
    background: "#537FC1",
  },
  timePeriod: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    whiteSpace: "nowrap",
  },
  timePeriodWrapper: {
    padding: "20px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
    width: "100%",
  },
  dailyPeriodWrapper: {
    padding: "20px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
    width: "100%",
    "&>.MuiFormControl-root": {
      maxWidth: "280px",
      width: "100%",
    },
  },
  selectTimeIcon: {
    position: "absolute",
    top: "8px",
    right: "8px",
  },
  channelName: {
    marginLeft: "4px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5D6269",
  },
  channel: {
    width: "100%",
    padding: "20px",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  email: {
    marginLeft: "12px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#5D6269",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "60%",
  },
  status: {
    padding: "3.5px 10px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#039855",
    background: "#F2FDF7",
    borderRadius: "8px",
  },
  message: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#858C9B",
  },
  telegramLink: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#00AFD6",
    "&>a": {
      maxWidth: "254px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#00AFD6",
    },
  },
  messageForConect: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#858C9B",
    marginTop: "12px",
  },
  warnningMessage: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#858C9B",
  },
  wrapper: {},
  periodContainer: {},
  emailForMobile: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  hourlyContentForMobile: {
    display: "none",
  },
  dailyContentForMobile: {
    display: "none",
  },
  hourlyContent: {
    display: "block",
    width: "100%",
  },
  dailyContent: {
    display: "block",
    width: "100%",
  },
  hourlySelects: {
    maxWidth: "280px",
    position: "relative",
    width: "100%",
  },
  emailChannel: {},
  emailChannelForMobile: {
    display: "none",
  },
  hourlyPeriodContainer: {},
  newsPodNameWrapper: {},
  title: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#5D6269",
  },
  newsPodNameContainer: {
    position: "relative",
  },
  newsPodNameInput: {
    minWidth: "192px",
    maxWidth: "280px",
    borderRadius: "8px",
    border: "0",
    padding: "8px 12px 8px 36px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
    "&:hover": {
      border: "1px solid #C2C4CB",
    },
  },
  newsPodNameImg: {
    position: "absolute",
    top: "7px",
    left: "12px",
    "&>img": {
      display: "block",
      height: "18px",
      width: "18px",
    },
  },
  nameError: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#E74768",
    paddingLeft: "8px",
  },
  errorIcon: {
    position: "absolute",
    right: "12px",
    top: "9px",
    "&>img": {
      display: "block",
      width: "18px",
      height: "18px",
    },
  },
  everySelect: {
    width: "100%",
  },
  dailySelect: {
    width: "100%",
    maxWidth: "280px",
    position: "relative",
    "&>.MuiFormControl-root": {
      width: "100%",
    },
  },
  keyword: {
    maxWidth: "calc(100% - 28px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [Breakpoints.Mobile]: {
    wrapper: {
      flexDirection: "column",
      gap: "12px",
    },
    newsPodNameWrapper: {
      width: "100%",
    },
    periodContainer: {
      flexDirection: "column",
      width: "100%",
    },
    hourlyContentForMobile: {
      display: "block",
      width: "100%",
    },
    dailyContentForMobile: {
      display: "flex",
      width: "100%",
    },
    hourlyContent: {
      display: "none",
    },
    dailyContent: {
      display: "none",
    },
    emailChannel: {
      display: "none",
    },
    emailChannelForMobile: {
      display: "flex",
    },
    hourlyPeriodContainer: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
    timePeriodWrapper: {
      padding: "0",
      width: "100%",
      border: "none",
      gap: "16px",
    },
    dailyPeriodWrapper: {
      flexDirection: "column",
      padding: "0",
      width: "100%",
      border: "none",
      gap: "16px",
      alignItems: "flex-start",
      "&>.MuiFormControl-root": {
        maxWidth: "unset",
      },
    },
    newsPodNameContainer: {
      width: "100%",
    },
    newsPodNameInput: {
      border: "1px solid #C2C4CB",
      width: "calc(100% - 50px)",
      maxWidth: "inherit",
    },
    everySelect: {
      width: "100%",
    },
    dailySelect: {
      maxWidth: "unset",
    },
  },
}));
