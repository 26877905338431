export const getFirstLetters = (userName: string) => {
  let firstLetterName;
  let firstLetterLastName;

  if (userName?.includes(" ")) {
    const nameParts = userName.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    firstLetterName = firstName?.charAt(0);
    firstLetterLastName = lastName?.charAt(0);
  } else {
    firstLetterName = userName?.charAt(0);
  }

  return {
    firstLetterName,
    firstLetterLastName,
  };
};

export default getFirstLetters;
