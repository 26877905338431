const TelegramLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.3566 9.9997L13.5358 11.178L15.8924 8.82136C16.5176 8.19623 16.8688 7.34836 16.8688 6.46428C16.8688 5.5802 16.5176 4.73233 15.8924 4.1072C15.5829 3.79766 15.2154 3.55212 14.811 3.3846C14.4066 3.21708 13.9731 3.13086 13.5354 3.13086C12.6513 3.13086 11.8034 3.48206 11.1783 4.1072L8.82161 6.46386L9.99994 7.64303L12.3566 5.28553C12.6692 4.9729 13.0932 4.79727 13.5354 4.79727C13.9775 4.79727 14.4015 4.9729 14.7141 5.28553C15.0267 5.59815 15.2024 6.02216 15.2024 6.46428C15.2024 6.9064 15.0267 7.3304 14.7141 7.64303L12.3566 9.9997ZM9.99994 12.3572L11.1783 13.5355L8.82161 15.8922C8.19647 16.5173 7.3486 16.8685 6.46452 16.8685C5.58044 16.8685 4.73258 16.5173 4.10744 15.8922C3.4823 15.2671 3.1311 14.4192 3.1311 13.5351C3.1311 12.651 3.4823 11.8032 4.10744 11.178L6.46411 8.82136L7.64327 9.9997L5.28577 12.3572C4.97326 12.6698 4.79774 13.0938 4.79781 13.5358C4.79789 13.9779 4.97357 14.4018 5.28619 14.7143C5.59881 15.0268 6.02278 15.2023 6.46482 15.2022C6.90686 15.2022 7.33076 15.0265 7.64327 14.7139L9.99994 12.3564V12.3572Z"
        fill="#00AFD6"
      />
      <path
        d="M12.3575 8.82182C12.5139 8.66545 12.6017 8.45337 12.6017 8.23223C12.6017 8.0111 12.5139 7.79902 12.3575 7.64265C12.2011 7.48628 11.989 7.39844 11.7679 7.39844C11.5468 7.39844 11.3347 7.48628 11.1783 7.64265L7.64332 11.1785C7.56373 11.2554 7.50025 11.3473 7.45657 11.449C7.4129 11.5507 7.38991 11.66 7.38895 11.7706C7.38799 11.8813 7.40907 11.991 7.45097 12.0934C7.49287 12.1959 7.55475 12.2889 7.63299 12.3671C7.71124 12.4454 7.80428 12.5073 7.90669 12.5492C8.00911 12.5911 8.11884 12.6122 8.22949 12.6112C8.34014 12.6102 8.44949 12.5872 8.55116 12.5436C8.65283 12.4999 8.74478 12.4364 8.82166 12.3568L12.3567 8.82182H12.3575Z"
        fill="#07B6DC"
      />
    </svg>
  );
};

export default TelegramLinkIcon;
