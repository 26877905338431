export const ArrowDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_6399_20684)">
      <path d="M7 10L12 15L17 10H7Z" fill="#858C9B" />
    </g>
    <defs>
      <clipPath id="clip0_6399_20684">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
