import { useEffect } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import messageIcon from "assets/icons/messageIcon.svg";
import telegramIcon from "assets/icons/telegramIcon.svg";
import { Button } from "shared/reusableComponents/Button/Button";
import TelegramLinkIcon from "assets/icons/components/TelegramLink.Icon";
import { useOnBoardingContext } from "context/onBoarding.context";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { channelsUseStyles } from "./Channels.styles";

const Channels = ({
  setNewsPodChannels,
  handleCreateNewsPod,
  userInfo,
  handlePrevStep,
  isLoading,
}: {
  setNewsPodChannels: any;
  handleCreateNewsPod: any;
  userInfo: any;
  handlePrevStep: any;
  isLoading: boolean;
}) => {
  const styles = channelsUseStyles();

  const { sendViaChannels, setSendViaChannels } = useOnBoardingContext();

  useEffect(() => {
    setNewsPodChannels(sendViaChannels);
    //eslint-disable-next-line
  }, [sendViaChannels]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const generateContentForMobile = () => {
    return (
      <Flex
        direction="column"
        gap="8px"
        justifyContent="space-between"
        className={`${styles.channel} ${styles.emailChannelForMobile}`}
        style={{ borderColor: sendViaChannels.email ? "#537FC1" : "" }}
      >
        <Flex justifyContent="space-between" width="100%">
          <Flex gap="8px">
            <img src={messageIcon} alt="" />
            <span className={styles.channelName}>Email</span>
          </Flex>
          <input
            type="checkbox"
            checked={sendViaChannels.email}
            onChange={() =>
              setSendViaChannels((prev: any) => ({
                ...sendViaChannels,
                email: !prev.email,
              }))
            }
            style={{ width: "16px", height: "16px" }}
          />
        </Flex>
        <Flex width="100%" justifyContent="space-between">
          <span className={styles.emailForMobile}>{userInfo.email}</span>
          <div className={styles.status}>Connected</div>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      className={styles.wrapper}
      direction="column"
      justifyContent="space-between"
      width="62%"
    >
      <Flex direction="column" gap="16px" width="100%">
        <div style={{ marginBottom: "8px" }}>
          <h2 className={styles.title}>Choose your preferred channels.</h2>
        </div>
        {generateContentForMobile()}
        <Flex
          direction="column"
          gap="8px"
          className={`${styles.channel} ${styles.emailChannel}`}
          style={{ borderColor: sendViaChannels.email ? "#537FC1" : "" }}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex gap="8px" alignItems="center">
              <img src={messageIcon} alt="" />
              <span className={styles.channelName}>Email</span>
              <span className={styles.email}>{userInfo.email}</span>
              <div className={styles.status}>Connected</div>
            </Flex>
            <input
              type="checkbox"
              checked={sendViaChannels.email}
              onChange={() =>
                setSendViaChannels((prev: any) => ({
                  ...sendViaChannels,
                  email: !prev.email,
                }))
              }
              style={{ width: "16px", height: "16px" }}
            />
          </Flex>
          <span className={styles.message}>
            Receive your daily newsletter in your inbox.
          </span>
        </Flex>
        <Flex
          direction="column"
          gap="8px"
          className={styles.channel}
          style={{ borderColor: sendViaChannels.telegram ? "#537FC1" : "" }}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex gap="8px" alignItems="center">
              <img src={telegramIcon} alt="" />
              <span className={styles.channelName}>Telegram</span>
            </Flex>
            <input
              type="checkbox"
              onChange={() =>
                setSendViaChannels((prev: any) => ({
                  ...sendViaChannels,
                  telegram: !prev.telegram,
                }))
              }
              checked={sendViaChannels.telegram}
              style={{ width: "16px", height: "16px" }}
            />
          </Flex>

          {sendViaChannels.telegram ? (
            <Flex gap="8px" direction="column" className={styles.telegramConnectContainer}>
              <span className={styles.messageForConect}>
                Click the link below to connect your Telegram account.
              </span>
              <Flex
                gap="8px"
                alignItems="center"
                className={styles.telegramLink}
              >
                <TelegramLinkIcon />
                <a
                  href={userInfo.telegram_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {userInfo.telegram_link}
                </a>
              </Flex>
            </Flex>
          ) : (
            <span className={styles.message}>
              Receive your daily audio newsletter via Telegram.
            </span>
          )}
        </Flex>
      </Flex>
      <Flex
        className={styles.buttonsContainer}
        justifyContent="flex-end"
        gap="16px"
        width="100%"
      >
        <Button
          variant={ButtonVariants.Outlined}
          className={styles.backBtn}
          handleClick={() => handlePrevStep()}
        >
          Back
        </Button>
        <Button
          handleClick={() => {
            handleCreateNewsPod();
          }}
          disabled={!sendViaChannels.email && !sendViaChannels.telegram}
        >
          {isLoading ? <Loader /> : "Create"}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Channels;
