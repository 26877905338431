import ErrorTosterIcon from "assets/icons/components/ErrorToster.Icon";
import { useEffect } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { tosterUseStyles } from "shared/reusableComponents/Toster/Toster.styles";

const ErrorToaster = ({
  message,
  setMessage,
}: {
  message: string;
  setMessage?: any;
}) => {
  const styles = tosterUseStyles();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
    //eslint-disable-next-line
  }, [message]);

  return message ? (
    <Flex className={styles.errorTosterWrapper} direction="column" gap="2px">
      <Flex gap="10px" alignItems="center">
        <ErrorTosterIcon />
        <span className={styles.tosterType}>Error</span>
      </Flex>
      <span className={styles.message}>{message}</span>
    </Flex>
  ) : (
    <></>
  );
};

export default ErrorToaster;
