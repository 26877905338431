export const ROUTES = {
  SIGNIN: "/",
  SIGNUP: "/sign-up",
  SIGNUP_FROM_LANDING: "/sign-up/:uid",
  FORGOT_PASSWORD: "/password/reset",
  ON_BOARDING: "/newsfeeds",
  DASHBOARD: "/newsfeed",
  PROFILE: "/profile",
  FORGOT_PASSWORD_CONFIRM: "/password/reset/confirm/:uid/:token",
  MAGIC_LINK_CONFIRM: "/magic-link/confirm/:uid/:token",
  NEWSPOD: "newspod/:uuid",
};
