import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Header from "modules/Dashboard/Header/Header";
import { ROUTES } from "constants/routes.constants";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import watchIcon from "assets/icons/watchIcon.svg";
import trialOverWatchIcon from "assets/icons/trialOverWatchIcon.svg";
import { userState } from "constants/userInfo.constants";
import BasicModal from "shared/reusableComponents/BasicModal/BasicModal";
import TrialIsOver from "modals/TrialIsOverModal";
import SubscriptionCanceled from "modals/SubscriptionCanceledModal";
import { variables } from "variables";
import SideBar from "modules/SideBar/SideBar";
import NewsPodCreateOrEdit from "modules/Dashboard/NewsPodCreateOrEdit/components/NewsPodCreatOrEdit";
import ErrorToaster from "shared/reusableComponents/Toster/ErrorToaster";
import { dashboardLayoutUseStyles } from "Layouts/Dashboard/styles";
import { useDashboardContext } from "context/Dashboard.context";

const { REACT_APP_NO_SUBSCRIPTION_MODAL_SHOW_FREQUENCY_IN_HOURS } = variables;

const DashboardLayout = ({
  children,
  userInfo,
  isFetching,
}: {
  children: any;
  userInfo?: any;
  isFetching?: boolean;
}) => {
  const styles = dashboardLayoutUseStyles();
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const [showOverModal, setShowOverModal] = useState<boolean>(false);
  const [errorMessageForToaster, setErrorMessageForToaster] =
    useState<string>("");
  const [showCanceledModal, setShowCanceledModal] = useState<boolean>(false);

  const { isOpenCreateNewspodModal, setIsOpenCreateNewspodModal }: any =
    useDashboardContext();

  useEffect(() => {
    const lastTrialOverModalShown = localStorage.getItem(
      " lastTrialOverModalShown"
    );
    const lastCanceledModalShown = localStorage.getItem(
      "lastCanceledModalShown"
    );
    const currentTime = new Date().getTime();

    if (
      (!lastTrialOverModalShown ||
        currentTime - parseInt(lastTrialOverModalShown) >
          +REACT_APP_NO_SUBSCRIPTION_MODAL_SHOW_FREQUENCY_IN_HOURS *
            60 *
            60 *
            1000) &&
      userInfo.state === userState.not_subscribed
    ) {
      setShowOverModal(true);
      localStorage.setItem("lastTrialOverModalShown", currentTime.toString());
    } else if (
      (!lastCanceledModalShown ||
        currentTime - parseInt(lastCanceledModalShown) >
          +REACT_APP_NO_SUBSCRIPTION_MODAL_SHOW_FREQUENCY_IN_HOURS *
            60 *
            60 *
            1000) &&
      userInfo.state === userState.canceled
    ) {
      setShowCanceledModal(true);
      localStorage.setItem("lastCanceledModalShown", currentTime.toString());
    }

    //eslint-disable-next-line
  }, [userInfo]);

  const generateTrialContent = () => {
    if (userInfo.state === userState.trialing_not_subscribed) {
      return (
        <Flex className={styles.trialContainer} gap="22px" alignItems="center">
          <img src={watchIcon} alt="" />
          <Flex direction="column" gap="8px">
            <span className={styles.trialTitle}>
              Your 3-day trial has started.
            </span>
            <span className={styles.trialDescription}>
              Please{" "}
              <span
                onClick={() =>
                  navigate(ROUTES.PROFILE, {
                    state: {
                      activeTab: "Billing information",
                    },
                  })
                }
              >
                choose a plan
              </span>{" "}
              to continue using HottakeAi after the trial period.
            </span>
          </Flex>
        </Flex>
      );
    } else if (userInfo.state === userState.not_subscribed) {
      return (
        <Flex
          className={styles.trialContainer}
          gap="22px"
          alignItems="center"
          style={{ background: "#FEF3F2", border: "1px dashed #E74768" }}
        >
          <img src={trialOverWatchIcon} alt="" />
          <Flex direction="column" gap="8px">
            <span className={styles.trialTitle}>Your free trial is over</span>
            <span className={styles.trialDescription}>
              Please{" "}
              <span
                onClick={() =>
                  navigate(ROUTES.PROFILE, {
                    state: {
                      activeTab: "Billing information",
                    },
                  })
                }
              >
                choose a plan
              </span>{" "}
              to continue using HottakeAi after the trial period.
            </span>
          </Flex>
        </Flex>
      );
    }
  };

  const generateContent = () => {
    if (isFetching) {
      return (
        <CircularProgress style={{ display: "block", margin: "150px auto" }} />
      );
    } else if (accessToken) {
      if (userInfo.has_completed_onboarding) {
        return (
          <Flex width="100%" style={{ background: "#F5F5F5" }}>
            <SideBar userName={userInfo?.name} />
            <div className={styles.wrapper}>
              <Header userName={userInfo?.name} />
              <div className={styles.container}>
                {(userInfo.state === userState.trialing_not_subscribed ||
                  userInfo.state === userState.not_subscribed) &&
                  generateTrialContent()}
                {children}
              </div>

              <BasicModal
                open={showOverModal}
                handleClose={() => setShowOverModal(false)}
                className={styles.noSubscriptionModalContent}
              >
                <TrialIsOver handleCancel={() => setShowOverModal(false)} />
              </BasicModal>
              <BasicModal
                open={showCanceledModal}
                handleClose={() => setShowCanceledModal(false)}
                className={styles.noSubscriptionModalContent}
              >
                <SubscriptionCanceled
                  handleCancel={() => setShowCanceledModal(false)}
                />
              </BasicModal>
              <BasicModal
                open={isOpenCreateNewspodModal.open}
                handleClose={() =>
                  setIsOpenCreateNewspodModal({ open: false, type: "" })
                }
                style={{
                  maxWidth: "608px",
                  width: "100%",
                  height: "calc(100vh - 80px)",
                  top: "40px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  overflowY: "scroll",
                  borderRadius: "20px",
                  background: "#FFF",
                  padding: "20px",
                }}
              >
                <NewsPodCreateOrEdit
                  userInfo={userInfo}
                  setErrorMessageForToaster={setErrorMessageForToaster}
                />
              </BasicModal>
            </div>
            <ErrorToaster
              message={errorMessageForToaster}
              setMessage={setErrorMessageForToaster}
            />
          </Flex>
        );
      } else {
        return <Navigate to={ROUTES.ON_BOARDING} />;
      }
    } else {
      return <Navigate to={ROUTES.SIGNIN} />;
    }
  };
  return generateContent();
};

export default DashboardLayout;
