import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes.constants";
import { magicLink, magicLinkConfirm } from "modules/Services/auth.services";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import hotakeAiLogoLight from "assets/icons/hotakeAiLogoLight.svg";
import { Input } from "shared/reusableComponents/Input/Input";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { validateEmail } from "helpers/validateEmail";
import Loader from "shared/reusableComponents/ButtonLoader/Loader";
import { errorSuccessMessages } from "constants/errorSuccessMessages.constants";
import MagicLinkSent from "modules/Autentication/MagicLink/components/MagicLinkSent";
import { magicLinkConfirmUseStyles } from "modules/Autentication/MagicLink/pages/MagicLinkConfirm.styles";
import LoadingState from "shared/reusableComponents/LoadingState/LoadingState";

const MagicLinkConfirm = () => {
  const styles = magicLinkConfirmUseStyles();
  const location = useLocation();
  const uid = location.pathname.split("/")[3];
  const token = location.pathname.split("/")[4];
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSent, setIsSent] = useState<boolean>(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(magicLinkConfirm, {
    onSuccess: ({ data }) => {
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      navigate(ROUTES.SIGNIN);
    },
    onError: () => {
      setIsTokenExpired(true);
    },
  });
  const { mutate: sendMagicLinkMutate, isLoading: isMagicLintSentLoading } =
    useMutation(magicLink, {
      onSuccess: () => {
        setIsSent(true);
        setIsTokenExpired(false);
      },
    });
  useEffect(() => {
    mutate({ uid, token });

    //eslint-disable-next-line
  }, []);

  const handleSend = () => {
    const isValidEmail = validateEmail(email);
    if (isValidEmail && !isLoading) {
      sendMagicLinkMutate({ email, tz: userTimezone });
    } else if (!isValidEmail) {
      setErrorMessage(errorSuccessMessages.incorrect);
    }
  };

  const generateContent = () => {
    if (isLoading) {
      return (
       <LoadingState/>
      );
    } else if (isTokenExpired) {
      return (
        <Flex
          className={styles.wrapper}
          justifyContent="flex-end"
          alignItems="center"
          gap="210px"
        >
          <div>
            <img src={hotakeAiLogoLight} alt="" />
          </div>
          <Flex
            className={styles.authContainer}
            alignItems="center"
            justifyContent="center"
            width="55%"
          >
            <Flex
              className={styles.linkExpiredWrapper}
              direction="column"
              gap="40px"
            >
              <Flex direction="column" gap="12px" alignItems="center">
                <span className={styles.linkExpiredMessage}>
                  Sorry, this link has expired.
                </span>
                <span className={styles.linkExpiredInfo}>
                  Looks like your magic link expired. Try sending a new link by
                  entering your email below.
                </span>
              </Flex>
              <Input
                label="Email"
                defaultValue={email}
                value={email}
                hasError={!!errorMessage}
                errorText={errorMessage}
                handleChange={(e) => {
                  setErrorMessage("");
                  setEmail((e.target as HTMLInputElement).value);
                }}
              />
              <Button
                variant={ButtonVariants.Primary}
                disabled={!validateEmail(email)}
                style={{ width: "100%" }}
                handleClick={handleSend}
              >
                {isMagicLintSentLoading ? <Loader /> : "Request Another Link"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      );
    } else if (isSent) {
      return <MagicLinkSent email={email} />;
    }
  };

  return generateContent();
};

export default MagicLinkConfirm;
