import { RegexConstants } from "constants/regex.constants";
import { variables } from "variables";

const { REACT_APP_CAN_EMAIL_CONTAIN_PLUS } = variables;

export const validateEmail = (email: string) => {
  if (+REACT_APP_CAN_EMAIL_CONTAIN_PLUS) {
    return RegexConstants.emailWithPlus.test(email);
  } else {
    return RegexConstants.email.test(email);
  }
};
