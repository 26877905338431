import { useEffect, useRef, useState } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import addCategories from "assets/icons/addCategories.svg";
import cross from "assets/icons/cross.svg";
import { Input } from "shared/reusableComponents/Input/Input";
import { useOnBoardingContext } from "context/onBoarding.context";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { categoriesUsestyles } from "./Categories.styles";

const Categories = ({
  handleNextStep,
  setNewsPodCategories,
}: {
  handleNextStep: any;
  setNewsPodCategories: any;
}) => {
  const styles = categoriesUsestyles();
  const wrapperRef = useRef(null);
  const [inputValue, setInputValue] = useState<string>("");

  const {
    categories,
    setCategories,
    selectedCategories,
    setSelectedCategories,
    keywords,
    setKeywords,
  } = useOnBoardingContext();

  const handleAddCategorie = (categorie: string) => {
    setSelectedCategories([...selectedCategories, categorie]);
    const indexToRemove = categories.indexOf(categorie);
    const newArr = [...categories];

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setCategories(newArr);
  };

  const handleDeletCategorie = (categorie: any) => {
    let newArr = [...selectedCategories];
    const indexToRemove = newArr.indexOf(categorie);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setSelectedCategories(newArr);
    setCategories((prev: any) => [...prev, categorie]);
  };

  const handleDeleteKeyword = (keyword: string) => {
    let newArr = [...keywords];
    const indexToRemove = newArr.indexOf(keyword);

    if (indexToRemove !== -1) {
      newArr.splice(indexToRemove, 1);
    }
    setKeywords(newArr);
  };

  useEffect(() => {
    if (wrapperRef?.current) {
      (wrapperRef?.current as any).scrollTop = (
        wrapperRef?.current as any
      ).scrollHeight;
    }
  }, [keywords]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleBlur = () => {
    if (inputValue && !keywords.includes(inputValue)) {
      setKeywords((prev: any) => [...prev, inputValue]);
    }
    setInputValue("");
  };

  const handleEnterClick = (e: any) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <Flex
      className={styles.topicsWrapper}
      direction="column"
      justifyContent="space-between"
      alignItems="inherit"
      width="64%"
    >
      <div className={styles.wrapper} ref={wrapperRef}>
        <h2 className={styles.title}>
          Choose the topics that match your interests.
        </h2>
        <Flex
          className={styles.categoriesContainer}
          direction="column"
          gap="12px"
        >
          <Flex direction="column" gap="12px">
            <p className={styles.sectionTitle}>All topics</p>
            <Flex flexWrap="wrap" gap="12px">
              {categories.map((categorie: any) => (
                <Flex
                  key={categorie}
                  gap="8px"
                  alignItems="center"
                  className={styles.categorie}
                  onClick={() => handleAddCategorie(categorie)}
                >
                  <span>{categorie}</span>
                  <img src={addCategories} alt="" />
                </Flex>
              ))}
            </Flex>
          </Flex>
          {!!selectedCategories.length && (
            <Flex
              className={styles.selectedCategoriesContainer}
              direction="column"
              gap="12px"
            >
              <p className={styles.sectionTitle}>Selected topics</p>
              <Flex flexWrap="wrap" gap="12px">
                {selectedCategories.map((categorie: any) => (
                  <Flex
                    key={categorie}
                    gap="8px"
                    alignItems="center"
                    className={styles.selectedCategorie}
                    onClick={() => handleDeletCategorie(categorie)}
                  >
                    <span>{categorie}</span>
                    <img src={cross} alt="" />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex direction="column" gap="8px" className={styles.keywordsContainer}>
          <p className={styles.sectionTitle}>Add keywords</p>
          <Input
            label="Keywords"
            value={inputValue}
            handleChange={(e: any) =>
              e.target.value.length <= 30 && setInputValue(e.target.value)
            }
            handleBlur={handleBlur}
            handleKeyDown={handleEnterClick}
            style={{ width: "100%", marginTop: "4px" }}
          />
          {!!keywords.length && (
            <Flex flexWrap="wrap" gap="12px" width="100%">
              {keywords.map((keyword: any) => (
                <Flex
                  key={keyword}
                  gap="8px"
                  alignItems="center"
                  className={styles.selectedCategorie}
                  onClick={() => handleDeleteKeyword(keyword)}
                >
                  <span className={styles.keyword}>{keyword}</span>
                  <img src={cross} alt="" />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </div>
      <Flex
        className={styles.buttonsContainer}
        gap="16px"
        justifyContent="flex-end"
      >
        <Button variant={ButtonVariants.Outlined} disabled={true}>
          Back
        </Button>
        <Button
          disabled={!selectedCategories.length && !keywords.length}
          handleClick={() => {
            handleNextStep();
            setNewsPodCategories(selectedCategories, keywords);
          }}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
export default Categories;
