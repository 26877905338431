const CrossIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93994 8.4997L11.6533 5.78637C11.9133 5.52637 11.9133 5.10637 11.6533 4.84637C11.3933 4.58637 10.9733 4.58637 10.7133 4.84637L7.99994 7.5597L5.28661 4.84637C5.02661 4.58637 4.60661 4.58637 4.34661 4.84637C4.08661 5.10637 4.08661 5.52637 4.34661 5.78637L7.05994 8.4997L4.34661 11.213C4.08661 11.473 4.08661 11.893 4.34661 12.153C4.47994 12.2864 4.64661 12.3464 4.81994 12.3464C4.99328 12.3464 5.15994 12.2797 5.29328 12.153L8.00661 9.4397L10.7199 12.153C10.8533 12.2864 11.0199 12.3464 11.1933 12.3464C11.3666 12.3464 11.5333 12.2797 11.6666 12.153C11.9266 11.893 11.9266 11.473 11.6666 11.213L8.95328 8.4997H8.93994Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CrossIcon;
