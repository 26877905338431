const ProfileIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.565 5.6325C5.565 3.765 7.08 2.25 8.9475 2.25C10.815 2.25 12.33 3.765 12.33 5.6325C12.33 7.5 10.815 9.015 8.9475 9.015C7.08 9.015 5.565 7.5 5.565 5.6325ZM8.9475 10.71C6.69 10.71 2.1825 11.8425 2.1825 14.0925V15.0375C2.1825 15.45 2.52 15.7875 2.9325 15.7875H14.9625C15.375 15.7875 15.7125 15.45 15.7125 15.0375V14.0925C15.7125 11.8425 11.205 10.71 8.9475 10.71Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ProfileIcon;
