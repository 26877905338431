import { createUseStyles } from "react-jss";

export const dateRangeSelectUseStyles = createUseStyles(() => ({
  selectWrapper: {
    width: "100%",
    position: "relative",
    "&>.react-datepicker-wrapper": {
      width: "100%",
      "&>.react-datepicker__input-container": {
        "&>input": {
          width: "100%",
          border: "1px solid #C2C4CB",
          borderRadius: "8px",
          outline: "none",
          padding: "10px 32px 10px 12px",
          "&:focus": {
            border: "1px solid #537FC1",
          },
          "&::placeholder": {
            fontSize: "16px",
            fontWeight: "400",
            color: "rgb(194, 196, 203)",
          },
        },
        "&>svg": {
          width: "24px",
          height: "24px",
          right: "0px",
        },
      },
    },
    "& .react-datepicker-popper": {
      width: "100%",
      "& .react-datepicker": {
        width: "100%",
        border: "1px solid #C2C4CB",
      },
      "& .react-datepicker__header ": {
        backgroundColor: "#F5F5F5",
        padding: "14px 0",
        borderBottom: "1px solid #C2C4CB",
      },
      "& .react-datepicker__triangle": {
        display: "none",
      },
    },
    "& .react-datepicker__day--today": {
      border: "1px solid #537FC1",
      borderRadius: "8px",
      backgroundColor: "#fff",
    },
    "& .react-datepicker__month-container": {
      width: "100%",
    },
    "& .react-datepicker__day": {
      fontSize: "16px",
      fontWeight: "400",
      color: "#5D6269",
    },
    "& .react-datepicker__day--in-range": {
      background: "#F4F7FE",
      color: "#5D6269",
    },
    "& .react-datepicker__day--range-end,.react-datepicker__day--range-start": {
      background: "#537FC1",
      color: "#FDFDFD",
    },
    "& .react-datepicker__day-name": {
      fontSize: "16px",
      fontWeight: "500",
      color: "#5D6269",
    },
    "& .react-datepicker__current-month": {
      fontSize: "16px",
      fontWeight: "400",
      color: "#858C9B",
    },
    "& .react-datepicker__navigation-icon::before": {
      borderColor: "#5D6269",
    },
  },
  dateRangeIcon: {
    position: "absolute",
    top: "16px",
    right: "16px",
  },
}));
