import { CircularProgress } from "@mui/material";
import { Flex } from "shared/reusableComponents/Flex/Flex";

const LoadingState = () => {
  return (
    <Flex width="100%" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Flex>
  );
};

export default LoadingState;
