export const defaultTimes = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const defaultSources = [
  "ABC News",
  "Al Jazeera",
  "Associated Press",
  "BBC",
  "Bleacher Report",
  "Bloomberg",
  "Business Insider",
  "CBC News",
  "CNN International",
  "Fox News",
  "HuffingtonPost",
  "NBC News",
  "Scripps News",
  "National Geographic",
  "Reuters",
  "The Economist",
  "The Guardian",
  "The Washington Post",
  "The New York Times",
  "Yahoo News",
];

export const defaultTopics = [
  "Business",
  "Health",
  "Politics",
  "Technology",
  "Sports",
  "Environment",
  "Science",
  "Arts and Entertainment",
];

export const defaultEveryTimes = ["1 Hour", "2 Hours", "3 Hours", "6 Hours"];
