import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const dashboardLayoutUseStyles = createUseStyles(() => ({
  wrapper: {
    width: "100%",
    background: "#fff",
  },
  container: {
    minHeight: "100vh",
    background: "#F5F5F5",
    padding: "40px",
    "&>div": {
      paddingBottom: "130px",
    },
  },
  trialContainer: {
    padding: "24px !important",
    background: "#FAF5ED",
    border: "1px dashed #F59431",
    borderRadius: "8px",
    width: "100%",
    margin: "0 0 20px",
  },
  trialTitle: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#5D6269",
  },
  trialDescription: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#858C9B",
    "&>span": {
      fontWeight: "600",
      color: "#537FC1",
      cursor: "pointer",
    },
  },
  noSubscriptionModalContent: {
    width: "fit-content",
    padding: "0",
    border: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "4px 4px 16px 0px rgba(153, 153, 153, 0.40)",
  },

  [Breakpoints.LargeScreen]: {
    container: {
      padding: "20px",
    },
  },
  [Breakpoints.Mobile]: {
    noSubscriptionModalContent: {
      width: "calc(100% - 30px)",
    },
  },
}));
