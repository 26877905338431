import { CSSProperties } from "react";
//mport errorIcon from "assets/icons/errorIcon.svg";
import { errorMessageUseStyles } from "./ErrorMessage.styles";

const ErrorMessage = ({
  text,
  width,
  margin,
  position,
  style,
  iconStyle = { width: '15px', height: '15px' }
}: {
  text: string;
  width?: any;
  margin?: any;
  position?: any;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
}) => {
  const styles = errorMessageUseStyles()
  return (
    <span
      className={styles.errorMessage}
      style={{
        margin: margin,
        width,
        position,
        ...style,
      }}
    >
      {/* <img style={iconStyle} src={errorIcon} alt="info" /> */}
      {text}
    </span>
  );
};

export default ErrorMessage;
