import { createUseStyles } from "react-jss";

export const signUpUseStyles = createUseStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "100%",
  },
  redirectContentContainer: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#858C9B",
  },
  redirectContentLink: {
    fontWeight: "500",
    color: "#537FC1",
    cursor: "pointer",
    textDecoration: "none",
  },
}));
