import { useEffect, useRef, CSSProperties } from "react";
import { basicModalUseStyles } from "shared/reusableComponents/BasicModal/BasicModal.styles";


const BasicModal = ({
  open,
  handleClose,
  children,
  style,
  onMouseLeave,
  className,
}: {
  open: any;
  handleClose: () => void;
  children: any;
  style?: CSSProperties;
  className?: any;
  onMouseLeave?: () => void;
}) => {
  const styles = basicModalUseStyles();
  const modalRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        modalRef?.current &&
        !e.target.closest('div[role="presentation"]') &&
        !modalRef?.current?.contains(e.target)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    //eslint-disable-next-line
  }, [modalRef]);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [open]);

  return (
    open && (
      <div className={styles.basicModal} onMouseLeave={onMouseLeave}>
        <div
          className={`${styles.modalContent} ${className}`}
          ref={modalRef}
          style={style}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default BasicModal;
