export const convertTo24HourFormat = (time12Hour: any) => {
  const [timePart, amOrPm] = time12Hour.split(/(?=[APap][Mm])/);
  const [hours] = timePart.split(":");

  let hours24 = parseInt(hours);

  if (amOrPm?.toLowerCase() === "pm" && hours24 !== 12) {
    hours24 += 12;
  }
  if (hours24 === 12 && amOrPm?.toLowerCase() === "am") {
    hours24 = 0;
  }

  const formattedHours = String(hours24).padStart(2, "0");
  const formattedMinutes = "00";

  const time24Hour = `${formattedHours}:${formattedMinutes}`;

  return time24Hour;
};

export const convertTo12HourFormat = (time24: any) => {
  const timeArray = time24?.split(":");
  let hours = timeArray && parseInt(timeArray[0]);
  let minutes = timeArray && timeArray[1];
  let period = "AM";

  if (hours === 0) {
    hours = 12;
  } else if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  return `${hours}:${minutes} ${period}`;
};

export const convertToHourString = (frequency: number) => {
  if (frequency === 1) {
    return frequency + " Hour";
  } else {
    return frequency + " Hours";
  }
};

export const formatExpirationDate = (month: number, year: number) => {
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedYear = String(year).slice(-2);
  return `${formattedMonth}/${formattedYear}`;
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const options: any = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const formattedDateStringCustom = formattedDate.replace(",", "");
  const parts = formattedDateStringCustom.split(" ");
  const finalFormattedDate = `${parts[1]} ${parts[0]}, ${parts[2]}`;

  return finalFormattedDate;
};
export const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const formattedMinutes = minutes > 0 ? minutes + "min " : "";
  const formattedSeconds =
    remainingSeconds > 0 ? remainingSeconds + " sec" : "";

  return formattedMinutes + formattedSeconds;
};
export const formatListeningDuration = (
  listeningDuration: number,
  seconds: number
) => {
  let remainingSeconds = Math.round((listeningDuration - seconds) % 60);
  let remainingMinutes = Math.floor((listeningDuration - seconds) / 60);
  const formattedTime = [
    remainingMinutes.toString().padStart(2, "0"),
    remainingSeconds.toString().padStart(2, "0"),
  ].join(":");

  return formattedTime;
};

export function formatDateForBackend(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

