import React, { useEffect, useState } from "react";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import selectTime from "assets/icons/selecTimeIcon.svg";
import CustomSelect from "shared/reusableComponents/Select/Select";
import { Button } from "shared/reusableComponents/Button/Button";
import { convertTo24HourFormat } from "helpers/convertTimes";
import { useOnBoardingContext } from "context/onBoarding.context";
import {
  defaultEveryTimes,
  defaultTimes,
} from "constants/newsPodDetails.constants";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { frequencyUseStyles } from "./Frequency.styles";

const Frequency = ({
  handleNextStep,
  setNewsPodFrequency,
  handlePrevStep,
}: {
  handleNextStep: any;
  setNewsPodFrequency: any;
  handlePrevStep: any;
}) => {
  const styles = frequencyUseStyles();
  const {
    dailyTime,
    setDailyTime,
    frequencyTime,
    setFrequencyTime,
    hourlyTime,
    setHourlyTime,
  } = useOnBoardingContext();
  const [frequencySettings, setFrequencySettings] = useState({
    sendStart: "",
    sendEnd: "",
    sendAt: "",
    every: Number(),
  });

  useEffect(() => {
    if (frequencyTime === "Daily") {
      setFrequencySettings({
        ...frequencySettings,
        every: 24,
        sendEnd: "",
        sendStart: "",
        sendAt: dailyTime && convertTo24HourFormat(dailyTime),
      });
    } else {
      setFrequencySettings({
        sendStart: hourlyTime.start && convertTo24HourFormat(hourlyTime.start),
        sendEnd: hourlyTime.end && convertTo24HourFormat(hourlyTime.end),
        every: hourlyTime.every ? parseInt(hourlyTime.every, 10) : Number(),
        sendAt: "",
      });
    }

    //eslint-disable-next-line
  }, [hourlyTime, frequencyTime, dailyTime]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = (event.target as HTMLInputElement).value;
    setFrequencyTime(radioValue);
  };

  const generateContentHourly = () => {
    return (
      <Flex direction="column" gap="32px" className={styles.timePeriodwrapper}>
        <Flex
          className={styles.hourlyTimeContainer}
          gap="20px"
          alignItems="center"
        >
          <span className={styles.timePeriod}>Starting from</span>
          <Flex
            gap="20px"
            alignItems="center"
            width="100%"
            className={styles.hourlyInputs}
          >
            <div className={styles.hourlySelects}>
              <CustomSelect
                label="Time"
                value={hourlyTime.start}
                selectedValue={hourlyTime.end}
                handleChange={(e: any) => {
                  setHourlyTime({ ...hourlyTime, start: e.target.value });
                }}
                options={defaultTimes}
                width="100%"
              />
              <div className={styles.selectTimeIcon}>
                <img src={selectTime} alt="" />
              </div>
            </div>

            <span className={styles.timePeriod} style={{ color: "#858C9B" }}>
              To
            </span>
            <div className={styles.hourlySelects}>
              <CustomSelect
                label="Time"
                value={hourlyTime.end}
                selectedValue={hourlyTime.start}
                handleChange={(e: any) => {
                  setHourlyTime({ ...hourlyTime, end: e.target.value });
                }}
                options={defaultTimes}
                width="100%"
              />
              <div className={styles.selectTimeIcon}>
                <img src={selectTime} alt="" />
              </div>
            </div>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="20px"
          className={styles.everyContainer}
        >
          <span className={styles.timePeriodEvery}>Every</span>
          <CustomSelect
            label="Hour(s)"
            value={hourlyTime.every}
            handleChange={(e: any) => {
              setHourlyTime({
                ...hourlyTime,
                every: e.target.value,
              });
            }}
            options={defaultEveryTimes}
            width="100%"
          />
        </Flex>
      </Flex>
    );
  };
  const generateContentDaily = () => {
    return (
      <Flex gap="78px" alignItems="center" className={styles.dailyWrapper}>
        <span className={styles.timePeriod}>Daily at</span>
        <CustomSelect
          label="Time"
          value={dailyTime}
          handleChange={(e: any) => {
            setDailyTime(e.target.value);
            setFrequencySettings({
              ...frequencySettings,
              sendAt: convertTo24HourFormat(e.target.value),
            });
          }}
          options={defaultTimes}
          width="100%"
        />
      </Flex>
    );
  };

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      width="68%"
      className={styles.frequencyWrapper}
    >
      <Flex direction="column" gap="24px" width="100%">
        <Flex direction="column" gap="8px">
          <h2 className={styles.title}>Configure your preferred frequency.</h2>
        </Flex>
        <Flex direction="column" gap="16px" width="100%">
          <Flex gap="8px">
            <input
              type="radio"
              id="Hourly"
              value={"Hourly"}
              name="time"
              className={styles.radioButton}
              onChange={handleRadioChange}
              checked={frequencyTime === "Hourly"}
            />
            <label htmlFor="Hourly" className={styles.timePeriod}>
              Hourly
            </label>
          </Flex>
          {frequencyTime === "Hourly" && generateContentHourly()}

          <Flex gap="8px">
            <input
              type="radio"
              id="Daily"
              value={"Daily"}
              name="time"
              className={styles.radioButton}
              onChange={handleRadioChange}
              checked={frequencyTime === "Daily"}
            />
            <label htmlFor="Daily" className={styles.timePeriod}>
              Daily
            </label>
          </Flex>
          {frequencyTime === "Daily" && generateContentDaily()}
        </Flex>
      </Flex>
      <Flex
        className={styles.buttonsContainer}
        justifyContent="flex-end"
        gap="16px"
        width="100%"
      >
        <Button
          variant={ButtonVariants.Outlined}
          handleClick={() => handlePrevStep()}
        >
          Back
        </Button>
        <Button
          handleClick={() => {
            handleNextStep();
            setNewsPodFrequency(frequencySettings);
          }}
          disabled={
            !(
              frequencySettings.every &&
              ((frequencySettings.sendEnd && frequencySettings.sendStart) ||
                frequencySettings.sendAt)
            )
          }
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
export default Frequency;
