import { createUseStyles } from "react-jss";

interface ButtonStyleProps {
  disabled?: boolean;
  color?: string;
}

export const buttonUseStyles = createUseStyles(() => ({
  primaryButton: {
    position: "relative",
    padding: "10px 16px",
    background: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#EAECF0" : "#537FC1",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#C6CCD4" : "#FFFFFF",
    border: "none",
    cursor: ({ disabled }: ButtonStyleProps) =>
      disabled ? "initial" : "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      background: ({ disabled }: ButtonStyleProps) =>
        disabled ? "#EAECF0" : "#446BA6",
    },
  },
  secondaryButton: {
    padding: "10px 16px",
    background: "#EAECF0",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#858C9B",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#EBEEF3",
      color: "#5D6269",
    },
  },
  outlinedButton: {
    padding: "10px 16px",
    background: "#FFFFFF",
    border: ({ disabled }: ButtonStyleProps) =>
      disabled ? "1px solid #EAECF0" : "1px solid #537FC1",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
    color: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#D0D5DD" : "#537FC1",
    "&:hover": {
      border: ({ disabled }: ButtonStyleProps) =>
        !disabled && "1px solid #446BA6",
      color: ({ disabled }: ButtonStyleProps) => !disabled && "#446BA6",
    },
  },
}));
