import { useNavigate } from "react-router-dom";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import { Button } from "shared/reusableComponents/Button/Button";
import { ButtonVariants } from "shared/reusableComponents/Button/Button.types";
import { ROUTES } from "constants/routes.constants";
import trialIsOverModal from "assets/icons/trialIsOverModal.svg";
import { modalsUseStyles } from "modals/styles";

const TrialIsOver = ({ handleCancel }: { handleCancel: () => void }) => {
  const styles = modalsUseStyles();
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="24px" alignItems="center">
      <div className={styles.imgContainer}>
        <img src={trialIsOverModal} alt="" />
      </div>
      <Flex className={styles.container} direction="column" gap="8px"  width="calc(100% - 40px)" alignItems="center">
        <span className={styles.info}>Your free trial is over.</span>
        <span className={styles.message}>
          Please choose a plan to continue using HottakeAi.
        </span>
      </Flex>
      <Flex
        className={styles.buttonsContainer}
        width="calc(100% - 48px)"
        gap="20px"
        justifyContent="flex-end"
      >
        <Button variant={ButtonVariants.Secondary} handleClick={handleCancel}>
          Cancel
        </Button>
        <Button
          handleClick={() => {
            navigate(ROUTES.PROFILE, {
              state: {
                activeTab: "Billing information",
              },
            });
            handleCancel();
          }}
        >
          Choose plan
        </Button>
      </Flex>
    </Flex>
  );
};

export default TrialIsOver;
