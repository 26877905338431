import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import OnBoardingLayout from "Layouts/OnBoarding/OnBoardingLayout";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import CustomStepper from "../components/CustomStepper/CustomStepper";
import Categories from "../components/Categories/Categories";
import untitledNewspod from "assets/icons/untitledNewspod.svg";
import Frequency from "../components/Frequency/Frequency";
import Sources from "../components/Sources/Sources";
import Channels from "../components/Channels/Channels";
import NewsPodCreated from "modules/OnBoarding/components/WelcomeAndCreatedStates/NewspodCreated";
import {
  createNewsPod,
  getNewspodInitiation,
} from "modules/Services/newsPods.services";
import { Input } from "shared/reusableComponents/Input/Input";
import { useOnBoardingContext } from "context/onBoarding.context";
import { onBoardingUseStyles } from "./OnBoarding.styles";

const OnBoarding = ({ userInfo }: { userInfo: any }) => {
  const styles = onBoardingUseStyles();

  const {
    setSelectedSources,
    setSelectedCategories,
    setSendViaChannels,
    setCategories,
    setSources,
    categories,
    sources,
  } = useOnBoardingContext();
  const [activeStep, setActiveStep] = useState(0);
  const inputRef = useRef<any>(null);
  const [newsPodDetails, setNewsPodDetails] = useState<any>({
    name: "Untitled NewsPod",
    status: "Active",
    keywords: [],
    categories: [],
    sources: [],
    frequency: "",
    send_at: null,
    send_start: null,
    send_end: null,
    send_via_email: true,
    send_via_telegram: false,
  });

  const { data: { data = {} } = {} } = useQuery(
    "getNewspodInitiation",
    getNewspodInitiation
  );

  const deleteItemsFromArray = (
    selectedOptions: string[],
    options: string[]
  ) => {
    return options.filter((item) => !selectedOptions.includes(item));
  };

  useEffect(() => {
    if (data.uuid) {
      setSelectedCategories(data.categories);
      setSelectedSources(data.sources);
      setSendViaChannels({
        email: data.send_via_email,
        telegram: data.send_via_telegram,
      });
      setCategories(deleteItemsFromArray(data.categories, categories));
      setSources(deleteItemsFromArray(data.sources, sources));
    }
    // eslint-disable-next-line
  }, [data]);

  const { mutate: createNewsPodMutate, isLoading } = useMutation(
    () => createNewsPod(newsPodDetails),
    {
      onSuccess: () => {
        handleChangeStep();
      },
    }
  );

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleSetCategories = (selectedCategories: string[], keywords: any) => {
    setNewsPodDetails({
      ...newsPodDetails,
      categories: selectedCategories,
      keywords: keywords,
    });
  };
  const handleSetSorces = (selectedSources: any) => {
    setNewsPodDetails({
      ...newsPodDetails,
      sources: selectedSources,
    });
  };

  const handleSetFrequency = (frequencySettings: any) => {
    setNewsPodDetails({
      ...newsPodDetails,
      frequency: frequencySettings.every,
      send_at: frequencySettings.sendAt || null,
      send_start: frequencySettings.sendStart || null,
      send_end: frequencySettings.sendEnd || null,
    });
  };
  const handleSetChannels = (channels: any) => {
    setNewsPodDetails({
      ...newsPodDetails,
      send_via_email: channels.email,
      send_via_telegram: channels.telegram,
    });
  };

  const handleChangeStep = () => {
    if (activeStep < 4) {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleCreateNewsPod = () => {
    !isLoading && createNewsPodMutate();
  };
  const handleSetPrevStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const generateStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Categories
            handleNextStep={handleChangeStep}
            setNewsPodCategories={handleSetCategories}
          />
        );
      case 1:
        return (
          <Sources
            handleNextStep={handleChangeStep}
            setNewsPodSources={handleSetSorces}
            handlePrevStep={handleSetPrevStep}
          />
        );
      case 2:
        return (
          <Frequency
            handleNextStep={handleChangeStep}
            setNewsPodFrequency={handleSetFrequency}
            handlePrevStep={handleSetPrevStep}
          />
        );
      case 3:
        return (
          <Channels
            setNewsPodChannels={handleSetChannels}
            handleCreateNewsPod={handleCreateNewsPod}
            userInfo={userInfo}
            handlePrevStep={handleSetPrevStep}
            isLoading={isLoading}
          />
        );
      default:
        break;
    }
  };

  const generateContent = () => {
    if (activeStep > 3) {
      return <NewsPodCreated />;
    }
    return (
      <>
        <Flex
          direction="column"
          gap="18px"
          className={styles.onBoardingContainer}
        >
          <Flex direction="column" gap="8px">
            <h2 className={styles.title}>Create your first NewsPod</h2>
            <span className={styles.description}>
              We’re thrilled to have you on board, excited to see how your first
              NewsPod turns out.
            </span>
          </Flex>
          <Flex
            className={styles.newsPodNameContainer}
            direction="column"
            gap="4px"
          >
            <Input
              className={styles.newsPodNameInput}
              ref={inputRef}
              defaultValue={newsPodDetails.name}
              handleChange={(e: any) =>
                setNewsPodDetails({
                  ...newsPodDetails,
                  name: e.target.value,
                })
              }
              hasError={newsPodDetails.name.length > 50}
              errorText="Maximum 50 characters"
            />
            <div className={styles.newsPodNameImg}>
              <img src={untitledNewspod} alt="" />
            </div>
          </Flex>
        </Flex>

        <Flex gap="24px" alignItems="stretch" className={styles.contentWrapper}>
          <CustomStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          {generateStepContent()}
        </Flex>
      </>
    );
  };

  return (
    <OnBoardingLayout userInfo={userInfo}>{generateContent()}</OnBoardingLayout>
  );
};

export default OnBoarding;
