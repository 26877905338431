import { Breakpoints } from "constants/styles.contants";
import { createUseStyles } from "react-jss";

export const basicModalUseStyles = createUseStyles(() => ({
  basicModal: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "999",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.50)",
  },
  modalContent: {
    boxSizing: "border-box",
    position: "absolute",
    top: "calc(50% - 89px)",
    left: "calc(50% - 200px)",
    background: "#FFF",
    width: "560px",
    padding: "24px",
    boxShadow: "4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
    border: "1px solid #E6E6EB",
    borderRadius: "8px",
  },
  [Breakpoints.Mobile]: {
    modalContent: {
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "calc(100% - 30px)",
    },
  },
}));
