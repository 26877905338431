import { createContext, useContext, useState } from "react";

interface OnboardingContextProps {
  categories: string[];
  setCategories: (a: any) => void;
  selectedCategories: string[];
  setSelectedCategories: (a: any) => void;
  keywords: string[];
  setKeywords: (a: any) => void;
  sources: string[];
  setSources: (a: any) => void;
  selectedSources: string[];
  setSelectedSources: (a: any) => void;
  sendViaChannels: any;
  setSendViaChannels: (a: any) => void;
  dailyTime: string;
  setDailyTime: (a: any) => void;
  frequencyTime: string;
  setFrequencyTime: (a: any) => void;
  hourlyTime: any;
  setHourlyTime: (a: any) => void;
  userInfo: any;
  setUserInfo: (a: any) => void;
}

const OnboardingContext = createContext<OnboardingContextProps>({
  categories: [],
  setCategories: (a: any) => {},
  selectedCategories: [],
  setSelectedCategories: (a: any) => {},
  keywords: [],
  setKeywords: (a: any) => {},
  sources: [],
  setSources: (a: any) => {},
  selectedSources: [],
  setSelectedSources: (a: any) => {},
  sendViaChannels: null,
  setSendViaChannels: (a: any) => {},
  dailyTime: "",
  setDailyTime: (a: any) => {},
  frequencyTime: "",
  setFrequencyTime: (a: any) => {},
  hourlyTime: null,
  setHourlyTime: (a: any) => {},
  userInfo: null,
  setUserInfo: (a: any) => {},
});

const useProvideOnBoarding = () => {
  const [categories, setCategories] = useState<string[]>([
    "Arts and Entertainment",
    "Business",
    "Environment",
    "Health",
    "Politics",
    "Science",
    "Sports",
    "Technology",
  ]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);

  const [sources, setSources] = useState<string[]>([
    "ABC News",
    "Al Jazeera",
    "Associated Press",
    "BBC",
    "Bleacher Report",
    "Bloomberg",
    "Business Insider",
    "CBC News",
    "CNN International",
    "Fox News",
    "HuffingtonPost",
    "NBC News",
    "Scripps News",
    "National Geographic",
    "Reuters",
    "The Economist",
    "The Guardian",
    "The Washington Post",
    "The New York Times",
    "Yahoo News",
  ]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [sendViaChannels, setSendViaChannels] = useState<any>({
    email: true,
    telegram: false,
  });
  const [dailyTime, setDailyTime] = useState<string>("");
  const [frequencyTime, setFrequencyTime] = useState("");
  const [hourlyTime, setHourlyTime] = useState({
    start: "",
    end: "",
    every: "",
  });
  const [userInfo, setUserInfo] = useState({
    email: "",
    has_completed_onboarding: false,
    has_telegram_profile: false,
    name: "",
    telegram_link: "",
    tz: "",
    uuid: "",
  });

  return {
    categories,
    setCategories,
    selectedCategories,
    setSelectedCategories,
    keywords,
    setKeywords,
    sources,
    setSources,
    selectedSources,
    setSelectedSources,
    sendViaChannels,
    setSendViaChannels,
    dailyTime,
    setDailyTime,
    frequencyTime,
    setFrequencyTime,
    hourlyTime,
    setHourlyTime,
    userInfo,
    setUserInfo,
  };
};
export const OnBoardingContext = ({ children }: { children: any }) => {
  const onBoardingData = useProvideOnBoarding();
  return (
    <OnboardingContext.Provider value={onBoardingData}>
      {children}
    </OnboardingContext.Provider>
  );
};
export const useOnBoardingContext = () =>
  useContext<OnboardingContextProps>(OnboardingContext);
