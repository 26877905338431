import { instance } from "api/ApiRequest";
import { variables } from "variables";

const { REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS } = variables;

export const getSubscriptions = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS}plans/`
  );
  return response;
};
export const subscribe = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS}subscribe/`,
    payload
  );
  return response;
};

export const getBillingInformation = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS}billing-information/`
  );
  return response;
};

export const cancelSubscription = async (id: string) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS}${id}/cancel/`
  );
  return response;
};

export const changePlan = async () => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_SUBSCRIPTIONS}customer-portal/`
  );
  return response;
};
