import { Box, Pagination } from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { newsfeedsUseStyles } from "modules/Dashboard/Newsfeeds/pages/Newsfeeds.styles";
import EmptyState from "modules/Dashboard/reusableComponents/EmptyState/EmptyState";
import Filters from "modules/Dashboard/reusableComponents/Filters/Filters";
import NewsfeedsItem from "modules/Dashboard/reusableComponents/NewsfeedsItem/NewsFeedsItem";
import { getNewsfeeds } from "modules/Services/newsPods.services";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Flex } from "shared/reusableComponents/Flex/Flex";
import LoadingState from "shared/reusableComponents/LoadingState/LoadingState";

const Newsfeeds = () => {
  const styles = newsfeedsUseStyles();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const debauncedSearchValue = useDebounce(searchValue, 1000);

  const {
    data: newsfeedsResponse,
    isFetching,
    refetch,
  } = useQuery(
    ["getNewsfeeds", page, page === 1 && debauncedSearchValue],
    () =>
      getNewsfeeds(
        page,
        selectedTopic,
        selectedSource,
        dateRange,
        debauncedSearchValue,
        null
      ),
    {
      keepPreviousData: true,
    }
  );
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }

    //eslint-disable-next-line
  }, [debauncedSearchValue]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const { articles = [], count } = newsfeedsResponse?.data ?? {};

  return (
    <Flex className={styles.wrapper} direction="column" gap="20px">
      <Flex direction="column" gap="32px" width="100%">
        <h2 className={styles.sectionName}>Newsfeed</h2>
        <Filters
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isLoading={isFetching}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          refetch={refetch}
          setPage={setPage}
          page={page}
        />
      </Flex>
      <Flex
        className={styles.newsfeedsContainer}
        width="100%"
        direction="column"
        gap="32px"
      >
        {isFetching ? (
          <LoadingState />
        ) : !!articles?.length ? (
          <>
            <h2 className={styles.title}>Latest news</h2>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& > div:last-child": {
                  border: "none",
                },
              }}
              gap="20px"
              width="100%"
            >
              {articles?.map((item: any) => (
                <NewsfeedsItem article={item} key={item.id} />
              ))}
            </Box>
            <Flex
              width="100%"
              justifyContent="flex-end"
              className={styles.pagination}
            >
              <Pagination
                count={Math.ceil(newsfeedsResponse?.data?.count / 10)}
                page={page}
                onChange={handleChange}
              />
            </Flex>
          </>
        ) : (
          <EmptyState
            title="Latest news"
            message={
              count >= 0
                ? "No news generated for this hour"
                : "No created NewsPods yet"
            }
            variant={count >= 0 ? true : false}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Newsfeeds;
