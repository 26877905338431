import { instance } from "api/ApiRequest";
import { formatDateForBackend } from "helpers/convertTimes";
import { variables } from "variables";
const { REACT_APP_BACKEND_API_URL_NEWSFEEDS } = variables;

export const getNewsfeeds = async (
  page: number,
  topic: string,
  source: string,
  date: string[] | undefined[],
  text: string,
  uuid?: string | null
) => {
  const [startDate, endDate] = date;
  const queryParams = new URLSearchParams({
    page: page?.toString(),
    ...(text ? { text } : {}),
    ...(uuid ? { newspod: uuid } : {}),
    ...(topic ? { topic } : {}),
    ...(source ? { source } : {}),
    ...(startDate ? { start_date: formatDateForBackend(startDate) } : {}),
    ...(endDate ? { end_date: formatDateForBackend(endDate) } : {}),
  });

  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}?${queryParams}`
  );
  return response;
};

export const getCategories = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}categories/`
  );
  return response;
};
export const getSources = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}sources/`
  );
  return response;
};

export const createNewsPod = async (payload: any) => {
  const response = await instance.post(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/`,
    payload
  );
  return response;
};

export const getNewsPods = async ({ uuid }: { uuid?: any }) => {
  let url;
  if (uuid) {
    url = `newspods/${uuid}`;
  } else {
    url = `newspods/`;
  }
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}${url}`
  );

  return response;
};

export const deleteNewspod = async ({ uuid }: { uuid: any }) => {
  const response = await instance.delete(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/`
  );
  return response;
};

export const getNewspod = async ({ uuid }: { uuid: any }) => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/`
  );
  return response;
};

export const editNewsPod = async ({
  uuid,
  payload,
}: {
  uuid: any;
  payload: any;
}) => {
  const response = await instance.put(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/`,
    payload
  );
  return response;
};

export const activateNewspod = async ({ uuid }: { uuid: any }) => {
  const response = await instance.patch(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/activate/`
  );
  return response;
};

export const pauseNewspod = async ({ uuid }: { uuid: any }) => {
  const response = await instance.patch(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/pause/`
  );
  return response;
};

export const articlesCountUpdate = async ({ uuid }: { uuid?: string }) => {
  const response = await instance.patch(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspods/${uuid}/mark-as-read/`
  );
  return response;
};

export const getNewspodInitiation = async () => {
  const response = await instance.get(
    `${REACT_APP_BACKEND_API_URL_NEWSFEEDS}newspod-initiation/`
  );
  return response;
};
