import { Flex } from "shared/reusableComponents/Flex/Flex";
import hotakeAiLogoLight from "assets/icons/hotakeAiLogoLight.svg";
import { magicLinkUseStyles } from "modules/Autentication/MagicLink/components/MagicLink.styles";

const MagicLinkSent = ({ email }: { email: string }) => {
  const styles = magicLinkUseStyles();

  return (
    <Flex
      className={styles.wrapper}
      justifyContent="flex-end"
      alignItems="center"
      gap="210px"
    >
      <div>
        <img src={hotakeAiLogoLight} alt="" />
      </div>
      <Flex
        className={styles.authContainer}
        alignItems="center"
        justifyContent="center"
        width="55%"
      >
        <Flex
          className={styles.checkEmailContainer}
          direction="column"
          alignItems="center"
          gap="12px"
        >
          <span className={styles.checkEmailText}>Check your email</span>
          <span className={styles.successMessage}>
            We have sent a magic link to{" "}
            <span className={styles.email}>{email}</span> Click the link to
            login.
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MagicLinkSent;
