import { Navigate } from "react-router-dom";
import { ROUTES } from "constants/routes.constants";
import { onBoardingLayoutUseStyles } from "./styles";

const OnBoardingLayout = ({
  children,
  userInfo,
}: {
  children: any;
  userInfo: any;
}) => {
  const styles = onBoardingLayoutUseStyles();

  const accessToken = localStorage.getItem("access_token");

  const generateContent = () => {
    if (accessToken && Object.keys(userInfo).length) {
      if (userInfo?.has_completed_onboarding) {
        return <Navigate to={ROUTES.DASHBOARD} />;
      }
      return (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.newsPodContainer}>{children}</div>
          </div>
        </div>
      );
    } else {
      return <Navigate to={ROUTES.SIGNIN} />;
    }
  };
  return generateContent();
};

export default OnBoardingLayout;
